import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
//import { DeviceDetectorService } from 'ngx-device-detector';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {


  constructor( 
    //private deviceService: DeviceDetectorService
    ) 
    {

   }
   public open_link(link,link_type) {
//     const userOS=this.deviceService.getDeviceInfo().os;
//     const isMobile = this.deviceService.isMobile();
// var url=link;
//     if(isMobile){
//       if(userOS=='Android'){
//         if(link_type==1){
//             url="fb://page/Albabtainautokw";
//             url="fb://facewebmodal/f?href=https://www.facebook.com/Albabtainautokw/";
//         }else{
//             url="instagram://user?username=Albabtainautokw";
//         }
       
//       }else{
//         if(link_type==1){
//            url="fb://page/Albabtainautokw";
//       }else{
//            url="instagram://user?username=Albabtainautokw";
//       }
//       }
     
//     }
//     window.open(url, "_blank");
   }

  selectedLang= new BehaviorSubject('en');
  currency= new BehaviorSubject('$');
  page= new BehaviorSubject('');
}
