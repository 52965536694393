import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CrudService } from 'src/app/common_service/crud.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
import {PageService} from 'src/app/common_service/page.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { ProductsService } from 'src/app/common_service/products.service';
declare var $: any;
@Component({
  selector: 'app-returnorder',
  templateUrl: './returnorder.component.html',
  styleUrls: ['./returnorder.component.css']
})
export class ReturnOrderComponent implements OnInit {

  order_id:any;
  api_calling:any;
  order_data:any
  currency:any;
  policy_and_reasons:any;
constructor(
private translate: TranslateService,
public siteService: LanguageService,
private CartService:CartService,
public authService: AutheService,
public productsService: ProductsService,
private fb: FormBuilder,
private router:Router,
private route: ActivatedRoute,
private CrudService:CrudService,
private pageSerice:PageService) { }
return_form = this.fb.group({
        term_and_conditions: [true, Validators.required],
        reason_id: ['', [
          Validators.required
        ]],  return_type: ['', [
            Validators.required
          ]],  remarks: ['', [
            ]]
});
  ngOnInit() {
    this.order_id=this.route.snapshot.params.order_id;
  let  query_params={
      "fld_user_id":this.authService.userdata().fld_user_id,
      "fld_order_id":this.order_id
  };

 this.CrudService.add(query_params,'orderDetails').subscribe(data => {
         if(data.status==true){
           this.order_data=data.data;
                 let  query_params={
                     "fld_user_id":this.authService.userdata().fld_user_id,
                     "fld_product_id":this.order_data.fld_product_id,
                     "fld_reason_type":1
                 };
                   this.CrudService.add(query_params,'getReason').subscribe(data => {
                    this.policy_and_reasons=data.data;
                 });

         } else{
             this.router.navigate(['myorder/upcomming']);
         }
 });
 this.siteService.currency.subscribe(res=>{
 this.currency=res;
 });
  }
return_order(e){
  if (e) e.preventDefault();
         this.api_calling=true;
        let  query_params={
          "fld_user_id":this.authService.userdata().fld_user_id,
          "sub_order_id":this.order_id,
          "fld_reason_type":1,
          "return_type":this.return_form.controls['return_type'].value,
          "comments":this.return_form.controls['remarks'].value,
          "reason_id":this.return_form.controls['reason_id'].value
        };
    this.CrudService.add(query_params,'cancel_return_order').subscribe(data => {
       this.api_calling=false;
         if (data.status==true) {
          this.order_data.fld_order_status=5;
          this.pageSerice.snakeMessage(data.msg,'success');
        } else{
            this.pageSerice.snakeMessage(data.msg,'Danger');
        }
    });
}
}
