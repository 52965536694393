
import { Component, OnInit,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CrudService } from 'src/app/common_service/crud.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
import {PageService} from 'src/app/common_service/page.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
    state_data:any;
    city_data:any;
    address_data:any;
    address_mode:any=false;
    address_id:any=0;
  api_calling=false;

    constructor(  private translate: TranslateService,
      public siteService: LanguageService,
      private CartService:CartService,
      public authService: AutheService,
      private fb: FormBuilder,
        private CookieService:CookieService,
          private router:Router,
      private CrudService:CrudService,
      private pageSerice:PageService) { }
      address_book_from = this.fb.group({
        fld_user_name: ['', [
          Validators.required
        ]],
        fld_user_phone: ['', [
          Validators.required,
          Validators.pattern("^[0-9_-]{8}"),
          RxwebValidators.digit()
        ]],
        fld_user_address1: ['', [
          Validators.required
        ]],
        fld_user_address2: ['', [
          Validators.required
        ]],
        fld_user_address3: ['', [
          Validators.required
        ]],
        fld_user_state: ['', [
          Validators.required
        ]],
        fld_user_city: ['', [
          Validators.required
        ]],
        fld_user_pincode: ['281004', [
        ]],
        fld_address_type: ['', [
          Validators.required
        ]],
        fld_address_default: ['', [
        ]]
      });
      delivery_to_this(id){
             localStorage.setItem('user_selected_delivery_address', id);
          if (localStorage.getItem('customer_cart')) {
              this.router.navigate(['checkout']);
          }

      }
      delete(id){
        if (confirm("Want to delete confirm")) {
          let  query_params={
            "fld_user_id":this.authService.userdata().fld_user_id,
            "fld_address_id":id
          };
           this.CrudService.add(query_params,'deleteAddress').subscribe(data => {
              if (data.status==true) {
                  this.getdefault_address();
                if (localStorage.getItem('user_selected_delivery_address')) {
                var select_shipping_id=localStorage.getItem('user_selected_delivery_address');
                  if(select_shipping_id==id){
                        localStorage.removeItem("user_selected_delivery_address");
                  }
                }

              this.get_all_address();
              this.pageSerice.snakeMessage(data.msg,'success');
              } else {
              this.pageSerice.snakeMessage(data.msg,'Danger');
              }

         });
        }
      }
      cancel(){
          $('html, body').animate({scrollTop:0},'50');
          this.address_mode=false;
            this.address_id=0;
              this.address_book_from.reset();
      }
      getdefault_address(){
        let  query_params={
          "fld_user_id":this.authService.userdata().fld_user_id,
        };
         this.CrudService.add(query_params,'userDefaultaddress').subscribe(data => {
           if(data.status==true){
                 localStorage.setItem('user_default_delivery_address', data.data.fld_address_id);
           }
          });
      }
      edit(id){
          this.api_calling=false;
        if (confirm("Want to edit confirm")) {
          this.address_id=id;
          let  query_params={
            "fld_user_id":this.authService.userdata().fld_user_id,
            "fld_address_id":id
          };
           this.CrudService.add(query_params,'addressDetails').subscribe(data => {
              if (data.status==true) {
                this.address_mode=true;
                        let  query_params={
                            "state_name":data.data.fld_user_state
                        };
                  this.CrudService.add(query_params,'cities').subscribe(data => {
                    this.city_data=data.data;
                });
                this.address_book_from.setValue(
                    {
                fld_user_name: data.data.fld_user_name,
                fld_user_phone: data.data.fld_user_phone,
                fld_user_address1: data.data.fld_user_address1,
                fld_user_address2: data.data.fld_user_address2,
                    fld_user_address3: data.data.fld_user_block_no,
                fld_user_state:data.data.fld_user_state,
                fld_user_city: data.data.fld_user_city,
                fld_user_pincode: data.data.fld_user_pincode,
                fld_address_type: data.data.fld_address_type,
                fld_address_default: data.data.fld_address_default
                  }
                   );
                      $('html, body').animate({scrollTop:500},'50');
              } else {
              this.pageSerice.snakeMessage(data.msg,'Danger');
              }

         });
        }
      }
      get_all_address(){
        let  query_params={"fld_user_id":this.authService.userdata().fld_user_id};
         this.CrudService.add(query_params,'userAddresss').subscribe(data => {
               if(data.status==true){
                 this.address_data=data.data;
               }

       });

        let apisCallData=[
            {
            "url":"states",
            "params":{}
            }
        ];
        this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
          this.state_data=data[0].data;
      });
      }
    ngOnInit() {
    this.get_all_address();


    }
    selectedState(state){
      let  query_params={
          "state_name":state
    };
      this.CrudService.add(query_params,'cities').subscribe(data => {
          this.city_data=data.data;
    });
    }
    add_address(e){
      if (e) e.preventDefault();
        if(!this.address_book_from.valid){
              this.address_book_from.markAsTouched();
            return ;
        }
     this.api_calling=true;
      let  query_params={
        "fld_user_id":this.authService.userdata().fld_user_id,
        "fld_user_name":this.address_book_from.controls['fld_user_name'].value,
        "fld_user_phone":this.address_book_from.controls['fld_user_phone'].value,
        "fld_user_address1":this.address_book_from.controls['fld_user_address1'].value,
        "fld_user_address2":this.address_book_from.controls['fld_user_address2'].value,
        "fld_user_block_no":this.address_book_from.controls['fld_user_address3'].value,
        "fld_user_pincode":this.address_book_from.controls['fld_user_pincode'].value,
        "fld_user_state":this.address_book_from.controls['fld_user_state'].value,
        "fld_user_city":this.address_book_from.controls['fld_user_city'].value,
        "fld_address_type":this.address_book_from.controls['fld_address_type'].value,
        "fld_address_default":this.address_book_from.controls['fld_address_default'].value
    };

    this.CrudService.add(query_params,'addAddress').subscribe(data => {
          this.api_calling=false;
          if (data.status==true) {
            this.getdefault_address();
            this.address_book_from.reset();
                this.get_all_address();
            this.pageSerice.snakeMessage(data.msg,'success');
          } else {
            this.pageSerice.snakeMessage(data.msg,'Danger');
          }

  });

    }
    update_address(e){
      if (e) e.preventDefault();
      
      if(!this.address_book_from.valid){
              this.address_book_from.markAsTouched();
            return ;
        }
        
     this.api_calling=true;
      let  query_params={
          "fld_user_id":this.authService.userdata().fld_user_id,
          "fld_address_id":this.address_id,
            "fld_user_name":this.address_book_from.controls['fld_user_name'].value,
            "fld_user_phone":this.address_book_from.controls['fld_user_phone'].value,
            "fld_user_address1":this.address_book_from.controls['fld_user_address1'].value,
            "fld_user_address2":this.address_book_from.controls['fld_user_address2'].value,
              "fld_user_block_no":this.address_book_from.controls['fld_user_address3'].value,
            "fld_user_pincode":this.address_book_from.controls['fld_user_pincode'].value,
            "fld_user_state":this.address_book_from.controls['fld_user_state'].value,
            "fld_user_city":this.address_book_from.controls['fld_user_city'].value,
            "fld_address_type":this.address_book_from.controls['fld_address_type'].value,
            "fld_address_default":this.address_book_from.controls['fld_address_default'].value
    };

    this.CrudService.add(query_params,'updateAddress').subscribe(data => {
          this.api_calling=false;
          if (data.status==true) {
              this.getdefault_address();
            this.address_book_from.reset();
            this.get_all_address();
            this.address_mode=false;
            this.address_id=0;
            this.pageSerice.snakeMessage(data.msg,'success');
              $('html, body').animate({scrollTop:0},'50');
          } else {
            this.pageSerice.snakeMessage(data.msg,'Danger');
          }

  });

    }
}
