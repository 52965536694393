import { Component, OnInit ,AfterViewInit } from '@angular/core';

import { CrudService } from 'src/app/common_service/crud.service';
import { SEOService } from '../../../common_service/seo.service';

import { AuthService } from "angular-6-social-login";

import { Router } from '@angular/router';

import {ActivatedRoute} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {PageService} from '../../../common_service/page.service';

import { ProductsService } from 'src/app/common_service/products.service';

import {Title , Meta} from '@angular/platform-browser';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RxwebValidators } from '@rxweb/reactive-form-validators';

import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";

import { SocialUser } from "angular-6-social-login";

import {LanguageService} from 'src/app/common_service/language.service';

 import { ConfigService } from '../../../common_service/ConfigService.service';

 import { Http, Headers } from '@angular/http';

import { Observable, forkJoin } from 'rxjs';

 declare var $: any;



@Component({

  selector: 'app-forget',

  templateUrl: './forget.component.html',

  styleUrls: ['./forget.component.css']

})

export class ForgetComponent implements OnInit {

  api_calling=false;



  capctha_data:any;

  blocks:any=[];

  constructor(

    private authService: AuthService,

    private translate: TranslateService,

    public siteService: LanguageService,

    private pageSerice: PageService,

    private productsService: ProductsService,

    private fb: FormBuilder,

    private router:Router,

    private route: ActivatedRoute,

    private title:Title,

    private meta:Meta,

    private http: Http,

    private CrudService:CrudService,
	private seoService: SEOService,
    private config: ConfigService

    ) { }

    forgot_form = this.fb.group({

      email: ['', [

        Validators.required,

        RxwebValidators.email()

      ]],

      captcha: ['', [

        Validators.required

      ]]

    });



  ngOnInit() {
	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
  this.getNewCapctha();

}

getNewCapctha(){

  let apisCallData=[

      {

      "url":"getCaptcha",

      "params":{}

      },

       {

        "url":"home_blocks",

        "params":{"block_type":1}

          }

  ];

  this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {

    this.capctha_data=data[0].data;

    this.blocks=data[1].data;

  });

}

forgot(e){



  if (e) e.preventDefault();
if(!this.forgot_form.valid){
              this.forgot_form.markAsTouched();
            return ;
        }
    this.api_calling=true;

const formData = new FormData();

formData.append('captcha', this.forgot_form.controls['captcha'].value);

formData.append('c_key', this.capctha_data.key);

formData.append('email', this.forgot_form.controls['email'].value);

this.CrudService.addByFormData(formData, 'forgot_password').subscribe(res => {

this.api_calling=false;

if (res.status==true) {

    this.forgot_form.reset();

    this.api_calling=false;

    this.getNewCapctha();

    this.forgot_form.reset();

  this.pageSerice.snakeMessage(res.msg,'success');

} else {

this.api_calling=false;

this.pageSerice.snakeMessage(res.msg,'Danger');

}

});

}

}

