import { Component, OnInit } from '@angular/core';

import { CrudService } from 'src/app/common_service/crud.service';
import { SEOService } from '../../../common_service/seo.service';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PageService } from '../../../common_service/page.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { LanguageService } from 'src/app/common_service/language.service';
declare var $: any;
declare var fbq: Function;
declare var ttq: any;

@Component({
  selector: 'app-summer-campaign',
  templateUrl: './summer-campaign.component.html',
  styleUrls: ['./summer-campaign.component.css']
})
export class SummerCampaignComponent implements OnInit {

  api_calling = false;

  providers: any = [];
  capctha_data: any;
  blocks: any = [];
  currentlang: any; size; model;

  constructor(
    public siteService: LanguageService,
    private pageSerice: PageService,
    private fb: FormBuilder,
    private CrudService: CrudService,
    private seoService: SEOService
  ) {
    this.siteService.selectedLang.subscribe(res => {
      this.currentlang = res;
    });
  }

  feedbackForm = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [RxwebValidators.email()]],
    mobile: ['', [Validators.required, Validators.pattern("^[0-9_-]{8}"), RxwebValidators.digit()]],
  });

  ngOnInit() {
    const currentRoute = window.location.href;
    this.seoService.updateCanonicalUrl(currentRoute);
  }

  feedbackcall(e) {
    if (e) e.preventDefault();
    if (!this.feedbackForm.valid) {
      this.feedbackForm.markAsTouched();
      return;
    }
    fbq('track', 'CompleteRegistration');
    ttq.track('SubmitForm');
    this.api_calling = true;

    this.CrudService.list(this.feedbackForm.value, 'summer-campaign').subscribe(res => {
      this.api_calling = false;
      if (res.status == true) {
        this.api_calling = false;
        this.feedbackForm.reset();
        this.pageSerice.snakeMessage(res.msg, 'success');
      } else {
        this.api_calling = false;
        this.pageSerice.snakeMessage(res.msg, 'Danger');
      }
    });
  }

}

