import { Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
token:any;
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    public siteService: LanguageService,
    private pageSerice: PageService,
    private productsService: ProductsService,
    private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
    private config: ConfigService
   ) { }

  ngOnInit() {
    this.token=this.route.snapshot.params.token;
    const formData = new FormData();
    formData.append('token', this.token);
    this.CrudService.addByFormData(formData, 'verify_email').subscribe(res => {
    if (res.status==true) {
      this.pageSerice.snakeMessage(res.msg,'success');
    } else {
    this.pageSerice.snakeMessage(res.msg,'Danger');
    }
      this.router.navigate(['login']);
    });
  }

}
