import { Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router,NavigationEnd  } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
page_data:any;
page_url:any='';
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
      public siteService: LanguageService,
    private pageSerice: PageService,
    private productsService: ProductsService,
    private router:Router,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
     private config: ConfigService,
	 private seoService: SEOService
   ){ 
   
   }

  ngOnInit() {
    // this.route.queryParams.subscribe(params=>{
    //   if(params.hasOwnProperty('page')){
    //       this.page_url=params.cat;
    //    }
    // });
	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute); 

    let  query_params={"page_name":"about-us"};
     let apisCallData=[
         {
         "url":"crm_page_data",
         "params":query_params
         }
     ];
     this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
          if(data[0].status==true){
          this.page_data=data[0].data;
		  this.pageSerice.pageInfo(this,{
              "page_title":this.page_data.page_title,
              "meta_data":{
                  "Keywords":this.page_data.meta_keyword,
                  "title":this.page_data.meta_title,
                  "description":this.page_data.meta_description
              }
          });
          }

   });
  }

}
