import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';

            import { PostComponent } from 'src/app/modules/frontend/post/post.component';
            import { ProductsComponent } from 'src/app/modules/frontend/products/products.component';
            import { ProductComponent } from 'src/app/modules/frontend/product/product.component';
            import { HomeComponent } from 'src/app/modules/frontend/home/home.component'
            import { ContactsComponent } from 'src/app/modules/frontend/contacts/contacts.component';
            import { AdloginComponent } from 'src/app/modules/admin/adlogin/adlogin.component';
            import {AdminlayoutComponent} from 'src/app/layouts/adminlayout/adminlayout.component';
            import { AdcatComponent } from 'src/app/modules/admin/adcat/adcat.component';
            import { AdbrandComponent } from 'src/app/modules/admin/adbrand/adbrand.component';
            import { AdattrComponent } from 'src/app/modules/admin/adattr/adattr.component';
            import { AuthGuard } from './guards/auth.guard';
            import { CarservicesComponent } from 'src/app/modules/frontend/carservices/carservices.component';
            import{ LoginComponent } from 'src/app/modules/frontend/login/login.component';
            import { ProductCompareComponent } from 'src/app/modules/frontend/product-compare/product-compare.component';
            import {CheckoutComponent} from 'src/app/modules/frontend/checkout/checkout.component';
            import {BrandDetailsComponent} from 'src/app/modules/frontend/branddetails/branddetails.component';
            
            import {CartComponent} from 'src/app/modules/frontend/cart/cart.component';
            import {BrandsComponent} from 'src/app/modules/frontend/brands/brands.component';
            import {AppoinmentsComponent} from 'src/app/modules/frontend/appoinments/appoinments.component';
            import {EnquiryComponent} from 'src/app/modules/frontend/enquiry/enquiry.component';
            import {OffersComponent} from 'src/app/modules/frontend/offers/offers.component';
            import {InstallmentsComponent} from 'src/app/modules/frontend/installments/installments.component';
            import {InstallationComponent} from 'src/app/modules/frontend/installation/installation.component';
            import { PagesComponent } from 'src/app/modules/frontend/pages/pages.component';
            import { AboutComponent } from 'src/app/modules/frontend/about/about.component';
            
            import { LoyalityComponent } from 'src/app/modules/frontend/loyality/loyality.component';
            import { LoyalityStepComponent } from 'src/app/modules/frontend/loyalityStep/loyalityStep.component';
            // customer section
            
            import {DashboardComponent} from 'src/app/modules/frontend/dashboard/dashboard.component';
            import { AddressComponent } from 'src/app/modules/frontend/address/address.component';
            import {MyorderComponent} from 'src/app/modules/frontend/myorder/myorder.component';
            import {ChangepasswordComponent} from 'src/app/modules/frontend/changepassword/changepassword.component';
            
            //end customer section
            import { from } from 'rxjs';
            import { ForgetComponent } from './modules/frontend/forget/forget.component';
            import {FaqsComponent} from 'src/app/modules/frontend/faqs/faqs.component';
            import {ThankuComponent} from 'src/app/modules/frontend/thanku/thanku.component';
            import { ReviewComponent } from './modules/frontend/review/review.component';
            import { InstallerReviewComponent } from './modules/frontend/installerreview/installerreview.component';
            import { FittinglocationsComponent } from './modules/frontend/fittinglocations/fittinglocations.component';
            import { BatteryComponent } from './modules/frontend/battery/battery.component';
            import { TrackOrderComponent } from 'src/app/modules/frontend/trackorder/trackorder.component';
            import { ServicesComponent } from 'src/app/modules/frontend/services/services.component';
            import { TetimonialsComponent } from 'src/app/modules/frontend/tetimonials/tetimonials.component';
            import { AllBrandsComponent } from 'src/app/modules/frontend/allbrands/allbrands.component';
            import { OrderDetailsComponent } from 'src/app/modules/frontend/orderdetails/orderdetails.component';
            import { MobileVanServiceComponent } from 'src/app/modules/frontend/mobilevanservice/mobilevanservice.component';
            import { CancelOrderComponent } from 'src/app/modules/frontend/cancelorder/cancelorder.component';
            import { ReturnOrderComponent } from 'src/app/modules/frontend/returnorder/returnorder.component';
            import { ResetComponent } from 'src/app/modules/frontend/reset/reset.component';
            import { VerifyComponent } from 'src/app/modules/frontend/verify/verify.component';
            
            
            
            import { CpnDetailsComponent } from 'src/app/modules/frontend/cpndetails/cpndetails.component';
            import { OfferDetailsComponent } from 'src/app/modules/frontend/offerdetails/offerdetails.component';
            import { NavComponent } from 'src/app/modules/frontend/nav/nav.component';
            import {CarYearComponent} from 'src/app/modules/frontend/caryear/caryear.component';
            import {CarModalComponent} from 'src/app/modules/frontend/carmodal/carmodal.component';
            import {CarMakeComponent} from 'src/app/modules/frontend/carmake/carmake.component';
            import {CarShoopComponent} from 'src/app/modules/frontend/carshoop/carshoop.component';
            import {OtherProductsComponent} from 'src/app/modules/frontend/otherproducts/otherproducts.component';
            import {NoMatchComponent} from 'src/app/modules/frontend/nomatch/nomatch.component';
            import {QueryFormComponent} from 'src/app/modules/frontend/queryform/queryform.component';
            import {ReachUsComponent} from 'src/app/modules/frontend/reachus/reachus.component';
            
            import {SummerCampaignComponent} from 'src/app/modules/frontend/summer-campaign/summer-campaign.component';
            import {ScratchCardComponent} from 'src/app/modules/frontend/scratchcard/scratchcard.component';
            import {ScratchCardFormComponent} from 'src/app/modules/frontend/scratchcardform/scratchcardform.component';
            import {ScratchCardOtpComponent} from 'src/app/modules/frontend/scratchcardotp/scratchcardotp.component';
            import {HealthCheckupComponent} from 'src/app/modules/frontend/health-checkup/health-checkup.component';

  import {FeedbackComponent} from 'src/app/modules/frontend/feedback/feedback.component';
  import {MobileVanTermComponent} from 'src/app/modules/frontend/mobilevanterm/mobilevanterm.component';
  import {ScratchCardMobileVanComponent} from 'src/app/modules/frontend/scratchcardmobilevan/scratchcardmobilevan.component';
  import {ScratchCardFormMobileVanComponent} from 'src/app/modules/frontend/scratchcardformmobilevan/scratchcardformmobilevan.component';
  import {ScratchCardOtpMobileVanComponent} from 'src/app/modules/frontend/scratchcardotpmobilevan/scratchcardotpmobilevan.component';
import { ShowroomGalleryComponent } from './modules/frontend/showroom-gallery/showroom-gallery.component';
import { DaiyaComponent } from './modules/frontend/daiya/daiya.component';
import { RiggaeComponent } from './modules/frontend/riggae/riggae.component';
import { FaihaComponent } from './modules/frontend/faiha/faiha.component';
import { JahraComponent } from './modules/frontend/jahra/jahra.component';
import { AhmadiComponent } from './modules/frontend/ahmadi/ahmadi.component';
import { RaiComponent } from './modules/frontend/rai/rai.component';
import { CanadadryComponent } from './modules/frontend/canadadry/canadadry.component';
import { SpeedmatetabComponent } from './modules/frontend/speedmatetab/speedmatetab.component';
import { CalluspageComponent } from './modules/frontend/calluspage/calluspage.component';

export const routes: Routes = [
    { path: '', component:HomeComponent },
   {path:'tyres/:tyre_type',component:CarMakeComponent},
    {path:'tyres/:tyre_type/:make',component:CarModalComponent},
    {path:'tyres/:tyre_type/:make/:modal',component:CarYearComponent},
    {path:'tyres/:tyre_type/:make/:modal/:year',component:CarShoopComponent},
    {path:'home',component:HomeComponent},
    {path:'customer-support',component:ContactsComponent},
    {path:'post',component:PostComponent},
    {path:'pages',component:PagesComponent},
    {path:'about',component:AboutComponent},
    {path:'p/:type',component:OtherProductsComponent},
    {path:'products/:type',component:ProductsComponent},
    {path:'product/:id',component:ProductComponent},
    {path:'services/:name',component:CarservicesComponent},
   
    {path:'order-tracking',component:NavComponent},
    {path:'login',component:LoginComponent},
    {path:'forget',component:ForgetComponent},
    {path:'reset_password/:token',component:ResetComponent},
    {path:'verify/:token',component:VerifyComponent},
    {path:'product-compare',component:ProductCompareComponent},
    {path:'dashboard',component:DashboardComponent, canActivate: [AuthGuard] },
    {path:'address',component:AddressComponent,canActivate: [AuthGuard]},
    {path:'checkout',component:CheckoutComponent},
    {path:'cart',component:CartComponent},
   
    {path:'brand/:brand_id',component:BrandDetailsComponent},
    {path:'book-appoinments/:name/:id',component:AppoinmentsComponent},
    {path:'service/:name/:id',component:ServicesComponent},
    {path:'enquiry',component:EnquiryComponent},
    {path:'offers',component:OffersComponent},
    {path:'installments',component:InstallmentsComponent},
    {path:'installation',component:InstallationComponent},
    {path:'offer-details/:id',component:OfferDetailsComponent},
    {path:'coupon-details/:id',component:CpnDetailsComponent},
    {path:'myorder/:list_type',component:MyorderComponent, canActivate: [AuthGuard]},
    {path:'track_order/:order_id',component:TrackOrderComponent, canActivate: [AuthGuard]},
    {path:'order_details/:order_id',component:OrderDetailsComponent, canActivate: [AuthGuard]},
    {path:'cancel_order/:order_id',component:CancelOrderComponent, canActivate: [AuthGuard]},
    {path:'return_order/:order_id',component:ReturnOrderComponent, canActivate: [AuthGuard]},
    {path:'faqs',component:FaqsComponent},
    {path:'orderStatus',component:ThankuComponent},
    {path:'review/:product_id/:name',component:ReviewComponent},
    {path:'rate-installer/:installer_id',component:InstallerReviewComponent},
    {path:'fitting-locations',component:FittinglocationsComponent},
    {path:'battery',component:BatteryComponent},
    {path:'changepassword',component:ChangepasswordComponent, canActivate: [AuthGuard]},
    {path:'testimonials',component:TetimonialsComponent},
    {path:'mobile_van_service',component:MobileVanServiceComponent},
    {path:'loyality_membership',component:LoyalityComponent},
    {path:'loyality_membership_step',component:LoyalityStepComponent},
    {path:'brands/:type',component:AllBrandsComponent},
    {path:'showroom-gallery/suwaikh',component:ShowroomGalleryComponent},
    {path:'showroom-gallery/daiya',component:DaiyaComponent},
    {path:'showroom-gallery/riggae',component:RiggaeComponent},
    {path:'showroom-gallery/faiha',component:FaihaComponent},
    {path:'showroom-gallery/jahra',component:JahraComponent},
    {path:'showroom-gallery/ahmadi',component:AhmadiComponent},
    {path:'showroom-gallery/rai',component:RaiComponent},
    {path:'showroom-gallery/canadadry',component:CanadadryComponent},
    {path:'showroom-gallery/speedmate',component:SpeedmatetabComponent},


    
    {path:'lead-form',component:QueryFormComponent},
    {path:'reach-us',component:ReachUsComponent},
    {path:'albabtain-auto-new-year-campaign',component:SummerCampaignComponent},
    {path:'total-oil-change-appointment-form',component:HealthCheckupComponent},
    
    {path:'terms-and-conditions',component:BrandsComponent},
    {path:'terms-and-conditions-mobile-van',component:MobileVanTermComponent},
     
    {path:'scratch',component:ScratchCardComponent},
    {path:'scan-and-win',component:ScratchCardFormComponent},
    {path:'verifyPhone',component:ScratchCardOtpComponent},

    {path:'feedback',component:FeedbackComponent},
		{path:'scratch-and-win-mobile-van',component:ScratchCardFormMobileVanComponent},
		{path:'verifyPhone-mobile-van',component:ScratchCardOtpMobileVanComponent},
		{path:'scratch-mobile-van',component:ScratchCardMobileVanComponent},
    {path:'call-us',component:CalluspageComponent},
    
    { path: '', redirectTo: '/', pathMatch: 'full' },
    {path:'**',component:NoMatchComponent},

    
];

@NgModule({
  //imports: [ RouterModule.forRoot(routes, { useHash: true })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }
