import { Component, OnInit, ViewChild, ElementRef,AfterViewInit,OnDestroy} from '@angular/core';
import { Options } from 'ng5-slider';
import {PageService} from 'src/app/common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import { SEOService } from '../../../common_service/seo.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { CartService } from 'src/app/common_service/cart.service';
import { CrudService } from 'src/app/common_service/crud.service';
import { LanguageService } from 'src/app/common_service/language.service';
declare var $: any;
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit,AfterViewInit ,OnDestroy{
  product_id:any;
  record:any={
    "mrp_price":0
  };
  rating_data:any={
      "avg_rating": 0,
      "customer_reviews": [],
      "page": 0,
      "sub_level_rating": {
          "wet_avg_rating": 0,
          "dry_grip": 0,
          "corn_stability": 0,
          "steer_resp": 0,
          "ride_com": 0,
          "noise_level": 0,
          "treadwear": 0,
          "total_avg_rating": 0,
          "total_rate": 0
      }
  };
    currency:any='';
  constructor(
      private pgServ:PageService,
      private router:Router,
      private route: ActivatedRoute,
      private CartService:CartService,
      private LanguageService:LanguageService,
      private CrudService:CrudService,
      public productsService: ProductsService,
	  private seoService: SEOService
  ) { }

  ngOnInit() {
    this.product_id=this.route.snapshot.params.id;
    this.CrudService.list({},'tyre/'+this.product_id).subscribe(data => {
         this.record = data.data.product;
         if(!this.record){
            this.router.navigate(['**']);
         }
      });
	   const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
          let  query_params1={
          "fld_product_id":this.product_id,
          };
        this.CrudService.add(query_params1,'product_reviews').subscribe(data => {
          if(data.status==true){
               this.rating_data = data.data;
          }
          });

      this.LanguageService.currency.subscribe(res=>{
      this.currency=res;
      });

  }

  ngAfterViewInit(){
    setTimeout (() => {
      $("#zoom_detail").elevateZoom({
         zoomType: "inner",
         cursor: "crosshair"
       });

      }, 3000);
  }
  add_to_cart(qty:any,product:any){
      var item={
        "product_id":product.id,
        "qty":qty,
        "data":product
      };

   this.CartService.addToCart(item);


  }
  ngOnDestroy(){
      $('.zoomContainer').remove();
      //this.CookieService.delete('compareproducts')
  }

}
