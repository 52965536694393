import { ConfigService } from './ConfigService.service';
import {LanguageService} from './language.service';
import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import 'rxjs/Rx'
import { Observable ,forkJoin } from 'rxjs';
import 'rxjs/add/observable/forkJoin'
declare var $: any;
@Injectable()
export class CrudService {
  customerToken:any;
  customer:any;
  authToken: any;
  user: any;
  isDev:any;
  currentlang:any;
  constructor(
      private http: Http,
      private config: ConfigService,
      public siteService: LanguageService
      ) {
      this.isDev = false;
      this.siteService.selectedLang.subscribe(res=>{
        this.currentlang=res;
    });
      }

      insta(data) : Observable <any>{
        data='gulfexalbabtain';
        const params = 'gulfexalbabtain';
        let querystring = { "lang" : 2};
        $.extend(params,querystring);
        const url2 = 'https://www.instagram.com/'+data+'/?__a=1&limit=2';
        console.log(url2);
        return this.http.get(url2,{params})
                .pipe(map(res => res.json()));
      }

      addByFormData(data,url) : Observable <any>{
        const params = data;
      data.append('lang',  this.currentlang);
     return this.http.post(this.config.backendUrl+url+"?lang="+this.currentlang, params)
      .pipe(map(res => res.json()));
    }


    addSummer(data,url) : Observable <any>{
      const params = data;
   return this.http.post(this.config.backendUrl+url+"?lang="+this.currentlang, params)
    .pipe(map(res => res.json()));

    }

    add(data,url) : Observable <any>{
      const params = data;
    let querystring = { "lang" :  this.currentlang};
    $.extend(params,querystring);
    return this.http.post(this.config.backendUrl+url+"?lang="+this.currentlang, params)
    .pipe(map(res => res.json()));
  }
  list(data,url) : Observable <any>{
    const params = data;
    let querystring = { "lang" :  this.currentlang};
    $.extend(params,querystring);
    return this.http.get(this.config.backendUrl+url,{params})
            .pipe(map(res => res.json()));
  }

  delete(url) : Observable <any>{
    return this.http.delete(this.config.backendUrl+url)
    .pipe(map(res => res.json()));
  }

  handleMutipleCallAPI( inputObject ) : Observable <any>{
  let observableBatch = [];
    inputObject.forEach((data,key ) => {
    const params = data.params;
    let querystring = { "lang" :  this.currentlang};
    $.extend(params,querystring);
      observableBatch.push( this.http.get(this.config.backendUrl+data.url,{params}).pipe(map(res => res.json())) );
  });
  return Observable.forkJoin(observableBatch);
}
  update(data,url) : Observable <any>{
    return this.http.patch(this.config.backendUrl+url, data)
    .pipe(map(res => res.json()));
  }
  withOutImage(data,url) : Observable <any>{
    return this.http.patch(this.config.backendUrl+url, data)
    .pipe(map(res => res.json()));
  }
}
