import { Component, OnInit ,AfterViewInit,OnDestroy } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { CartService } from 'src/app/common_service/cart.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
  import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
        site_info:any;
        order_details=[];
        api_calling=false;
        currency:any;
    constructor(
    private authService: AuthService,
    private translate: TranslateService,
      public siteService: LanguageService,
    private pageSerice: PageService,
     private CartService:CartService,
    public productsService: ProductsService,
      private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
     private config: ConfigService,
	 private seoService: SEOService) { 
         
 
         
     }
      traking_from = this.fb.group({
      order_id: ['', [
        Validators.required,
      ]],
       mobile: ['', [
            Validators.required,
            Validators.pattern("^[0-9_-]{8}"),
            RxwebValidators.digit()
       ]]
    });

  ngOnInit() {
      this.siteService.currency.subscribe(res=>{
   this.currency=res;
   });
   
   const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
   
        this.CartService.site_info.subscribe(res=>{
                this.site_info=res;
                });
  }
    total(order){
       return ((order.spcl_price*order.fld_order_qty)+order.fld__shipping_charges+order.fld_installation_charges-order.fld_cpm_amount)
     }
  traking(e){

        if (e) e.preventDefault();
                if(!this.traking_from.valid){
                this.traking_from.markAsTouched();
                return ;
                }
        this.api_calling=true;
    const formData = new FormData();
    formData.append('order_id', this.traking_from.controls['order_id'].value);
    formData.append('mobile_number', this.traking_from.controls['mobile'].value);
 

    this.CrudService.addByFormData(formData, 'trackorder').subscribe(res => {
      this.api_calling=false;
      this.traking_from.reset();
      if (res.status==true) {
      this.api_calling=false;
      this.order_details=res.data;
      console.log( this.order_details);
      if( this.order_details.length==0){
         this.pageSerice.snakeMessage(res.msg,'Danger');
      } else{
       

         document.querySelector('#new_target').scrollIntoView({ behavior: 'smooth' });
     
      }
      } else {
      this.api_calling=false;
      this.pageSerice.snakeMessage(res.msg,'Danger');
      }
    });
  }

}
