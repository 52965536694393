

import { Component, OnInit ,AfterViewInit ,NgZone } from '@angular/core';

import { CrudService } from 'src/app/common_service/crud.service';



import { AutheService } from 'src/app/common_service/authe.service';

import { Router } from '@angular/router';

import { CartService } from 'src/app/common_service/cart.service';
import { SEOService } from '../../../common_service/seo.service';

import {ActivatedRoute} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {PageService} from '../../../common_service/page.service';

import { ProductsService } from 'src/app/common_service/products.service';

import {Title , Meta} from '@angular/platform-browser';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RxwebValidators } from '@rxweb/reactive-form-validators';

import { AuthService } from "angular-6-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";

import {LanguageService} from 'src/app/common_service/language.service';

 import { ConfigService } from '../../../common_service/ConfigService.service';

 import {CookieService} from 'ngx-cookie-service';
 import { BehaviorSubject, Observable, merge, of } from 'rxjs';

 declare var $: any;

@Component({

  selector: 'app-login',

  templateUrl: './login.component.html',

  styleUrls: ['./login.component.css']

})

export class LoginComponent implements OnInit  {

  sitelang='';

      blocks:any=[];

      api_calling:any=false;

        api_calling2:any=false;

            site_info:any;
            
        providers:any=[];    

  constructor(
    private zone: NgZone,
      private translate: TranslateService,

     private authService: AutheService,

        private AuthServiceSocial: AuthService,

      public siteService: LanguageService,

      private pageSerice: PageService,

      private productsService: ProductsService,

      private router:Router,

      private fb: FormBuilder,

      private route: ActivatedRoute,

      private title:Title,

      private meta:Meta,

      private CrudService:CrudService,

        private CartService:CartService,
		private seoService: SEOService,

      private config: ConfigService,

      private CookieService:CookieService,

    ) { 

  
        console.log((window as any).Zone.current.name);
         this.zone.run(() => this.init$.next(true));
    
    }
    
    public signOut(): Observable<any> {
      return new Observable((subscriber) => {
        this.googleAuth.signOut().then((err: any) => {
          this.authenticated = false;
          subscriber.next(err);
        });
      });
    }

    public authenticated: boolean;
    private readonly url = 'https://apis.google.com/js/platform.js';
    private init$ = new BehaviorSubject<boolean>(false);
    private googleAuth: any;


    login_form = this.fb.group({

       email: ['', [

          Validators.required,

          RxwebValidators.email()

      ]],

        password: ['', [

        Validators.required
        

        ]],

    });



    reg_form = this.fb.group({

       email: ['', [

          Validators.required,

          RxwebValidators.email()

      ]],

      name: ['', [

         Validators.required

     ]],

        password: ['', [ 
          Validators.required,
          Validators.minLength(6),
        ]],

    });

username: string;

password: string;



 page_info= {

  page_title :'',

    boxtitle:"",

    breadCrum:"",

    meta_data :{

      Keywords:"",

      description:""

    }

}


login(e){

    if (e) e.preventDefault();
if(!this.login_form.valid){
              this.login_form.markAsTouched();
            return ;
        }
       this.api_calling2=true;

  const formData = new FormData();

  for (var key in this.login_form.controls) {

        formData.append(key, this.login_form.controls[key].value);

  }

    if (localStorage.getItem('customer_cart')) {

      formData.append('user_cookie', localStorage.getItem('customer_cart'));

    }

  this.CrudService.add(formData, 'login').subscribe(res => {

    this.api_calling2=false;

    if (res.status==true) {

              this.authService.storeUserData(res.data);

              let  query_params={

                "fld_user_id":this.authService.userdata().fld_user_id,

              };

               this.CrudService.add(query_params,'userDefaultaddress').subscribe(data => {

                 if(data.status==true){

                     localStorage.setItem('user_default_delivery_address',data.data.fld_address_id);

                 }

                });

              this.router.navigate(['home']);

              this.login_form.reset();

      this.pageSerice.snakeMessage(res.msg,'success');

    } else {

       this.api_calling2=false;

      this.pageSerice.snakeMessage(res.msg,'Danger');

    }

  });





}

registration(e){

    if (e) e.preventDefault();
if(!this.reg_form.valid){
              this.reg_form.markAsTouched();
            return ;
        }
       this.api_calling=true;

  var userentry={

         "name":this.reg_form.controls['name'].value,

         "phone":'',

         "email":this.reg_form.controls['email'].value,

         "isEmailVerified":0,

         "registration_type":'MANUAL',

         "google_id":'',

         "password":this.reg_form.controls['password'].value

  }



  this.CrudService.add(userentry, 'registration').subscribe(res => {

    if (res.status==true) {

         this.pageSerice.snakeMessage(res.msg,'success');

      this.reg_form.reset();

    } else {

       this.api_calling=false;

      this.pageSerice.snakeMessage(res.msg,'Danger');

    }

  });

}

signInWithGoogle(): void {

    let socialPlatformProvider;
    socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    console.log(socialPlatformProvider);

this.AuthServiceSocial.signIn(socialPlatformProvider).then(
(userData) => {
  var userentry={
    "name":userData.name,
    "phone":'',
    "email":userData.email,
    "isEmailVerified":1,
    "registration_type":userData.provider,
    "google_id":userData.id,
    "password":userData.id
}
   this.commonSocialLogin(userentry);   
}
);

  }

  commonSocialLogin(userData){
    this.CrudService.add(userData, 'social_login').subscribe(res => {

      if (res.status==true) {

            this.authService.storeUserData(res.data);

            this.router.navigate(['home']);

           this.pageSerice.snakeMessage(res.msg,'success');

      } else {

        this.pageSerice.snakeMessage(res.msg,'Danger');

      }

    });

  }



signInWithFB(): void {
      let socialPlatformProvider;
        socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;

  this.AuthServiceSocial.signIn(socialPlatformProvider).then(
    (userData) => {
      console.log( userData);
      var userentry={
        "name":userData.name,
        "phone":'',
        "email":userData.email,
        "isEmailVerified":1,
        "registration_type":userData.provider,
        "google_id":userData.id,
        "password":userData.id
 }
 this.commonSocialLogin(userentry);   
          
    }
  );

}

   ngOnInit() {

     this.CartService.site_info.subscribe(res=>{

         this.site_info=res;

     });
	 
	 const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);

     this.AuthServiceSocial.authState.subscribe((user) => {

       var userentry={

              "name":user.name,

              "phone":'',

              "email":user.email,

              "isEmailVerified":1,

              "registration_type":user.provider,

              "google_id":user.id,

              "password":user.id

       }
          console.log(userentry);

     });



    this.page_info= {

      page_title :this.translate.instant('login_reg_forget_change_pass.page_title'),

        boxtitle:this.translate.instant('login_reg_forget_change_pass.boxtitle'),

        breadCrum:this.translate.instant('login_reg_forget_change_pass.bread_crum'),

        meta_data :{

          Keywords:this.translate.instant('login_reg_forget_change_pass.bread_crum'),

          description:this.translate.instant('login_reg_forget_change_pass.bread_crum'),

        }

    }

       //this.pageSerice.pageInfo(this,this.page_info);



       let  query_params={};

        let apisCallData=[

             {

             "url":"home_blocks",

             "params":query_params

             }

        ];

        this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {

            this.blocks=data[0].data;

      });

}





}

