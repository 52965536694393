import { Component, OnInit, ViewChild, ElementRef,AfterViewInit} from '@angular/core';
import { Options } from 'ng5-slider';
import {PageService} from '../../../common_service/page.service';
import {Title , Meta} from '@angular/platform-browser';
import { ProductsService } from 'src/app/common_service/products.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { CartService } from 'src/app/common_service/cart.service';
import { CrudService } from 'src/app/common_service/crud.service';
import { LanguageService } from 'src/app/common_service/language.service';
declare var $: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  record:any={
      "name":"",
      "id":"",
      "description":"",
      "spcl_note":"",
      "image":"",
      "image1":"",
      "image2":"",
      "image3":""
  };
  id:any;
  name:any;
  site_info:any;
  constructor(  private pgServ:PageService,
    private router:Router,
    private route: ActivatedRoute,
    private CartService:CartService,
    private title:Title,
    private meta:Meta,  
      private LanguageService:LanguageService,
    private CrudService:CrudService,
      public productsService: ProductsService,
      private pageSerice: PageService
  ) { this.router.routeReuseStrategy.shouldReuseRoute = () => false;}

  ngOnInit() {
        this.CartService.site_info.subscribe(res=>{
            this.site_info=res;
            });
    this.id=this.route.snapshot.params.id;
      this.name=this.route.snapshot.params.name;
    this.CrudService.list({"service_id":this.id},'service_facility').subscribe(data => {
         this.record = data.data;
         this.pageSerice.pageInfo(this,{
          "page_title":this.record.meta_title,
          "meta_data":{
              "Keywords":this.record.meta_keyword,
              "title":this.record.meta_title,
              "description":this.record.meta_description
          }
      });
      });

  }

}
