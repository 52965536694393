import { Component, OnInit ,OnDestroy,AfterViewInit} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import { ProductsService } from 'src/app/common_service/products.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { CrudService } from 'src/app/common_service/crud.service';
import {PageService} from 'src/app/common_service/page.service';
import { CartService } from 'src/app/common_service/cart.service';
import { LanguageService } from 'src/app/common_service/language.service';
import {of} from 'rxjs/observable/of';
declare var $: any;
@Component({
  selector: 'app-product-compare',
  templateUrl: './product-compare.component.html',
  styleUrls: ['./product-compare.component.css']
})
export class ProductCompareComponent implements OnInit ,OnDestroy,AfterViewInit{
records:any=[] ;
product_type:any='tyre';
    currency:any='';
  constructor(
      private CookieService:CookieService,
      private pageSerice: PageService,
      private CrudService:CrudService,
        private CartService:CartService,
          private LanguageService:LanguageService,
        private router:Router,
    private route: ActivatedRoute,
      public productsService: ProductsService,
  ) {
    }
ngAfterViewInit(){
  setTimeout (() => {
    $('#compareprd').owlCarousel({
      dots: false,
      margin: 0,
      responsiveClass: true,
      responsive: {
          0: {
              items: 1,
              nav: true
          },
          600: {
              items: 3,
              nav: false
          },
          1000: {
              items: 4,
              nav: true,
              loop: false,
              margin: 0
          }
      }
  });
  $('.compareDiv').show();
  $('html, body').animate({
          scrollTop: $(".scrollto").offset().top
      }, 1000);
},3000);
}
    add_to_cart(qty:any,product:any){
        var item={
          "product_id":product.id,
          "qty":qty,
          "data":product
        };
      this.CartService.addToCart(item);

    }

  ngOnInit() {
    this.LanguageService.currency.subscribe(res=>{
    this.currency=res;
    });
    if(localStorage.getItem('compareproducts')){
          var products_data=localStorage.getItem('compareproducts');
         
          let cart =JSON.parse(products_data);
          console.log(cart);
            var ids = cart.toString();
            var item={
              "products_ids":ids
            };
            this.CrudService.list(item,'checkAllProducts').subscribe(data => {
                if(data.success){
                  this.records = data.data;
                  if(this.records.length>0){
                    var type=this.records[0].product_type;
                    if(type==1){
                        this.product_type='tyre';
                    }
                    if(type==2){
                      this.product_type='battery';
                    }
                  }
                  console.log(type);
              } else{
                this.router.navigate(['home']);
              }

            });
    } else{
        this.router.navigate(['home']);
    }

  }
  close_this(index){
    $("#row"+index).remove();
    $('#compareprd').owlCarousel({
      dots: false,
      margin: 0,
      responsiveClass: true,
      responsive: {
          0: {
              items: 1,
              nav: true
          },
          600: {
              items: 3,
              nav: false
          },
          1000: {
              items: 4,
              nav: true,
              loop: false,
              margin: 0
          }
      }
  });
    this.CartService.remove_from_compare(index);
    if(localStorage.getItem('compareproducts')){
        var products_data=localStorage.getItem('compareproducts');
      let cart =JSON.parse(products_data);
      if(cart.length==0){
        this.router.navigate(['home']);
      }
    } else{
        this.router.navigate(['home']);
    }
  }
  ngOnDestroy(){
      //this.CookieService.delete('compareproducts')
  }

}
