import { Component, OnInit, ViewChild, ElementRef,AfterViewInit} from '@angular/core';
import { Options } from 'ng5-slider';
import {PageService} from 'src/app/common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {ActivatedRoute} from '@angular/router';
import { CartService } from 'src/app/common_service/cart.service';
import { CrudService } from 'src/app/common_service/crud.service';
import { LanguageService } from 'src/app/common_service/language.service';
declare var $: any;

@Component({
  selector: 'app-nomatch',
  templateUrl: './nomatch.component.html',
  styleUrls: ['./nomatch.component.css']
})
export class NoMatchComponent implements OnInit {
    product_id:any;
    product_code:any;
    api_calling=false;

  constructor(
    private pgServ:PageService,
    private router:Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private CartService:CartService,
      public authService: AutheService,
    private fb: FormBuilder,
    private LanguageService:LanguageService,
    private CrudService:CrudService,
      private pageSerice:PageService,
    public productsService: ProductsService
  ) { }

  ngOnInit() {
    
  }
      postReview(e){
      }
}
