import { Component, OnInit, ViewChild, ElementRef,AfterViewInit} from '@angular/core';
import { Options } from 'ng5-slider';
import {PageService} from 'src/app/common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {ActivatedRoute} from '@angular/router';
import { CartService } from 'src/app/common_service/cart.service';
import { CrudService } from 'src/app/common_service/crud.service';
import { LanguageService } from 'src/app/common_service/language.service';
declare var $: any;

@Component({
  selector: 'app-installerreview',
  templateUrl: './installerreview.component.html',
  styleUrls: ['./installerreview.component.css']
})
export class InstallerReviewComponent implements OnInit {
    product_id:any;
    product_code:any;
    api_calling=false;

  constructor(
    private pgServ:PageService,
    private router:Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private CartService:CartService,
      public authService: AutheService,
    private fb: FormBuilder,
    private LanguageService:LanguageService,
    private CrudService:CrudService,
      private pageSerice:PageService,
    public productsService: ProductsService
  ) { }
  reviewForm = this.fb.group({
    steer_resp: ['', [
         Validators.required
    ]],
    ride_com: ['', [
         Validators.required
    ]],
    noise_level: ['', [
         Validators.required
    ]],
    customer_name: ['', [
         Validators.required
    ]],
    comments: ['', [
         Validators.required
    ]],
  });
  ngOnInit() {
        this.product_id=this.route.snapshot.params.installer_id;
      //   this.product_code=this.route.snapshot.params.name.replace("~", ' ');

  }
      postReview(e){
        if (e) e.preventDefault();
                  this.api_calling=true;
          let  query_params={
            "fld_user_id":this.authService.userdata().fld_user_id,
            "installer_id":this.product_id,
            "courtesy":this.reviewForm.controls['steer_resp'].value,
            "service":this.reviewForm.controls['ride_com'].value,
            "cleaness":this.reviewForm.controls['noise_level'].value,
            "customer_name":this.reviewForm.controls['customer_name'].value,
            "comments":this.reviewForm.controls['comments'].value
        };
        this.CrudService.add(query_params,'post_installer_review').subscribe(data => {
              this.api_calling=false;
              if (data.status==true) {
                this.reviewForm.reset();
                this.pageSerice.snakeMessage(data.msg,'success');
              } else {
                this.pageSerice.snakeMessage(data.msg,'Danger');
              }

      });
      }
}
