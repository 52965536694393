import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import {ActivatedRoute} from '@angular/router';
import {PageService} from '../../../common_service/page.service';
import { SEOService } from '../../../common_service/seo.service';
import {Title , Meta} from '@angular/platform-browser';
@Component({
  selector: 'app-branddetails',
  templateUrl: './branddetails.component.html',
  styleUrls: ['./branddetails.component.css']
})
export class BrandDetailsComponent implements OnInit {
brandData:any={
  "fld_brand_name":""
};
brand_id:any;
  constructor(  
    private CrudService:CrudService,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,  
    private pageSerice: PageService,
	private seoService: SEOService) { 

    }

  ngOnInit() {
    this.brand_id=this.route.snapshot.params.brand_id;
    let  query_params={
        "id":this.brand_id
          };
	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
      this.CrudService.list(query_params,'brandData').subscribe(data => {
        if (data.status) {
          this.brandData=data.data;
          this.pageSerice.pageInfo(this,{
            "page_title":this.brandData.meta_title,
            "meta_data":{
                "Keywords":this.brandData.meta_keyword,
                "title":this.brandData.meta_title,
                "description":this.brandData.meta_description
            }
        });
          }
});
  }

}
