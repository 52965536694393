import { Component, OnInit, ViewChild, ElementRef,AfterViewInit} from '@angular/core';
import {PageService} from 'src/app/common_service/page.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { CrudService } from 'src/app/common_service/crud.service';
import { SEOService } from '../../../common_service/seo.service';
import { CartService } from 'src/app/common_service/cart.service';
import { LanguageService } from 'src/app/common_service/language.service';
import {CookieService} from 'ngx-cookie-service';
import { Observable, forkJoin } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit,AfterViewInit {
      total_product_in_cart:any=[];
      fitting_chrages:any=0;
      discount:any=0;
      currency:any='';
      counponApply:any=false;
      fitting_center:any;
      sitelang = '';
      public shoppingCartItems$: Observable<any[]>;
      obsevableResponseArray: Array<any> = [];
  constructor(
    private pgServ:PageService,
    private router:Router,
    private route: ActivatedRoute,
    private CrudService:CrudService,
    public CartService:CartService,
    private LanguageService:LanguageService,
    private CookieService:CookieService,
	private seoService: SEOService
    ) {  }

     ngOnInit() {
         this.CartService.couponApplied.subscribe(res=>{
                this.discount=res.discount_amount;
                this.counponApply=res;
        });
		
		const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);

       this.CartService.itemsInCartSubject.subscribe(
         cart_products => {
           this.total_product_in_cart=cart_products;
         });

             this.LanguageService.currency.subscribe(res=>{
             this.currency=res;
             });
              if(this.CookieService.get('couponCode')){
                this.checkCpn();
              }
             if(this.CookieService.get('installer')){
               let dt=this.CookieService.get('installer');
               this.fitting_center = JSON.parse(dt);
             }
    }

    checkCpn(){
          let randomString='NAN';
          let data=this.CookieService.get('couponCode');
          if (localStorage.getItem('customer_cart')) {
              randomString=localStorage.getItem('customer_cart');
          }
    let  query_params={
        "coupon_code":data,
        "user_cookie":randomString
          };
      this.CrudService.add(query_params,'applyCoupon').subscribe(data => {
        if (data.Error==0) {
          this.discount=data.discount_amount;
          this.CartService.couponApplied.next(data);
          } else {
          this.CookieService.delete('couponCode');
          this.CartService.couponApplied.next(data);
        }

});



    }
    ngAfterViewInit(){

    }
    removeCopoun(){
      this.discount=0;
      this.CartService.couponApplied.next({
        "Error":true,
        "discount_amount":0
      });
      this.CookieService.delete('couponCode');
    }
    openDiscountCode(){
        $('#apply-discount').modal('show');
    }
getSelectedLocation(){
  if(localStorage.getItem('selected_location')){
    var user_selected_location=localStorage.getItem('selected_location');
    var user_selected_latt=localStorage.getItem('selected_latt');
    var user_selected_lang=localStorage.getItem('selected_lang');
    var params={
      "location":user_selected_location.replace(/\s+/g, '-'),
      "latt":user_selected_latt,
      "long":user_selected_lang,
      "radius":10
    }
    return params;
  }
}
    change_qty(qty,type,index){
      if(type==1){
        qty++;
        if(qty<9){
                $('#item'+index).val(qty);
                this.CartService.update_cart_item(qty,index);
        }

      }
      if(type==0){
        if(qty!=1){
            (qty>1)?qty--:qty;
          $('#item'+index).val(qty);
            this.CartService.update_cart_item(qty,index);
        }
      }

    }
   
updaye_cart_item(qty,product_id){
    this.CartService.update_cart_item(qty,product_id);
}
      remove_product_from_cart(product:any){
            this.CartService.remove_from_cart(product);
      }
}
