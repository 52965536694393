import { Component, OnInit ,Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CrudService } from 'src/app/common_service/crud.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
import {PageService} from 'src/app/common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {CookieService} from 'ngx-cookie-service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router'

import { interval } from 'rxjs';
import { map } from 'rxjs/operators'
declare var $: any;
@Component({
  selector: 'app-fheader',
  templateUrl: './fheader.component.html',
  styleUrls: ['./fheader.component.css']
})
export class FheaderComponent implements OnInit {
          public future: Date;
          public futureString: string;
          public diff: number;
          public $counter: Observable<number>;
          public message: string;
          timeLeft: number = 60;


    currentlang = 'en';
    coupon:any;
    site_info:any;
    cpn_date_range:any=false;
    sitelang = '';
    site_url = '';
    browserlang = '';
    page='';
    api_calling=false;
    total_product_in_cart:any=0;
    tyre_services:any=[];
    why_speedmates:any=[];
    car_services:any=[];

  @Input("tyre_service") tyre_service:any;
  @Input('car_service') car_service: any;
 public shoppingCartItems$: Observable<any[]>;

constructor(
            private router:Router,
            private route: ActivatedRoute,
    private translate: TranslateService,
    public siteService: LanguageService,
    private CookieService:CookieService,
      private productsService: ProductsService,
    private CartService:CartService,
    public authService: AutheService,
    private fb: FormBuilder,
    private CrudService:CrudService,
    private pageSerice:PageService,
 ) {
    

           if( localStorage.getItem('customer_cart')){
          var randomString =localStorage.getItem('customer_cart');
          this.getcartproducts(randomString,1);
        } else{
         this.getcartproducts('randomString',0);
        }


   this.CartService.site_info.subscribe(res=>{
       this.site_info=res;
   });
   this.CartService.cpn_data.subscribe(cpn_data_res=>{
       this.coupon=cpn_data_res;
   });

  this.siteService.selectedLang.subscribe(sel_res=>{
    this.currentlang=sel_res;
    this.sitelang=sel_res;
    if(sel_res == 'ar'){
        $('body').addClass('body_ar_class');
        $("#bootstap2").remove();
        $("#style").remove();
          $("#matser").remove();
    } else{
      $("#bootstap").remove();
      $("#style2").remove();
      $("#matser2").remove();
      
    }



});
this.siteService.page.subscribe(res=>{
this.page=res;
});

    this.shoppingCartItems$ = this
    .CartService
    .getItems();

   this.shoppingCartItems$.subscribe(cart_products => this.total_product_in_cart=cart_products.length);

translate.addLangs(['en','ar']);
translate.setDefaultLang('en');
translate.use(this.currentlang);
}
public getcartproducts(customer_cookie,method){

  if(method==1){
    let user_cookie = { "user_cookie" :customer_cookie};
      this.CrudService.add(user_cookie, 'cart').subscribe(res => {
        if(res.status==true){
           this.CartService.itemsInCartSubject.next(res.data);
        } else{
          let cart=[];
          this.CartService.itemsInCartSubject.next(cart);
        }
    });

  } else{
    let cart=[];
    this.CartService.itemsInCartSubject.next(cart);
  }
}
login_form = this.fb.group({
   email: ['', [
      Validators.required,
      RxwebValidators.email()
  ]],
  password: ['', [
  Validators.required
  ]],
});
laguagechanged(){
  this.translate.use(this.browserlang.match('ar|en/')?this.browserlang:'en');
}
closethis(id){
    $('#'+id).slideUp();
}
applycode(name){
    var route=this.router.url;

              let randomString='NAN';
      if (localStorage.getItem('customer_cart')) {
          randomString=localStorage.getItem('customer_cart');
      }

      let  query_params={
          "coupon_code":name,
          "user_cookie":randomString
            };
    this.CrudService.add(query_params,'applyCoupon').subscribe(data => {
          if (data.Error==0) {
              setTimeout(function(){
                   $('#apply-discount').modal('hide');
            }, 1000);
                this.CartService.couponApplied.next(data);
                this.CookieService.set('couponCode',name,365);
               this.pageSerice.snakeMessage(data.Msg,'success');
          } else{
              this.pageSerice.snakeMessage(data.Msg,'Danger');
          }

  });
    if(route!='/cart'){
          this.router.navigate(['cart']);
    }
}
switchLanguage(language: string) {

      this.currentlang=language;
      localStorage.setItem("lang", language);
      if(language === 'ar'){
  
      } else{
      
      }
   
       location.reload();
     
}
coupondata(from_date,to_date){
       setInterval(() => {
            var target_date = new Date(to_date).getTime();
               var current_date = new Date().getTime();
        var seconds_left = (target_date - current_date) / 1000;
                if(seconds_left<1){
              $(".cpndisplay").hide();
            }
         const time = this.getTimeRemaining(seconds_left);
           document.getElementById('countdown').innerHTML = '<span class="days"><span class="hoursbg">' + time.days +  '</span><label>Days</label></span> <span class="hours"><span class="hoursbg">' + time.hours + '</span><label>HRS</label></span> <span class="minutes"><span class="hoursbg">'
             + time.min + '</span><label>MINS</label></span> <span class="seconds"><span class="hoursbg">' + time.seconds + '</span><label>SECS</label></span>';
   },1000);
}
getTimeRemaining(endtime){
  const secondleft=endtime % 86400;;
const seconds = Math.floor( (secondleft) % 60 );
const minutes = Math.floor((secondleft)/60*24 );
 var res = minutes.toString();
 var min=res.substr(0,2);
const hours = Math.floor(secondleft/3600 );
const days = Math.floor( endtime/(86400) );
return {
 days,
 hours,
 min,
 seconds
};
}
  ngOnInit() {

      $("#offser_close").on('click', function(e) {
            e.preventDefault();
            $("#hideCpnbanner").hide();
        });

    this.translate.get('header.site_info.currecny').subscribe((text:string) => {
        this.siteService.currency.next(text);
    });
    this.translate.get('header.site_info.site_url').subscribe((url:string) => {
      this.site_url=url;
    });
    let  query_params={};
     let apisCallData=[
            {
            "url":"feature_coupon",
            "params":query_params
            },
            {
            "url":"service_facilities",
            "params":{"cat_id":2}
            },
            {
            "url":"siteInfo",
            "params":{}
            },
            {
            "url":"faq_categorys",
            "params":query_params
            },
            {
            "url":"crm_page_name_url",
            "params":query_params
            }
     ];

     this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
      this.tyre_services=data[1].data.tyre_service;
      this.car_services=data[1].data.services;
                this.CartService.tyre_services.next(this.tyre_services);
                this.CartService.car_services.next(this.car_services);

               if(data[0].status==true){
                    this.CartService.cpn_data.next(data[0].data);
                 if(data[0].data.coupon_type==2 || data[0].data.coupon_type==3 || data[0].data.coupon_type==6 || data[0].data.coupon_type==7){
                    //this.cpn_date_range=true;
                    //this.coupondata(data[0].data.started_date,data[0].data.end_date);
                 }

                this.coupon=data[0].data;
              }
       if(data[3].status==true){
           this.CartService.site_info.next(data[2].data);
       }

            this.CartService.faq_categorys.next(data[3].data);
             this.why_speedmates=data[4].data;
            this.CartService.crm_page_urls.next(data[4].data);
   });
  }

login(){
   this.api_calling=true;
  const formData = new FormData();
  for (var key in this.login_form.controls) {
        formData.append(key, this.login_form.controls[key].value);
  }
  if (localStorage.getItem('customer_cart')) {
    formData.append('user_cookie', localStorage.getItem('customer_cart'));
  }

  this.CrudService.add(formData, 'login').subscribe(res => {
     this.api_calling=false;
    if (res.status==true) {

      this.authService.storeUserData(res.data);
      this.CartService.loggedin.next('yes');
      let  query_params={
        "fld_user_id":this.authService.userdata().fld_user_id,
      };
       this.CrudService.add(query_params,'userDefaultaddress').subscribe(data => {
         if(data.status==true){
             localStorage.setItem('user_default_delivery_address',data.data.fld_address_id);
         }
        });
          this.login_form.reset();
      this.pageSerice.snakeMessage(res.msg,'success');
    } else {
      this.pageSerice.snakeMessage(res.msg,'Danger');
    }
  });
}
}
