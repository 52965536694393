import { Component, OnInit, ViewChild, ElementRef,AfterViewInit,OnDestroy} from '@angular/core';
import {PageService} from 'src/app/common_service/page.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CrudService } from 'src/app/common_service/crud.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CartService } from 'src/app/common_service/cart.service';
import { LanguageService } from 'src/app/common_service/language.service';
import { AutheService } from 'src/app/common_service/authe.service';
import {CookieService} from 'ngx-cookie-service';
//import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, forkJoin } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit,AfterViewInit,OnDestroy {
  api_calling=false;
  state_data:any;
  city_data:any;
  checkbox_error=false;
      total_product_in_cart:any=[];
      fitting_chrages:any=0;
        currency:any='';
            fitting_center:any;

            discount:any=0;
            giftCard:any;
            giftCardAmount:any=0;
            couponCode:any=''
            counponApply:any='';

      public shoppingCartItems$: Observable<any[]>;
      obsevableResponseArray: Array<any> = [];
  constructor(
    private translate: TranslateService,
      private pageSerice:PageService,
      private router:Router,
      private route: ActivatedRoute,
      private fb: FormBuilder,
      public authService: AutheService,
      //private deviceService: DeviceDetectorService,
      private CrudService:CrudService,
      public CartService:CartService,
      private LanguageService:LanguageService,
      private CookieService:CookieService
    ) { 

      if (localStorage.getItem('user')) {

      } else{
         this.router.navigate(['login']);
      }

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      $('body').addClass('checkout_hide_common'); 
     
        
    }
    checkout_from = this.fb.group({
      full_name: ['', [
      Validators.required,
         RxwebValidators.maxLength({value:20 })
      ]],
      first_name: ['', [
      Validators.required,
          RxwebValidators.maxLength({value:20 })
       ]],
      last_name: ['', [
      ]],
      email: ['', [
      Validators.required,
          RxwebValidators.email()
      ]],
      mobile: ['', [
      Validators.required,
      Validators.pattern("^[0-9_-]{8}"),
      RxwebValidators.digit()
      ]],
      address: ['', [
      Validators.required,
        RxwebValidators.maxLength({value:255 })
      ]],
      city: ['', [
      Validators.required
      ]],
      country: ['', [
      Validators.required
      ]],
      state: ['', [
      ]],
      description: ['', [

      ]],
      vehicle_plate_number: ['', [
           RxwebValidators.maxLength({value:10 })
      ]],
      vehicle_make: ['', [
      RxwebValidators.maxLength({value:10 })
      ]],
      vehicle_modal: ['', [
        RxwebValidators.alphaNumeric()
      ]],
      vehicle_year: ['', [
      RxwebValidators.maxLength({value:4 }),
      Validators.pattern("^[0-9_-]{4}"),
      RxwebValidators.digit()
      ]],
      vehicle_mileage: ['', [
            RxwebValidators.maxLength({value:10 }),
            RxwebValidators.digit()
      ]],
      payment_method: ['', [
      Validators.required
    ]],
    term: [true, [
  ]],
  privacy: [true, [
  ]]
    });
    selectedState(state){
      let  query_params={
          "state_name":state
    };
      this.CrudService.add(query_params,'cities').subscribe(data => {
          this.city_data=data.data;
    });
    }

    getdefaultaddress(){
      var id='';
      if (localStorage.getItem('user_default_delivery_address')) {
       id=localStorage.getItem('user_default_delivery_address');

     } else{
       if (localStorage.getItem('user_selected_delivery_address')) {
         id=localStorage.getItem('user_selected_delivery_address');
       }
     }
     if(id!='' && this.authService.userloggedIn()){
       let  query_params={
         "fld_user_id":this.authService.userdata().fld_user_id,
         "fld_address_id":id
       };
        this.CrudService.add(query_params,'addressDetails').subscribe(data => {
           if (data.status==true) {
                     let  query_params={
                         "state_name":data.data.fld_user_state
                     };
               this.CrudService.add(query_params,'cities').subscribe(data => {
                 this.city_data=data.data;
             });
             this.checkout_from.patchValue(
                 {
                 first_name: data.data.fld_user_name,
                 last_name: data.data.fld_user_name,
                 email: data.data.fld_user_email,
                 address: data.data.fld_user_address1+" "+data.data.fld_user_address2+" "+data.data.fld_user_block_no,
                 mobile: data.data.fld_user_phone,
                 city: data.data.fld_user_city,
                 state: data.data.fld_user_state,
                 country: data.data.fld_user_state

               }
                );

           }

      });
     }
    }
     ngOnDestroy() {
      $('._sm_header').attr('id', 'mNavbar');
      $("body").removeClass("checkout_hide_common");
        
     }
     ngOnInit() {
          $('#banner_service_carousel').owlCarousel('destroy'); 
            $('#mainslider').owlCarousel('destroy');
            $('#testimonials').owlCarousel('destroy');
            $('#instagram_carousel').owlCarousel('destroy');
            $('#banner_service_carousel').owlCarousel('destroy');
            $('#brands').owlCarousel('destroy');
            $('#offers').owlCarousel('destroy');
            $('#servicetab').owlCarousel('destroy');
             $('.timeselector').owlCarousel('destroy');
            
           if (localStorage.getItem('user')) {
              var dt =JSON.parse(localStorage.getItem('user'));
               console.log(dt.fld_user_id);
                let  query_params={"fld_user_id":dt.fld_user_id};
       this.CrudService.add(query_params,'getuserProfile').subscribe(data => {
             if(data.status==true){
                  this.checkout_from.patchValue(
                 {
                 first_name: data.data.fld_user_name,
                 last_name: data.data.fld_user_name,
                 email: data.data.fld_user_email,
                 mobile: data.data.fld_user_phone
               }
                );
             }
     });
           }
         
       this.getdefaultaddress();
       this.CartService.loggedin.subscribe(res=>{
         if(res!=''){
              this.getdefaultaddress();
         }

      });
       let apisCallData=[
           {
           "url":"states",
           "params":{}
           }
       ];
       this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
         this.state_data=data[0].data;
     });

       this.CartService.couponApplied.subscribe(res=>{
          this.counponApply=res;
      });

      if(this.CookieService.get('couponCode')){
        this.checkCpn();
      }
       this.CartService.itemsInCartSubject.subscribe(
         cart_products => {
           this.total_product_in_cart=cart_products;
           // if(this.total_product_in_cart.length==0){
           //     this.router.navigate(['home']);
           // }
         });
             this.LanguageService.currency.subscribe(res=>{
             this.currency=res;
             });

             if(this.CookieService.get('installer')){
               let dt=this.CookieService.get('installer');
               this.fitting_center = JSON.parse(dt);
             }
             
          
             
    }
    checkCpn(){
          let randomString='NAN';
          let data=this.CookieService.get('couponCode');
          if (localStorage.getItem('customer_cart')) {
              randomString=localStorage.getItem('customer_cart');
          }
    let  query_params={
        "coupon_code":data,
        "user_cookie":randomString
          };
      this.CrudService.add(query_params,'applyCoupon').subscribe(data => {
        if (data.Error==0) {
            this.discount=data.discount_amount;
            this.couponCode=data.coupon_code;
        this.CartService.couponApplied.next(data);
          } else {
            this.CookieService.delete('couponCode');
            this.CartService.couponApplied.next(data);
        }

});



    }
    collpasecart(){
      $(".hidedivcheckout").slideToggle(500);
      $(".summerytab").toggleClass('active');
      $("#checkout_bg").fadeToggle();
      $("header").removeClass("navbar-fixed-top");
    }
    removeGift(){
      this.giftCard=null;
      this.giftCardAmount=0;
    }
    applygiftcard(card){
      if(card){

        if(this.couponCode){
          this.translate.get(['cart.giftCpnConflict'])
          .subscribe(translations => {
            this.pageSerice.snakeMessage(translations['cart.giftCpnConflict'],'Danger');
            });
        }else{
            const formData = new FormData();
            formData.append('gift', card);
            var dt=this.CartService.grandTotal(this.total_product_in_cart,this.fitting_chrages,this.discount,0);
            formData.append('g_order_total',dt.toString() );
            if(this.authService.userloggedIn()){
            formData.append('customer_id', this.authService.userdata().fld_user_id);
            }
          this.CrudService.addByFormData(formData, 'appyGift').subscribe(res => {
          if (res.status==true) {
              this.giftCard=card;
              this.giftCardAmount=res.data;
          this.pageSerice.snakeMessage(res.msg,'success');
          } else {
          this.pageSerice.snakeMessage(res.msg,'Danger');
          }
          });
        }
      } else{
        this.translate.get(['cart.invalid'])
        .subscribe(translations => {
          this.pageSerice.snakeMessage(translations['cart.invalid'],'Danger');
          });
      }
      
       
    }
    opneGfitBox(){
      $(".giftBox").slideToggle("slow");
    }
    
    ngAfterViewInit(){
        $("#mNavbar").removeClass("navbar-fixed-top");
       $('._sm_header').attr('id', 'unkhonw');
  $(window).scroll(function() {
        $("._sm_header").removeClass("navbar-fixed-top");
  });

 
  
    }
    checkout(){
if(!this.checkout_from.controls['term'].value || !this.checkout_from.controls['privacy'].value){
    this.checkbox_error=true;
}
         if(!this.checkout_from.valid || !this.checkout_from.controls['term'].value || !this.checkout_from.controls['privacy'].value){
              this.checkout_from.markAsTouched();
            return ;
        }
    this.checkbox_error=false;
     this.api_calling=true;
// const userBrowser=this.deviceService.getDeviceInfo().browser;
// const userOS=this.deviceService.getDeviceInfo().os;
//     var userDevice='';
//     const isMobile = this.deviceService.isMobile();
//     const isTablet = this.deviceService.isTablet();
//     const isDesktopDevice = this.deviceService.isDesktop();
//     if(isMobile){
//       userDevice='Mobile';
//     }else if(isTablet){
//       userDevice='Tablet';
//     }
//     else if(isDesktopDevice){
//       userDevice='Desktop';
//     }else{
//       userDevice='Unknown';
//     }
       const formData = new FormData();



    let randomString=localStorage.getItem('customer_cart');
    formData.append('user_cookie', randomString);
    formData.append('coupon_code', this.couponCode);
    formData.append('coupon_amout',this.discount);
  if(this.authService.userloggedIn()){
  formData.append('customer_id', this.authService.userdata().fld_user_id);
  }
      if(this.giftCard){
        formData.append('gift_id', this.giftCard);
        formData.append('gift_amount', this.giftCardAmount);
      }
  if(this.CookieService.get('installer')){
    let dt=this.CookieService.get('installer');
    formData.append('installer', dt);
  }
  var dt=this.CartService.grandTotal(this.total_product_in_cart,this.fitting_chrages,this.discount);
  formData.append('installation_charges', '');
  formData.append('fitting_charges', this.fitting_chrages);
  formData.append('g_order_total',dt.toString() );
formData.append('s_order_total', '');
formData.append('txn_id', '');
formData.append('txn_status', '');
formData.append('userOS', '');
formData.append('userBrowser','');
formData.append('userDevice', '');
for (var key in this.checkout_from.controls) {
      formData.append(key, this.checkout_from.controls[key].value);
}
if(this.checkout_from.controls['payment_method'].value==0 || this.checkout_from.controls['payment_method'].value==2 ){
    this.CrudService.add(formData, 'checoutOrder').subscribe(res => {
         this.api_calling=false;
      if (res.status==true) {
        this.checkout_from.reset();
        let  query_params={"order_no":res.data};
        this.router.navigate(['orderStatus'], { queryParams: query_params });
         this.pageSerice.snakeMessage(res.msg,'success');
      } else {
           this.pageSerice.snakeMessage(res.msg,'Danger');
      }
    });
} else if (this.checkout_from.controls['payment_method'].value==1){

    this.CrudService.add(formData, 'process_payment').subscribe(res => {
    if (res.status==true) {
      window.location.href=res.data;
    } else {
         this.pageSerice.snakeMessage(res.msg,'Danger');
    }
  });
}
else{
      this.api_calling=true;
      this.pageSerice.snakeMessage('Something went wrong','Danger');
}



    }

    get allproductsQty(){
      let qty=0;
      this.total_product_in_cart.forEach((p,index)=>{
        qty+=parseInt(p.cart_qty);
      })
      return qty;
    }
     
}
