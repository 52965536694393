import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AutheService } from 'src/app/common_service/authe.service'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor (private AutheService:AutheService, private router:Router){
  }
  canActivate() {
    if(this.AutheService.userloggedIn()) {
      return true;
    } else {
      console.log(this.router);
      // this.router.navigate(['/']);
      return false;
    }
  }
}
