

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import { CrudService } from 'src/app/common_service/crud.service';
import {PageService} from 'src/app/common_service/page.service';
import { AutheService } from 'src/app/common_service/authe.service';
import { Router } from '@angular/router';
import {of} from 'rxjs/observable/of';
//import { DeviceDetectorService } from 'ngx-device-detector';
declare var $: any;
  @Injectable()

export class CartService {

  public itemsInCartSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);

    public tyre_services = new BehaviorSubject([]);
    public faq_categorys = new BehaviorSubject([]);
    public car_services = new BehaviorSubject([]);
    public crm_page_urls = new BehaviorSubject([]);


  public loggedin= new BehaviorSubject('');
  public couponApplied= new BehaviorSubject({
    "Error":true,
    "discount_amount":0
  });

  public site_info= new BehaviorSubject({
        "customer_care_no_1":"6960 0645",
        "customer_care_no_2":"6037 9049",
        "email":"karan.oberoi@babtain.com.kw",
        "whatsApp_no":"6960 0645",
        "facebook_link":"https://www.facebook.com/",
        "twitter_link":"https://twitter.com/?lang=en/",
        "insta_link":"https://www.instagram.com/?hl=en",
        "year":"2020"
      });
  public cpn_data= new BehaviorSubject({
              "id": 0,
              "coupon_name": "WIPE60",
              "coupon_type": 2,
              "max_discount": null,
              "below_cart_amt": 1,
              "above_cart_amt": 1,
              "started_date": null,
              "end_date": null,
              "discount_value": 10,
              "description": "Flash sale",
              "fld_image": "http:\/\/aptechbangalore.com\/speedmate\/uploads\/coupon_banner\/cpn.png"
          });

  public itemsInCart: any = [];

  constructor(
      private CookieService:CookieService,
    //  private deviceService: DeviceDetectorService,
      public authService: AutheService,
      private router:Router,
      private pageSerice: PageService,
      private CrudService:CrudService) {
            if (localStorage.getItem('customer_cart')) {
                 let randomString=localStorage.getItem('customer_cart');
                     this.getcartproducts(randomString,1);
            } else{
                    this.getcartproducts('randomString',0);
            }

     //this.itemsInCartSubject.subscribe(_ => this.itemsInCart = _);
  }
  sum(total_product_in_cart)
  {
    let sum=0;
    total_product_in_cart.forEach((p,index)=>{
      sum+=this.subTotal(p);
    });
    return sum;
  }
   grandTotal(total_product_in_cart,fitting_chrages,discount,gift=0)
  {
    return (this.sum(total_product_in_cart)+fitting_chrages-discount-gift);
  }
  subTotal(product)
  {
      var dt=this.getpriceQTY(product);
      return dt.price*dt.qty;
  }
  getpriceQTY(product)
  {
    if(product.product_type==1){
            var even=(parseInt(product.cart_qty)%4);
            if(even==0){
              if(product.set_of_price>0){
                return {
                  "price":product.set_of_price,
                  "qty":(product.cart_qty/4)
              }
              }else{
                return {
                  "price":product.price,
                  "qty":product.cart_qty
              }
              }
             }else{
              return {
                "price":product.price,
                "qty":product.cart_qty
            }
            }
        
    }else{
      return {
        "price":product.price,
        "qty":product.cart_qty
    }
   
  }
}
  getPrice(product)
  {
    return product.price;
  }

public getcartproducts(customer_cookie,method){
  if(method==1){
    let user_cookie = { "user_cookie" :customer_cookie};
      this.CrudService.add(user_cookie, 'cart').subscribe(res => {
        if(res.status==true){
           this.itemsInCartSubject.next(res.data);
        } else{
          let cart=[];
          this.itemsInCartSubject.next(cart);
        }
    });

  } else{
    let cart=[];
    this.itemsInCartSubject.next(cart);
  }
}
public update_cart_item(qty:any,product_id:any) {

    let params=this.getUserPlatform();
    let querystring = { "product_id" :product_id,"qty":qty,"fld_action_type":1};
    $.extend(params,querystring);
            if(this.authService.userloggedIn()){
                let string = { "customer_id" :this.authService.userdata().fld_user_id};
                $.extend(params,string);
            }
         let randomString=localStorage.getItem('customer_cart');
          let user_cookie = { "user_cookie" :randomString};
          $.extend(params,user_cookie);
            this.CrudService.add(params, 'cart_add_update').subscribe(res => {
              if(res.success==true){
                    this.getcartproducts(randomString,1);
                this.pageSerice.snakeMessage(res.msg,'success');
              } else{
                 this.pageSerice.snakeMessage(res.msg,'Danger');
              }
          });

}
public getUserPlatform(){
  // const userBrowser=this.deviceService.getDeviceInfo().browser;
  // const userOS=this.deviceService.getDeviceInfo().os;
  //     var userDevice='';
  //     const isMobile = this.deviceService.isMobile();
  //     const isTablet = this.deviceService.isTablet();
  //     const isDesktopDevice = this.deviceService.isDesktop();
  //     if(isMobile){
  //       userDevice='Mobile';
  //     }else if(isTablet){
  //       userDevice='Tablet';
  //     }
  //     else if(isDesktopDevice){
  //       userDevice='Desktop';
  //     }else{
  //       userDevice='Unknown';
  //     }
  //     return {
  //         "userDevice":userDevice,
  //         "userOS":userOS,
  //         "userBrowser":userBrowser
  //     };
}
public makeid(length) {
        var today = new Date();
        var date = today.getFullYear()+':'+(today.getMonth()+1)+':'+today.getDate();
        var time = today.getHours() +":"+ today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+time;
        var randomString =Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)+dateTime;


   var result           = '';
   var characters       = '!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'+dateTime;
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}
  public addToCart(item: any) {
//console.log("CheckItem",item);
    if(item.data.product_type==4){
      if (localStorage.getItem('user')) {

      } else{
         this.router.navigate(['login']);
         return false;
      }
    }

//let params=this.getUserPlatform();
let params={};
let querystring = { "product_id" :item.product_id,"qty":item.qty,"fld_action_type":0};
$.extend(params,querystring);
 var randomString=this.makeid(10);
            if (localStorage.getItem('customer_cart')) {
              randomString=localStorage.getItem('customer_cart');
            } else{
                localStorage.setItem('customer_cart', randomString);
                //this.CookieService.set('customer_cart',randomString,365);
            }
            if(this.authService.userloggedIn()){
                let string = { "customer_id" :this.authService.userdata().fld_user_id};
                $.extend(params,string);
            }
      let user_cookie = { "user_cookie" :randomString};
      $.extend(params,user_cookie);

     // console.log("newParms",params);
        this.CrudService.add(params, 'cart_add_update').subscribe(res => {
          if(res.success==true){


                if (localStorage.getItem('withFitting')) {

                } else{
                    var params={};
                    if (localStorage.getItem('selected_location')) {
                      var user_selected_location=localStorage.getItem('selected_location');
                      var user_selected_latt=localStorage.getItem('selected_latt');
                      var user_selected_lang=localStorage.getItem('selected_lang');
                    params={
                        "location":user_selected_location.replace(/\s+/g, '-'),
                        "latt":user_selected_latt,
                        "long":user_selected_lang,
                        "radius":10
                      };
                    }
            if(item.data.product_type==1){
               this.router.navigate(['installation'], { queryParams: params });
            }

                }

            this.getcartproducts(randomString,1);

            this.pageSerice.snakeMessage(res.msg,'success');
          } else{
             this.pageSerice.snakeMessage(res.msg,'Danger');
          }
      });
  }

  public getItems(): Observable<any[]> {
    return this.itemsInCartSubject;
  }

  public addToCompare(items: any) {
            localStorage.removeItem("compareproducts");
            localStorage.setItem('compareproducts', items);
  }
  public remove_from_compare(product:any){
    var cart_products=localStorage.getItem('compareproducts');
    let cart =JSON.parse(cart_products);
    cart = cart.filter(prd => prd != product);
    localStorage.removeItem("compareproducts");
    localStorage.setItem('compareproducts', JSON.stringify(cart));


  }
  public remove_from_cart(product:any){
    // let params=this.getUserPlatform();
    let params={};
    let querystring = { "product_id" :product.product_id,"qty":0,"fld_action_type":2};
    $.extend(params,querystring);
         let randomString=localStorage.getItem('customer_cart');
          let user_cookie = { "user_cookie" :randomString};
          $.extend(params,user_cookie);
            this.CrudService.add(params, 'cart_add_update').subscribe(res => {
              if(res.success==true){
                  this.getcartproducts(randomString,1);
                this.pageSerice.snakeMessage(res.msg,'success');
              } else{
                 this.pageSerice.snakeMessage(res.msg,'Danger');
              }
          });

  }

  public remove_cookie(){
        localStorage.removeItem("customer_cart");
        localStorage.removeItem("gift_id");
        localStorage.removeItem("gift_amount");
      let cart=[];
      this.itemsInCartSubject.next(cart);
          this.couponApplied.next({
            "Error":true,
            "discount_amount":0
          });
          this.CookieService.delete('customer_cart');
          this.CookieService.delete('couponCode');
          this.CookieService.delete('installer');
  }
}
