import { NgModule, Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
@Component({
  selector: 'app-appoinments',
  templateUrl: './appoinments.component.html',
  styleUrls: ['./appoinments.component.css']
})
export class AppoinmentsComponent implements OnInit ,AfterViewInit{
page_data:any;
page_url:any='';
  name:any;
  services:any;
api_calling=false;
msg='';
error=0;
providers:any;
submitted = false;
        constructor(
        private authService: AuthService,
        private translate: TranslateService,
        public siteService: LanguageService,
        private pageSerice: PageService,
        private productsService: ProductsService,
        private router:Router,
        private route: ActivatedRoute,
        private title:Title,
        private meta:Meta,
        private http: Http,
          private fb: FormBuilder,
        private CrudService:CrudService,
        private config: ConfigService
         ){
               this.router.routeReuseStrategy.shouldReuseRoute = () => false;
         }
       booking_form = this.fb.group({
         email: ['', [
           Validators.required,
           RxwebValidators.email()
         ]],
         first_name: ['', [
           Validators.required
         ]],
         last_name: ['', [
           //Validators.required
         ]],
         mobile: ['', [
           Validators.required,
        Validators.pattern("^[0-9_-]{8}"),
        RxwebValidators.digit()
         ]],
         service_reqeust_type: ['', [
           Validators.required
         ]],
         date_time: ['', [
           Validators.required
         ]],
         comments: ['', [
             Validators.required
         ]],
         retail_location: ['', [
           Validators.required
         ]]


       });
  ngOnInit() {
        $('.datetimepicker').datetimepicker({}).on('changeDate', function(ev) {
        // $('.datetimepicker').datetimepicker('hide');
        });
    // $('.datetimepicker').datetimepicker();
        this.name=this.route.snapshot.params.name;
    let  query_params={};
     let apisCallData=[
             {
             "url":"fittling_location_installer",
             "params":query_params
             },
              {
             "url":"get_all_service_facilities",
             "params":query_params
             }
     ];
         this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
            this.providers=data[0].data.data;
            this.services=data[1].data.services;


       });
			
        
  }
  date_selected(val){
     this.booking_form.get("date_time").setValue(val);
  }

  booking(e){
    
      if (e) e.preventDefault();
    
        if(!this.booking_form.valid){
              this.booking_form.markAsTouched();
            return ;
        }
     this.api_calling=true;
    const formData = new FormData();
    formData.append('first_name', this.booking_form.controls['first_name'].value);
    formData.append('last_name', this.booking_form.controls['last_name'].value);
    formData.append('service_type', this.name);
    formData.append('service_id', '1');
    formData.append('date_time', this.booking_form.controls['date_time'].value);
    formData.append('email', this.booking_form.controls['email'].value);
    formData.append('retail_location', this.booking_form.controls['retail_location'].value);
    formData.append('service_reqeust_type', this.booking_form.controls['service_reqeust_type'].value);
    formData.append('mobile', this.booking_form.controls['mobile'].value);
    formData.append('comments', this.booking_form.controls['comments'].value);
    this.CrudService.addByFormData(formData, 'service_booking').subscribe(res => {
      this.api_calling=false;
      if (res.status==true) {
            this.api_calling=false;
             this.booking_form.reset();
            this.msg=res.msg;
            this.error=1;
         
      } else {
      this.api_calling=false;
      this.msg=res.msg;
       this.error=2;
      
      }
      setTimeout(function(){ this.msg=''; this.error=0;}, 3000);
    });
  }
  ngAfterViewInit(){
    
  }
}
