import { NgModule, Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import { CartService } from 'src/app/common_service/cart.service';
import {Title , Meta} from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;

@Component({
  selector: 'app-scratchcardformmobilevan',
  templateUrl: './scratchcardformmobilevan.component.html',
  styleUrls: ['./scratchcardformmobilevan.component.css']
})

export class ScratchCardFormMobileVanComponent implements OnInit,AfterViewInit {
page_data:any;
page_url:any='';
  name:any;
  services:any;
api_calling=false;
msg='';
site_info:any;
error=0;
providers:any;
submitted = false;
        constructor(
        private authService: AuthService,
        private translate: TranslateService,
        public siteService: LanguageService,
        private pageSerice: PageService,
        private CartService:CartService,
        private productsService: ProductsService,
        private router:Router,
        private route: ActivatedRoute,
        private title:Title,
        private meta:Meta,
        private http: Http,
          private fb: FormBuilder,
        private CrudService:CrudService,
        private config: ConfigService,
		private seoService: SEOService
         ){
              
         }
       booking_form = this.fb.group({
         email: ['', [
           RxwebValidators.email()
         ]],
         name: ['', [
          Validators.required
        ]],
         mobile: ['', [
           Validators.required,
        Validators.pattern("^[0-9_-]{8}"),
        RxwebValidators.digit()
         ]],
         code: ['', [
           Validators.required
         ]],
         invoice_number: ['', [
         ]],
         retail_location: ['', [
         ]]


       });
  ngOnInit() {
    this.CartService.site_info.subscribe(res=>{
      this.site_info=res;
      });
      
    let  query_params={};
     let apisCallData=[
             {
             "url":"fittling_location_installer",
             "params":query_params
             }
     ];
	 const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
         this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
            this.providers=data[0].data.data;
       });
			
        
  }

  booking(e){
      if (e) e.preventDefault();
    
        if(!this.booking_form.valid){
              this.booking_form.markAsTouched();
            return ;
        }
        this.api_calling=true;
    const formData = new FormData();
        formData.append('invoice_number',this.booking_form.controls['invoice_number'].value);
        formData.append('code', this.booking_form.controls['code'].value);
        formData.append('email', this.booking_form.controls['email'].value);
        formData.append('location', this.booking_form.controls['retail_location'].value);
        formData.append('mobile_no', this.booking_form.controls['mobile'].value);
        formData.append('name', this.booking_form.controls['name'].value);

    this.CrudService.addByFormData(formData, 'submitCompainForm').subscribe(res => {
      this.api_calling=false;
      if (res.status==true) {
            localStorage.setItem('code', this.booking_form.controls['code'].value);
            localStorage.setItem('cpnUser', res.data);
            localStorage.setItem('scratch', "0");
            localStorage.setItem('verify',"0");
            this.booking_form.reset();
            this.pageSerice.snakeMessage(res.msg,'success');
            this.router.navigate(['verifyPhone']);
          
      } else {
        this.pageSerice.snakeMessage(res.msg,'Danger');
      }
    });
  }
  ngAfterViewInit(){
    
  }
}
