 import { Component, OnInit ,AfterViewInit } from '@angular/core';
  import { CrudService } from 'src/app/common_service/crud.service';
  import {ActivatedRoute} from '@angular/router';
  import { TranslateService } from '@ngx-translate/core';
  import {PageService} from 'src/app/common_service/page.service';
  import { ProductsService } from 'src/app/common_service/products.service';
  import {Title , Meta} from '@angular/platform-browser';
  import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
  import { SocialUser } from "angular-6-social-login";
  import {LanguageService} from 'src/app/common_service/language.service';
  import { ConfigService } from 'src/app/common_service/ConfigService.service';
  import { Http, Headers } from '@angular/http';
  import { CartService } from 'src/app/common_service/cart.service';
  import { Observable, forkJoin } from 'rxjs';
  declare var $: any;
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit ,AfterViewInit{
page ='';
loading = true;
site_info:any;
  constructor(
      private router :Router,
      public siteService: LanguageService,
      private translate: TranslateService,
      private pageSerice: PageService,
      public productsService: ProductsService,
      private route: ActivatedRoute,
      private title:Title,
        private CartService:CartService,
      private meta:Meta,
      private http: Http,
      private CrudService:CrudService,
      private config: ConfigService
  ) {

    this.CartService.site_info.subscribe(res=>{
        this.site_info=res;
    });
    this.router.events.subscribe((event:RouterEvent)=>{
         this.navigationInterceptor(event);
      if(event instanceof NavigationEnd){
        var lastindex=window.location.pathname.lastIndexOf('/')+1;
        this.page = window.location.pathname.substring(lastindex);
        this.siteService.page.next(this.page);
          ///code in team 2 branch d the team 2

      }
    });

      if( localStorage.getItem('lang')){
         const lang =localStorage.getItem('lang');
        this.siteService.selectedLang.next(lang);
       } else{
        this.siteService.selectedLang.next('en');
       }
  }
  navigationInterceptor(event: RouterEvent): void {
   if (event instanceof NavigationStart) {
      $('#preloader').fadeIn();
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
         setTimeout(function(){
                  $('html, body').animate({scrollTop:0},'0');
             }, 1000);
      this.loading = false;
      $('#preloader').fadeOut();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
      $('#preloader').fadeOut();
    }
    if (event instanceof NavigationError) {
      this.loading = false;
         $('#preloader').fadeOut();
    }
  }
  ngOnInit() {


  }
  ngAfterViewInit(){
     setTimeout(function(){

     }, 2000);
  }

}

