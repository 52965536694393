import { Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
@Component({
  selector: 'app-tetimonials',
  templateUrl: './tetimonials.component.html',
  styleUrls: ['./tetimonials.component.css']
})
export class TetimonialsComponent implements OnInit {
customer_reviews:any=[];
page:any
  constructor(  private authService: AuthService,
    private translate: TranslateService,
      public siteService: LanguageService,
    private pageSerice: PageService,
    private productsService: ProductsService,
    private router:Router,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
     private config: ConfigService,
	 private seoService: SEOService) { }

  ngOnInit() {
    let  query_params={};
     let apisCallData=[
         {
         "url":"customerReviews",
         "params":query_params
       }
     ];
	 const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
     this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
         this.customer_reviews=data[0].data;

   });
  }

}
