import { Component, OnInit ,AfterViewInit,OnDestroy } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { CartService } from 'src/app/common_service/cart.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';

 declare var $: any;
 @Component({
   selector: 'app-mobilevanservice',
   templateUrl: './mobilevanservice.component.html',
   styleUrls: ['./mobilevanservice.component.css']
 })
 export class MobileVanServiceComponent implements OnInit,OnDestroy {
  selected_front_tyre_width= '';
  selected_front_tyre_height= '';
  selected_front_tyre_rim_size= '';
    sitelang = '';
    site_info:any;
    page_data:any={
      "page_banner":""
    };
    page_url:any='';
  api_calling=false;

  capctha_data:any={
    "img":""
  };

  banner_image:any;
  tyre_filter:any=false;
  service_filter:any=false;
  filter_pop:any=false;
  brands:any;
  providers:any;
  special_offers:any=[];
    blocks:any=[];
  customer_reviews:any=[];
  page_title:any='Hello';
  private user: SocialUser;
  private loggedIn: boolean;
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
      public siteService: LanguageService,
    private pageSerice: PageService,
     private CartService:CartService,
    public productsService: ProductsService,
      private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
     private config: ConfigService,
	 private seoService: SEOService) {
       this.router.routeReuseStrategy.shouldReuseRoute = () => false;
       this.siteService.selectedLang.subscribe(res=>{
         if(res == 'ar'){
           this.sitelang='ar';
         } else{
           this.sitelang='';
         }
        });
     }
     booking_form = this.fb.group({
       email: ['', [
         Validators.required,
         RxwebValidators.email()
       ]],
       first_name: ['', [
         Validators.required
       ]],
       mobile: ['', [
            Validators.required,
            Validators.pattern("^[0-9_-]{8}"),
            RxwebValidators.digit()
       ]],
       service_reqeust_type: ['mobile_van_service', [
         Validators.required
       ]],
       date: ['', [
         Validators.required
       ]],
       time: ['', [
       ]],
       comments: ['', [
         Validators.required
       ]],
       captcha: ['', [
         Validators.required
       ]],
       retail_location: ['', [
         Validators.required
       ]]
     });
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
    ngOnDestroy() {
            $('#banner_service_carousel').owlCarousel('destroy'); 
            $('#mainslider').owlCarousel('destroy');
            $('#testimonials').owlCarousel('destroy');
            $('#instagram_carousel').owlCarousel('destroy');
            $('#banner_service_carousel').owlCarousel('destroy');
            $('#brands').owlCarousel('destroy');
            $('#offers').owlCarousel('destroy');
            $('#servicetab').owlCarousel('destroy');
    // cleanup logic goes here
  }

  signOut(): void {
    this.authService.signOut();
  }
  pay(): void {

  }
  openTyreFilter(){
    this.tyre_filter=true;
    this.filter_pop=true;
  }
  openServices(){
      setTimeout (() => {
        $('#banner_service_carousel').owlCarousel({
        loop:true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        dots: false,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 2,
                nav: true
            },
            600: {
                items: 3,
                nav: true
            },
            1000: {
                items: 4,
                nav: true,
                margin: 20
            }
        }
        });

      },10);

      this.filter_pop=true;
      this.service_filter=true;

  }
  ngAfterViewInit(){
  }
setAllView(){

  setTimeout (() => {
    $(".regular").slick({
       dots: false,
       arrows: true,
       autoplay:true,
       infinite: true,
       slidesToShow: 1,
       slidesToScroll: 1
   });
    // $('.regular').slick('refresh');
   //  $(".regular").slick({
   //     dots: false,
   //     arrows: true,
   //     autoplay:true,
   //     infinite: true,
   //     slidesToShow: 1,
   //     slidesToScroll: 1
   // });
    $('.service-carousel').owlCarousel({
          loop: true,
          dots: false,
          margin: 5,
          responsiveClass: true,
          responsive: {
              0: {
                  items: 2,
                  nav: true
              },
              600: {
                  items: 3,
                  nav: true
              },
              1000: {
                  items: 4,
                  nav: true,
                  loop: false,
                  margin: 5
              }
          }
      });
            $('#offers').owlCarousel({
                    loop:true,
                    autoplay:true,
                    autoplayTimeout:3000,
                    autoplayHoverPause:true,
                    dots: false,
                    margin: 10,
                    responsiveClass: true,
                responsive: {
                0: {
                items: 1,
                nav: true
                },
                600: {
                items: 3,
                nav: true
                },
                1000: {
                items: 3,
                nav: true,
                margin: 20
                }
                }
            });
$('#brands').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: true
    },
    1000: {
        items: 5,
        nav: true,
        margin: 20
    }
}
});

$('#banner_service_carousel').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 2,
        nav: true
    },
    600: {
        items: 3,
        nav: true
    },
    1000: {
        items: 4,
        nav: true,
        margin: 20
    }
}
});
$('#instagram_carousel').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: false
    },
    1000: {
        items: 4,
        nav: true,
        margin: 30
    }
}
});
$('#testimonials').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: false
    },
    1000: {
        items: 3,
        nav: true,
        margin: 30
    }
}
});
$('.compareDiv').show();




}, 3000);


}
  ngOnInit() {

    // $('.datetimepicker').datetimepicker({
    //   keepOpen: true,
    // });

  //   $('.datetimepicker').datetimepicker({
  //     autoclose: false
  // })

    //  $('.datetimepicker').datetimepicker({});

    $('.datetimepicker').datetimepicker({}).on('changeDate', function(ev) {
      // $('.datetimepicker').datetimepicker('hide');
      });


        this.CrudService.handleMutipleCallAPI([
        {
        "url":"fittling_location_installer",
        "params":{}
        }
        ]).subscribe(data => {
        this.providers=data[0].data.data;
        });


    this.translate.get(['mobile_van.page_title', 'mobile_van.keywords','mobile_van.description',])
    .subscribe(translations => {
        this.pageSerice.pageInfo(this,{
      "page_title":translations['mobile_van.page_title'],
      "meta_data":{
          "Keywords":translations['mobile_van.keywords'],
          "title":translations['mobile_van.page_title'],
          "description":translations['mobile_van.description']
      }
  });
});

const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute); 

    this.productsService.selected_front_tyre_width.next('');
    this.productsService.selected_front_tyre_height.next('');
    this.productsService.selected_front_tyre_rim_size.next('');

    this.productsService.selected_rear_tyre_width.next('');
    this.productsService.selected_rear_tyre_height.next('');
    this.productsService.selected_rear_tyre_rim_size.next('');

    this.productsService.selected_location.next('');
    this.productsService.selected_latt.next('');
    this.productsService.selected_lang.next('');



    this.productsService.selected_make_name.next('');
    this.productsService.selected_modal_name.next('');
      this.productsService.selected_make_id.next('');
    this.productsService.selected_year.next('');
     localStorage.removeItem("withFitting");

    $('html, body').animate({
            scrollTop: $(".scrollto").offset().top
        }, 1000);
                this.CartService.site_info.subscribe(res=>{
                this.site_info=res;
                });
    // subcribe tyre dimensions
        this.productsService.selected_front_tyre_width.subscribe(selected_front_tyre_width=>{
          this.selected_front_tyre_width= selected_front_tyre_width;
        });

        this.productsService.selected_front_tyre_height.subscribe(selected_front_tyre_height=>{
        this.selected_front_tyre_height= selected_front_tyre_height;
        });

        this.productsService.selected_front_tyre_rim_size.subscribe(selected_front_tyre_rim_size=>{
          this.selected_front_tyre_rim_size= selected_front_tyre_rim_size;
        });
    // subcribe tyre dimensions
    this.translate.get('header.all_brands').subscribe((text:string) => {

      this.page_title=text;

      const  page_data={
          page_title :this.page_title,
          meta_data :{
            Keywords:" Home Speedmate",
            description:"Home page speedmate ",
          },
      };
      //this.pageSerice.pageInfo(this,page_data);
    });

    this.runDependent();
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });
   let  query_params={};
    let apisCallData=[
        {
        "url":"banners",
        "params":query_params
        },
        {
        "url":"homebrand",
        "params":query_params
        },
          {
          "url":"homespecialoffer",
          "params":query_params
          },
        {
        "url":"customerReviews",
        "params":query_params
        },
        {
        "url":"home_blocks",
        "params":query_params
      },
        {
        "url":"crm_page_data",
        "params":{"page_name":"mobile_van_service"}
        },
        {
        "url":"getCaptcha",
        "params":{}
        }
    ];
    this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
      this.banner_image=data[0].banner_data;
      this.brands = data[1].data;
      this.special_offers=data[2].data;
      this.customer_reviews=data[3].data;
      this.blocks=data[4].data;
      this.page_data=data[5].data;
      this.capctha_data=data[6].data;
      this.setAllView();
  });
  }

  getNewCapctha(){
    let apisCallData=[
        {
        "url":"getCaptcha",
        "params":{}
        }
    ];
    this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
      this.capctha_data=data[0].data;
    });
  }
  date_selected(val){
    this.booking_form.get("date").setValue(val);
 }
  booking(e){

      if (e) e.preventDefault();
      if(!this.booking_form.valid){
      this.booking_form.markAsTouched();
      return ;
      }
        this.api_calling=true;
    const formData = new FormData();
    formData.append('captcha', this.booking_form.controls['captcha'].value);
    formData.append('c_key', this.capctha_data.key);
    formData.append('first_name', this.booking_form.controls['first_name'].value);
    formData.append('last_name', '');
    formData.append('service_type', this.booking_form.controls['service_reqeust_type'].value);
    formData.append('service_id', '1');
    formData.append('date_time', this.booking_form.controls['date'].value);
    formData.append('email', this.booking_form.controls['email'].value);
    formData.append('retail_location', this.booking_form.controls['retail_location'].value);
    formData.append('service_reqeust_type', this.booking_form.controls['service_reqeust_type'].value);
    formData.append('mobile', this.booking_form.controls['mobile'].value);
    formData.append('comments', this.booking_form.controls['comments'].value);

    this.CrudService.addByFormData(formData, 'service_booking').subscribe(res => {
      this.api_calling=false;
      if (res.status==true) {
        this.getNewCapctha();
          this.api_calling=false;
          this.booking_form.reset();
        this.pageSerice.snakeMessage(res.msg,'success');
      } else {
      this.api_calling=false;
      this.pageSerice.snakeMessage(res.msg,'Danger');
      }
    });
  }

  date_selected1(val){
     this.booking_form.get("time").setValue(val);
  }
  callSnackBar(){
    this.pageSerice.snakeMessage('Snack bar called','success');
  }
  runDependent(){


  $('.service-carousel').owlCarousel({
    loop: true,
    dots: false,
    margin: 5,
    responsiveClass: true,
    responsive: {
        0: {
            items: 2,
            nav: true
        },
        600: {
            items: 3,
            nav: true
        },
        1000: {
            items: 4,
            nav: true,
            loop: false,
            margin: 5
        }
    }
});
$('#banner_service_carousel').owlCarousel({
  loop: true,
  dots: false,
  margin: 10,
  responsiveClass: true,
  responsive: {
      0: {
          items: 2,
          nav: true
      },
      600: {
          items: 3,
          nav: false
      },
      1000: {
          items: 4,
          nav: true,
          loop: false,
          margin: 20
      }
  }
})

$('#instagram_carousel').owlCarousel({
  loop: true,
  dots: false,
  margin: 10,
  responsiveClass: true,
  responsive: {
      0: {
          items: 1,
          nav: true
      },
      600: {
          items: 3,
          nav: false
      },
      1000: {
          items: 4,
          nav: false,
          loop: false,
          margin: 30
      }
  }
});

$("#find_tyre").click(function(e){
  e.preventDefault();
  $(".banner_shop").show();
  $(".banner_text, .banner_shop_service").hide();
});
$("#find_service").click(function(e){
  e.preventDefault();
  $(".banner_shop_service").show();
  $(".banner_text, .banner_shop").hide();
});

$("#servicetab .item a").on('click', function(e) {
  e.preventDefault();
  var tabs = $(this).data('servicescont');
  $('.item').removeClass("hilight");
  $(this).parent().addClass("hilight");

  $("#tabscontent .servicescont:not('.hide')").stop().fadeOut('fast', function(e) {
    e.preventDefault();
      $(this).addClass('hide');
   $('#tabscontent .servicescont[data-tabs="'+tabs+'"]').fadeIn('fast').removeClass('hide');
  });
});

  }


}
