import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '../../../common_service/seo.service';
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})

export class PostComponent implements OnInit {
 cats=[
  {
"name":"hii"
  },
  {
    "name":"hello"
  }
];
des={
  name:"ram",
  roll:"123"
}
  constructor(private translate: TranslateService,private seoService: SEOService) { }
 
  ngOnInit() {
	  const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
    // api call
  }

}
