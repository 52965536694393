
import { Component, OnInit ,AfterViewInit ,OnDestroy} from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import {Title , Meta} from '@angular/platform-browser';
import { ProductsService } from 'src/app/common_service/products.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
 @Component({
   selector: 'app-battery',
   templateUrl: './battery.component.html',
   styleUrls: ['./battery.component.css']
 })
 export class BatteryComponent implements OnInit,OnDestroy {
  banner_image:any;
  brands:any;
  sitelang = '';
  special_offers:any=[];
  blocks:any=[];
  cat_data:any={
  "banner":""
};
   brandForFilter:any=[] ;
  private user: SocialUser;
  private loggedIn: boolean;
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    public siteService: LanguageService,
    private pageSerice: PageService,
      public productsService: ProductsService,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
     private config: ConfigService,
	 private seoService: SEOService
   ) {
     this.siteService.selectedLang.subscribe(res=>{
       if(res == 'ar'){
         this.sitelang='ar';
       } else{
         this.sitelang='';
       }

   });
     }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  
    ngOnDestroy() {
            $('#banner_service_carousel').owlCarousel('destroy'); 
            $('#mainslider').owlCarousel('destroy');
            $('#testimonials').owlCarousel('destroy');
            $('#instagram_carousel').owlCarousel('destroy');
            $('#banner_service_carousel').owlCarousel('destroy');
            $('#brands').owlCarousel('destroy');
            $('#offers').owlCarousel('destroy');
            $('#servicetab').owlCarousel('destroy');
    // cleanup logic goes here
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }
  pay(): void {

  }
ngAfterViewInit(){

  setTimeout (() => {
    $('.service-carousel').owlCarousel({
          loop: true,
          dots: false,
          margin: 5,
          responsiveClass: true,
          responsive: {
              0: {
                  items: 2,
                  nav: true
              },
              600: {
                  items: 3,
                  nav: true
              },
              1000: {
                  items: 4,
                  nav: true,
                  loop: false,
                  margin: 5
              }
          }
      });
      $('#offers').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: true
    },
    1000: {
        items: 3,
        nav: true,
        margin: 20
    }
}
});
$('#brands').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: true
    },
    1000: {
        items: 5,
        nav: true,
        margin: 20
    }
}
});

$('#banner_service_carousel').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 2,
        nav: true
    },
    600: {
        items: 3,
        nav: true
    },
    1000: {
        items: 4,
        nav: true,
        margin: 20
    }
}
});
$('#instagram_carousel').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: false
    },
    1000: {
        items: 4,
        nav: true,
        margin: 30
    }
}
});
$('#testimonials').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: false
    },
    1000: {
        items: 3,
        nav: true,
        margin: 30
    }
}
});
$('.compareDiv').show();
      }, 3000);


}
  ngOnInit() {

    this.translate.get(['battery.page_title', 'battery.keywords','battery.description',])
    .subscribe(translations => {
        this.pageSerice.pageInfo(this,{
      "page_title":translations['battery.page_title'],
      "meta_data":{
          "Keywords":translations['battery.keywords'],
          "title":translations['battery.page_title'],
          "description":translations['battery.description']
      }
  });
});

const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
	
    const  page_data={
        page_title :"Home",
        meta_data :{
          Keywords:" Home Speedmate",
          description:"Home page speedmate ",
        },
    };
    this.pageSerice.pageInfo(this,page_data);
    this.runDependent();
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });

    let  query_params={};



      let apisCallData=[
          {
            "url":"getCategoryData",
            "params":{"cat":3}
          },
          {
          "url":"homebrand",
          "params":{"cat_id":2}
          },
          {
          "url":"homespecialoffer",
          "params":query_params
        },
        {
        "url":"home_blocks",
        "params":{"block_type":1}
          },
            {
            "url":"brandsList",
            "params":{"product_type":"battery"}
            }
      ];
      this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
         this.cat_data = data[0].data;
          this.brands = data[1].data;
          this.special_offers=data[2].data;
            this.blocks=data[3].data;
             this.brandForFilter = data[4].data;
    });

  }

  callSnackBar(){
    this.pageSerice.snakeMessage('Snack bar called','success');
  }
  runDependent(){
    $(".regular").slick({
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
  });

  $('.service-carousel').owlCarousel({
    loop: true,
    dots: false,
    margin: 5,
    responsiveClass: true,
    responsive: {
        0: {
            items: 2,
            nav: true
        },
        600: {
            items: 3,
            nav: true
        },
        1000: {
            items: 4,
            nav: true,
            loop: false,
            margin: 5
        }
    }
});

// $('#brands').owlCarousel({
//   loop: true,
//   dots: false,
//   margin: 10,
//   responsiveClass: true,
//   responsive: {
//       0: {
//           items: 1,
//           nav: true
//       },
//       600: {
//           items: 4,
//           nav: false
//       },
//       1000: {
//           items: 5,
//           nav: true,
//           loop: false,
//           margin: 20
//       }
//   }
// });

$('#banner_service_carousel').owlCarousel({
  loop: true,
  dots: false,
  margin: 10,
  responsiveClass: true,
  responsive: {
      0: {
          items: 2,
          nav: true
      },
      600: {
          items: 3,
          nav: false
      },
      1000: {
          items: 4,
          nav: true,
          loop: false,
          margin: 20
      }
  }
})

$('#instagram_carousel').owlCarousel({
  loop: true,
  dots: false,
  margin: 10,
  responsiveClass: true,
  responsive: {
      0: {
          items: 1,
          nav: true
      },
      600: {
          items: 3,
          nav: false
      },
      1000: {
          items: 4,
          nav: false,
          loop: false,
          margin: 30
      }
  }
});

$("#find_tyre").click(function(e){
  e.preventDefault();
  $(".banner_shop").show();
  $(".banner_text, .banner_shop_service").hide();
});
$("#find_service").click(function(e){
  e.preventDefault();
  $(".banner_shop_service").show();
  $(".banner_text, .banner_shop").hide();
});

$("#servicetab .item a").on('click', function(e) {
  e.preventDefault();
  var tabs = $(this).data('servicescont');
  $('.item').removeClass("hilight");
  $(this).parent().addClass("hilight");

  $("#tabscontent .servicescont:not('.hide')").stop().fadeOut('fast', function(e) {
    e.preventDefault();
      $(this).addClass('hide');
   $('#tabscontent .servicescont[data-tabs="'+tabs+'"]').fadeIn('fast').removeClass('hide');
  });
});

  }

}
