import { Component, OnInit,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import {PageService} from 'src/app/common_service/page.service';
import {CookieService} from 'ngx-cookie-service';
import { CartService } from 'src/app/common_service/cart.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-thanku',
  templateUrl: './thanku.component.html',
  styleUrls: ['./thanku.component.css']
})
export class ThankuComponent implements OnInit {
    order_no:any='';
    order_response:any;
  constructor(
        private CrudService:CrudService,
          private CookieService:CookieService,
            private CartService:CartService,
        private pgServ:PageService,
        private router:Router,
        private route: ActivatedRoute
    ) {
     }

  ngOnInit() {
    this.route.queryParams.subscribe(params=>{
      if(params.hasOwnProperty('order_no')){
        this.order_no=params.order_no;
        let  query_params=  {
              "order_no":this.order_no
          }
          if (localStorage.getItem('customer_cart')) {
              let randomString=localStorage.getItem('customer_cart');
                let user_cookie = { "user_cookie" :randomString};

              $.extend(query_params,user_cookie);
          } else{
                let user_cookie = { "user_cookie" :'yogi'};
              $.extend(query_params,user_cookie);
          }


          this.CrudService.add(query_params,'get_payment_status').subscribe(data => {
              this.order_response=data.data;
              if(data.status==true){
                  this.CartService.remove_cookie();
              }
        });

      } else{
          this.router.navigate(['home']);
      }
    });





    $('html, body').animate({
            scrollTop: $(".scrollto").offset().top
        }, 1000);
  }

}
