import { Component, OnInit ,AfterViewInit,OnDestroy } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { CartService } from 'src/app/common_service/cart.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;

@Component({
  selector: 'app-carmake',
  templateUrl: './carmake.component.html',
  styleUrls: ['./carmake.component.css']
})
export class CarMakeComponent implements OnInit {
        site_info:any;
        make_data=[];
        pageData;
        api_calling=false;
        currency:any;
    constructor(
    private authService: AuthService,
    private translate: TranslateService,
      public siteService: LanguageService,
    private pageSerice: PageService,
     private CartService:CartService,
    public productsService: ProductsService,
      private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
     private config: ConfigService,
	 private seoService: SEOService) { 
         
 
         
     }
     
  ngOnInit() {
    this.translate.get(['car_suv_tires_data_meta_data.page_title', 'car_suv_tires_data_meta_data.keywords','car_suv_tires_data_meta_data.description',])
    .subscribe(translations => {
        this.pageSerice.pageInfo(this,{
      "page_title":translations['car_suv_tires_data_meta_data.page_title'],
      "meta_data":{
          "Keywords":translations['car_suv_tires_data_meta_data.keywords'],
          "title":translations['car_suv_tires_data_meta_data.page_title'],
          "description":translations['car_suv_tires_data_meta_data.description']
      }
  });
});

const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);

       this.productsService.selected_front_tyre_width.next('');
        this.productsService.selected_front_tyre_height.next('');
        this.productsService.selected_front_tyre_rim_size.next('');

        this.productsService.selected_rear_tyre_width.next('');
        this.productsService.selected_rear_tyre_height.next('');
        this.productsService.selected_rear_tyre_rim_size.next('');

        this.productsService.selected_location.next('');
        this.productsService.selected_latt.next('');
        this.productsService.selected_lang.next('');



        this.productsService.selected_make_name.next('');
        this.productsService.selected_modal_name.next('');
          this.productsService.selected_make_id.next('');
        this.productsService.selected_year.next('');
        
      let apisCallData=[
        {
        "url":"car_make",
        "params":{"cat_name":"car-suv-tyres"}
      },
      {
        "url":"custom_pages2",
        "params":{"page_name":"car_service"}
      }
    ];
        this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
         this.make_data=data[0].data;
         this.pageData=data[1].data;
        });
    
     
  }
  
    returnUrl(make){
        var name= make.name;
          name= name.toLowerCase();
        return  (name.replace(/\s+/g, '-'));
    }

}
