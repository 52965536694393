import { Component, OnInit ,Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import { AutheService } from 'src/app/common_service/authe.service';
 import { AuthService } from "angular-6-social-login";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CrudService } from 'src/app/common_service/crud.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
import {PageService} from 'src/app/common_service/page.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-dashsidebar',
  templateUrl: './dashsidebar.component.html',
  styleUrls: ['./dashsidebar.component.css']
})
export class DashsidebarComponent implements OnInit {
  @Input('current_page') page_name: string;
  sitelang = '';
  constructor(  private translate: TranslateService,
    public siteService: LanguageService,
    private CartService:CartService,
    public authService: AutheService,
      public social_auth: AuthService,
    private fb: FormBuilder,
      private router:Router,
        private route: ActivatedRoute,
    private CrudService:CrudService,
    private pageSerice:PageService) {
      this.siteService.selectedLang.subscribe(res=>{
        if(res == 'ar'){
                this.sitelang='ar';
        } else{
          this.sitelang='';
        }
    });}

  ngOnInit() {
      	$("#account-btn").on("click", function() {
				$("#mobile-show").slideToggle("2000");
			});
  }
  logout(){
  this.social_auth.signOut();
    this.authService.logout();
this.router.navigate(['home']);
  }

}
