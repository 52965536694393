
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import { CrudService } from 'src/app/common_service/crud.service';
import {PageService} from 'src/app/common_service/page.service';
import {of} from 'rxjs/observable/of';
 declare var $: any;
 declare var google: any;
@Injectable({
  providedIn: 'root'
})
export class ProductsService {

constructor(private CrudService:CrudService) {}

    filter_data = new BehaviorSubject([]);
    car_services = new BehaviorSubject([]);
          selected_front_tyre_width= new BehaviorSubject('');
          selected_front_tyre_height= new BehaviorSubject('');
          selected_front_tyre_rim_size= new BehaviorSubject('');


        selected_location= new BehaviorSubject('');
        selected_latt= new BehaviorSubject('');
        selected_lang= new BehaviorSubject('');


      selected_rear_tyre_width= new BehaviorSubject('');
      selected_rear_tyre_height= new BehaviorSubject('');
      selected_rear_tyre_rim_size= new BehaviorSubject('');


      selected_make_name= new BehaviorSubject('');
      selected_modal_name= new BehaviorSubject('');
      selected_make_id= new BehaviorSubject('');
      selected_modal_id= new BehaviorSubject('');
      selected_year= new BehaviorSubject('');

      selected_fitting= new BehaviorSubject(false);


        filter_applied= new BehaviorSubject('');
        filter_make_modal_applied= new BehaviorSubject('');
      public getTyreConfiguration(filter_typre,type){
        this.filter_data.next([]);
        var params={};
        var last_url=''
switch(filter_typre){

  case 1:
    last_url='get_all_tyre_width';
        this.selected_front_tyre_rim_size.next('');
        this.selected_front_tyre_height.next('');
  break;

  case 2 :
     last_url='get_all_tyre_height';
     this.selected_front_tyre_rim_size.next('');
     this.selected_front_tyre_width.subscribe(selected_front_tyre_width=>{
        $.extend(params,{"width":selected_front_tyre_width});
     });

  break;

  case 3:
    last_url='get_all_tyre_rim_size';

    this.selected_front_tyre_width.subscribe(selected_front_tyre_width=>{
       $.extend(params,{"width":selected_front_tyre_width});
    });
    this.selected_front_tyre_height.subscribe(selected_front_tyre_height=>{
       $.extend(params,{"height":selected_front_tyre_height});
    });
  break;

  case 5:
    last_url='get_all_tyre_width';
    this.selected_rear_tyre_height.next('');
    this.selected_rear_tyre_rim_size.next('');

  break;

  case 6:
     last_url='get_all_tyre_height';
     this.selected_rear_tyre_rim_size.next('');
     this.selected_rear_tyre_width.subscribe(selected_rear_tyre_width=>{
        $.extend(params,{"width":selected_rear_tyre_width});
     });
  break;

  case 7:
      last_url='get_all_tyre_rim_size';
      this.selected_rear_tyre_width.subscribe(selected_rear_tyre_width=>{
         $.extend(params,{"width":selected_rear_tyre_width});
      });
      this.selected_rear_tyre_height.subscribe(selected_rear_tyre_height=>{
         $.extend(params,{"height":selected_rear_tyre_height});
      });
  break;

  case 70:
    last_url='car_make';
        this.selected_modal_id.next('');
  break;

  case 80:
     last_url='car_modal';
      this.selected_modal_id.next('');

     this.selected_make_id.subscribe(selected_make_id=>{
       if(selected_make_id!=''){
         params={
           "make":selected_make_id
         }
       }
     });

  break;

  case 90:
      last_url='car_year';

      this.selected_make_id.subscribe(selected_make_id=>{
         $.extend(params,{"make":selected_make_id});
      });
      this.selected_modal_id.subscribe(selected_modal_id=>{
         $.extend(params,{"modal":selected_modal_id});
      });

  break;
}
if(last_url!=''){
  this.CrudService.list(params,last_url).subscribe(data => {
        if(data.success){
          this.filter_data.next(data.data);
        }
    });
}


      }
  oncheck(e){
    if(e.target.checked){
          localStorage.setItem('withFitting','yes');
        this.selected_fitting.next(true);
    } else{
       localStorage.removeItem("withFitting");
        this.selected_fitting.next(false);
    }
  }
breadCrumb(val){

  return val.replace("_", " ");
}
      product_list_star_rating(rating){
        switch(rating){

          case 0:
          return '<span class="rate_star">'+
            '<i class="disable_star fa fa-star-o"></i>'+
            '<i class="disable_star fa fa-star-o"></i>'+
            '<i class="disable_star fa fa-star-o"></i>'+
            '<i class="disable_star fa fa-star-o"></i>'+
            '<i class="disable_star fa fa-star-o"></i></span>'

          break;

          case 1:
          return '<span class="rate_star">'+
            '<i class="fa fa-star"></i>'+
            '<i class="disable_star fa fa-star-o"></i>'+
            '<i class="disable_star fa fa-star-o"></i>'+
            '<i class="disable_star fa fa-star-o"></i>'+
            '<i class="disable_star fa fa-star-o"></i></span>'

          break;
          case 2:
          return '<span class="rate_star">'+
            '<i class="fa fa-star"></i>'+
            '<i class="fa fa-star"></i>'+
            '<i class="disable_star fa fa-star-o"></i>'+
            '<i class="disable_star fa fa-star-o"></i>'+
            '<i class="disable_star fa fa-star-o"></i></span>'

          break;
          case 3:
          return '<span class="rate_star">'+
            '<i class="fa fa-star"></i>'+
            '<i class="fa fa-star"></i>'+
            '<i class="fa fa-star"></i>'+
            '<i class="disable_star fa fa-star-o"></i>'+
            '<i class="disable_star fa fa-star-o"></i></span>'
          break;

          case 4:
          return '<span class="rate_star">'+
            '<i class="fa fa-star"></i>'+
            '<i class="fa fa-star"></i>'+
            '<i class="fa fa-star"></i>'+
            '<i class="fa fa-star"></i>'+
            '<i class="disable_star fa fa-star-o"></i></span>'
          break;

          case 5:
          return '<span class="rate_star">'+
            '<i class="fa fa-star"></i>'+
            '<i class="fa fa-star"></i>'+
            '<i class="fa fa-star"></i>'+
            '<i class="fa fa-star"></i>'+
            '<i class="fa fa-star"></i></span>'
          break;

        }
      }
      star_rating(rating){
        switch(rating){

          case 0:
          return '<span class="fa fa-star non-rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>';
          break;

          case 1:
          return '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>';

          break;
          case 2:
          return '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>';

          break;
          case 3:
          return '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>';
          break;

          case 4:
          return '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star-o non-rate"></span>';
          break;

          case 5:
          return '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star rate"></span>'+
          '<span class="fa fa-star rate"></span>';
          break;

        }
      }
      level_class(rating){
        switch(rating){

          case 0:
          return 'redbg';
          break;

          case 1:
           return 'light-yellowbg';
          break;

          case 2:
            return 'yellowbg';
          break;

          case 3:
           return 'light-greenbg';
         break;

          case 4:
           return 'light-greenbg';
          break;

          case 5:
          return 'greenbg';
           break;

        }
      }
      total(order){
        return ((order.productTotal)+order.fld__shipping_charges+order.fld_installation_charges-order.fld_cpm_amount-order.gift_card_value)
      }

      offer_zone_params(data){
        switch(data.offer_zone_type){
          case 0:
            return {"offer_type":data.offer_type,"offer_discount":data.offer_discount,"offer_id":data.offer_id,"show_products":data.show_products};
          break;

          case 1:
            return {"offer_type":data.offer_type,"offer_discount":data.offer_discount,"filters":"brand"+data.belong_id,"offer_id":data.offer_id,"show_products":data.show_products};
          break;
        }
      }

      return_days_expire(orderData){
            var d = new Date(orderData.fld_order_date);
            d.setDate(d.getDate() + 15);
          var today_time_string=new Date().getTime();
          var expired_ios_times=d.getTime();
          var res=true;
          if(today_time_string>expired_ios_times){
            res=false;
          }
          return res;
      }
      percentage_off(product){
         var decreased=product.mrp_price-product.price;
        return Math.round((decreased/(product.mrp_price)*100));
       }
       replace_space(name){
           var cat=name.replace(/ /g, "-");
        return  cat;
       }
       
        
       
       
       open_close_modal(open_modal_id,close_modal_id,servicePop){
           console.log(open_modal_id);
         if(close_modal_id!=0){
             $('#'+servicePop+close_modal_id).modal('hide');
         }
         if(open_modal_id!=0){
             $('#'+servicePop+open_modal_id).modal('show');
         }

       }
}
