import { Component, OnInit ,AfterViewInit,OnDestroy } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { CartService } from 'src/app/common_service/cart.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
@Component({
  selector: 'app-caryear',
  templateUrl: './caryear.component.html',
  styleUrls: ['./caryear.component.css']
})
export class CarYearComponent implements OnInit {
        site_info:any;
            make:any='';
            modal:any='';
            make_data=[];
            modal_data=[];
            year_data=[];
        api_calling=false;
        currency:any;
    constructor(
    private authService: AuthService,
    private translate: TranslateService,
      public siteService: LanguageService,
    private pageSerice: PageService,
     private CartService:CartService,
    public productsService: ProductsService,
      private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
     private config: ConfigService) { 
         
 
         
     }
    

  returnUrl(data){
         var name= data.name;
          name= name.toLowerCase();
        return  (name.replace(/\s+/g, '-'))
    }
     resolve_name(data){
         var name= data
          name= name.toUpperCase();
        return  (name.replace('-', ' '))
    }
  ngOnInit() {
         this.productsService.selected_front_tyre_width.next('');
        this.productsService.selected_front_tyre_height.next('');
        this.productsService.selected_front_tyre_rim_size.next('');

        this.productsService.selected_rear_tyre_width.next('');
        this.productsService.selected_rear_tyre_height.next('');
        this.productsService.selected_rear_tyre_rim_size.next('');

        this.productsService.selected_location.next('');
        this.productsService.selected_latt.next('');
        this.productsService.selected_lang.next('');



        this.productsService.selected_make_name.next('');
        this.productsService.selected_modal_name.next('');
          this.productsService.selected_make_id.next('');
        this.productsService.selected_year.next('');
        
       this.make=this.route.snapshot.params.make;
        this.modal=this.route.snapshot.params.modal;
    let apisCallData=[

             {
            "url":"car_year",
            "params":{"make":this.make,"model":this.modal}
            }
    ];
        this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
                this.year_data=data[0].data;
        });
     
  }
    
  traking(e){

      
  }

}
