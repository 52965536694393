import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calluspage',
  templateUrl: './calluspage.component.html',
  styleUrls: ['./calluspage.component.css']
})
export class CalluspageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
