import { Component, OnInit ,AfterViewInit } from '@angular/core';

import { CrudService } from 'src/app/common_service/crud.service';
import { SEOService } from '../../../common_service/seo.service';

import { AuthService } from "angular-6-social-login";

import { Router } from '@angular/router';

import {ActivatedRoute} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {PageService} from '../../../common_service/page.service';

import { ProductsService } from 'src/app/common_service/products.service';

import {Title , Meta} from '@angular/platform-browser';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RxwebValidators } from '@rxweb/reactive-form-validators';

import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";

import { SocialUser } from "angular-6-social-login";

import {LanguageService} from 'src/app/common_service/language.service';

 import { ConfigService } from '../../../common_service/ConfigService.service';

 import { Http, Headers } from '@angular/http';

import { Observable, forkJoin } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

 declare var $: any;



 @Component({
  selector: 'app-reachus',
  templateUrl: './reachus.component.html',
  styleUrls: ['./reachus.component.css']
})
export class ReachUsComponent implements OnInit {

  api_calling=false;

  providers:any=[];
  capctha_data:any;
  blocks:any=[];
  currentlang: any;size;model;
  constructor(

    private authService: AuthService,

    private translate: TranslateService,

    public siteService: LanguageService,

    private pageSerice: PageService,

    private productsService: ProductsService,

    private fb: FormBuilder,

    private router:Router,

    private route: ActivatedRoute,

    private title:Title,

    private meta:Meta,

    private http: Http,

    private CrudService:CrudService,
	private seoService: SEOService,
    private config: ConfigService

    ) { 
      this.siteService.selectedLang.subscribe(res=>{
        this.currentlang=res;
      });
    }

    feedbackForm = this.fb.group({
      first_name: ['', [
      Validators.required
      ]],
      email: ['', [
      RxwebValidators.email()
      ]],
      mobile: ['', [
      Validators.required,
      Validators.pattern("^[0-9_-]{8}"),
      RxwebValidators.digit()
      ]],
      enquiry: ['', [
        Validators.required
      ]],
      modal: [null, [ Validators.required
      ]],
      size: [null, [ Validators.required
      ]],
      image: [null, [
            
      ]],
      retail_location: [null, [ 
            
      ]],
      date: [null, [ 
            
      ]],
      time: [null, [ 
            
      ]],
    });


    date_selected(val){
      this.feedbackForm.get("date").setValue(val);
   }
  ngOnInit() {
}
feedbackcall(e){
  if (e) e.preventDefault();
        if(!this.feedbackForm.valid){
      this.feedbackForm.markAsTouched();
      return ;
      }
     
        this.api_calling=true;

    const formData = new FormData();
        formData.append('first_name', this.feedbackForm.controls['first_name'].value);
        formData.append('email', this.feedbackForm.controls['email'].value);
        formData.append('mobile', this.feedbackForm.controls['mobile'].value);
        formData.append('enquiry', this.feedbackForm.controls['enquiry'].value);
        formData.append('modal', this.feedbackForm.controls['modal'].value);
        formData.append('size', this.feedbackForm.controls['size'].value);
        formData.append('image', this.feedbackForm.controls['image'].value);
        formData.append('date_time', this.feedbackForm.controls['date'].value);
        formData.append('lead_time', this.feedbackForm.controls['time'].value);
        formData.append('retail_location', this.feedbackForm.controls['retail_location'].value);

    this.CrudService.addByFormData(formData, 'lead-form').subscribe(res => {
      this.api_calling=false;
      if (res.status==true) {
            this.feedbackForm.patchValue({
            image: null
            });
            this.feedbackForm.get('image').updateValueAndValidity()

          this.api_calling=false;
          this.feedbackForm.reset();
        this.pageSerice.snakeMessage(res.msg,'success');
      } else {
          this.api_calling=false;
          this.pageSerice.snakeMessage(res.msg,'Danger');
      }
    });
}

}

