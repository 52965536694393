import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from "@angular/animations";
@Component({
  selector: 'app-multipurposeslider',
  templateUrl: './multipurposeslider.component.html',
  styleUrls: ['./multipurposeslider.component.css'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ])
  ]
})


export class MultipurposesliderComponent implements OnInit {
  mpurpose_slides=[
    {
     "title":"First slide label njnjnh",
     "description":"Nulla vitae elit libero, a pharetra augue mollis interdum.",
      "url":"",
      "image":"http://aptechbangalore.com/speedmate/uploads/brand/logo/1590732963-961.png"
    },
    {
      "title":"Second slide label",
      "description":"Nulla vitae elit libero, a pharetra augue mollis interdum.",
       "url":"",
       "image":"http://aptechbangalore.com/speedmate/uploads/brand/logo/1590732963-961.png"
     },
     {
      "title":"Third slide label",
      "description":"Nulla vitae elit libero, a pharetra augue mollis interdum.",
       "url":"",
       "image":"http://aptechbangalore.com/speedmate/uploads/brand/logo/1590732963-961.png"
     },
     {
      "title":"Fourth slide label",
      "description":"Nulla vitae elit libero, a pharetra augue mollis interdum.",
       "url":"",
       "image":"http://aptechbangalore.com/speedmate/uploads/brand/logo/1590732963-961.png"
     },
     {
      "title":"Fifth slide label",
      "description":"Nulla vitae elit libero, a pharetra augue mollis interdum.",
       "url":"",
       "image":"http://aptechbangalore.com/speedmate/uploads/brand/logo/1590732963-961.png"
     }
  ];
  constructor() { }

  ngOnInit() {
  }

}
