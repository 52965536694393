import { NgModule, Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { CartService } from 'src/app/common_service/cart.service';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;

 @Component({
  selector: 'app-scratchcardotp',
  templateUrl: './scratchcardotp.component.html',
  styleUrls: ['./scratchcardotp.component.css']
})
export class ScratchCardOtpComponent implements OnInit,AfterViewInit {
page_data:any;
page_url:any='';
  name:any;
  services:any;
api_calling=false;
msg='';
site_info:any;
error=0;
providers:any;
submitted = false;
        constructor(
        private authService: AuthService,
        private translate: TranslateService,
        public siteService: LanguageService,
        private pageSerice: PageService,
        private CartService:CartService,
        private productsService: ProductsService,
        private router:Router,
        private route: ActivatedRoute,
        private title:Title,
        private meta:Meta,
        private http: Http,
          private fb: FormBuilder,
        private CrudService:CrudService,
        private config: ConfigService
         ){
              
         }
       booking_form = this.fb.group({
         otp: ['', [
          Validators.required,
          RxwebValidators.digit()
         ]]

       });
  ngOnInit() {
    this.CartService.site_info.subscribe(res=>{
      this.site_info=res;
      });
  }
  resendOtp(){
    if (localStorage.getItem('cpnUser')) {
      const formData = new FormData();
        formData.append('id',localStorage.getItem('cpnUser'));
        this.CrudService.addByFormData(formData, 'resendCpnOtp').subscribe(res => {
          if (res.status==true) {
               this.pageSerice.snakeMessage(res.msg,'success'); 
          } else {
               this.pageSerice.snakeMessage(res.msg,'Danger');
          }
        });
    } else{
      this.translate.get(['other_page.form_label.nouser'])
          .subscribe(translations => {
          this.pageSerice.snakeMessage(translations['other_page.form_label.nouser'],'Danger');
          });
    }
  }
  booking(e){
      if (e) e.preventDefault();
    
        if(!this.booking_form.valid){
              this.booking_form.markAsTouched();
            return ;
        }
        if (localStorage.getItem('cpnUser')) {
           this.api_calling=true;
    const formData = new FormData();
    formData.append('id',localStorage.getItem('cpnUser'));
    formData.append('otp', this.booking_form.controls['otp'].value);
      this.CrudService.addByFormData(formData, 'verifyCouponOtp').subscribe(res => {
        this.api_calling=false;
        if (res.status==true) {
              localStorage.setItem('verify',"1");
              this.booking_form.reset();
              this.pageSerice.snakeMessage(res.msg,'success');
              this.router.navigate(['scratch']);
            
        } else {
          this.pageSerice.snakeMessage(res.msg,'Danger');
        }
      });
        } else{
          this.translate.get(['other_page.form_label.nouser'])
          .subscribe(translations => {
          this.pageSerice.snakeMessage(translations['other_page.form_label.nouser'],'Danger');

          });
             
        }
  
  }
  ngAfterViewInit(){
    
  }
}
