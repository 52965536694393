import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../../common_service/seo.service';

@Component({
  selector: 'app-loyalityStep',
  templateUrl: './loyalityStep.component.html',
  styleUrls: ['./loyalityStep.component.css']
})
export class LoyalityStepComponent implements OnInit {

  constructor(private seoService: SEOService) { }

  ngOnInit() {
	  const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
  }

}
