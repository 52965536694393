import { Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  special_offers:any=[];
   banner_data:any={
        "fld_advertisement_image":""
    };
  constructor(
      private authService: AuthService,
      private translate: TranslateService,
      public siteService: LanguageService,
      private pageSerice: PageService,
      public productsService: ProductsService,
      private router:Router,
      private route: ActivatedRoute,
      private title:Title,
      private meta:Meta,
      private http: Http,
      private CrudService:CrudService,
      private config: ConfigService,
	  private seoService: SEOService
 ) { }

  ngOnInit() {
    this.translate.get(['offer.page_title', 'offer.keywords','offer.description',])
    .subscribe(translations => {
        this.pageSerice.pageInfo(this,{
      "page_title":translations['offer.page_title'],
      "meta_data":{
          "Keywords":translations['offer.keywords'],
          "title":translations['offer.page_title'],
          "description":translations['offer.description']
      }
  });
});

	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute); 
	
     let  query_params={};
    let apisCallData=[
        {
        "url":"homespecialoffer",
        "params":query_params
      }, {
         "url":"advertisement",
         "params":{"ads_id":30}
         }
    ];
    this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
        this.special_offers=data[0].data;
         this.banner_data=data[1].data;
  });
  }

}
