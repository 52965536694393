import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CrudService } from 'src/app/common_service/crud.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
import {PageService} from 'src/app/common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-orderdetails',
  templateUrl: './orderdetails.component.html',
  styleUrls: ['./orderdetails.component.css']
})
export class OrderDetailsComponent implements OnInit {
  order_id:any;
  order_data:any
  currency:any;
  constructor(
    private translate: TranslateService,
    public siteService: LanguageService,
    private CartService:CartService,
    public authService: AutheService,
      public productsService: ProductsService,
    private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private CrudService:CrudService,
    private pageSerice:PageService) {

     }

  ngOnInit() {
      this.order_id=this.route.snapshot.params.order_id;
    let  query_params={
        "fld_user_id":this.authService.userdata().fld_user_id,
        "fld_order_id":this.order_id
    };

   this.CrudService.add(query_params,'orderDetails').subscribe(data => {
         if(data.status==true){
           this.order_data=data.data;
         } else{
             this.router.navigate(['myorder/upcomming']);
         }
   });
   this.siteService.currency.subscribe(res=>{
   this.currency=res;
   });
  }
return_days_expire(){

}
}
