import { Component, OnInit ,AfterViewInit,OnDestroy } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit,AfterViewInit,OnDestroy {
  selected_front_tyre_width= '';
  selected_front_tyre_height= '';
  selected_front_tyre_rim_size= '';
  sitelang = '';
   site_info:any;
  banner_image:any;
  tyre_filter:any=false;
  service_filter:any=false;
  filter_pop:any=false;
  brands:any;
  special_offers:any=[];
  instas=[];
    car_services=[];
    blocks:any=[];
  customer_reviews:any=[];
  page_title:any='Hello';
  private user: SocialUser;
  private loggedIn: boolean;
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
      public siteService: LanguageService,
    private pageSerice: PageService,
    public productsService: ProductsService,
    private router:Router,
      private CartService:CartService,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
     private config: ConfigService) {
       this.siteService.selectedLang.subscribe(res=>{
         if(res == 'ar'){
           this.sitelang='ar';
         } else{
           this.sitelang='';
         }

     });
     }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }
  pay(): void {

  }
  openTyreFilter(){
    this.tyre_filter=true;
    this.filter_pop=true;
  }
     ngOnDestroy() {
            $('#banner_service_carousel').owlCarousel('destroy'); 
            $('#mainslider').owlCarousel('destroy');
            $('#testimonials').owlCarousel('destroy');
            $('#instagram_carousel').owlCarousel('destroy');
            $('#banner_service_carousel').owlCarousel('destroy');
            $('#brands').owlCarousel('destroy');
            $('#gallery').owlCarousel('destroy');
            $('#offers').owlCarousel('destroy');
            $('#servicetab').owlCarousel('destroy');
    // cleanup logic goes here
  }
  openServices(){
      setTimeout (() => {
        $('#banner_service_carousel').owlCarousel({
        loop:true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        dots: false,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 2,
                nav: true
            },
            600: {
                items: 3,
                nav: true
            },
            1000: {
                items: 4,
                nav: true,
                margin: 20
            }
        }
        });

      },10);

      this.filter_pop=true;
      this.service_filter=true;

  }
  ngAfterViewInit(){

    // this.translate.get(['insta.username'])
    //     .subscribe(translations => {
    //       console.log(translations['insta.username']);
    //       this.CrudService.insta(translations['insta.username']).subscribe(data => {
    //         this.instas=data.graphql.user.edge_owner_to_timeline_media.edges;
    //   });
    // });
  }
setAllView(){
$('.mainLoader').hide();
  setTimeout (() => {
    
    // $('#mainBanner').owlCarousel({
    //   loop:false,
    //   autoplay:true,
    //   autoplayTimeout:3000,
    //   autoplayHoverPause:true,
    //   dots: false,
    //   margin: 10,
    //   responsiveClass: true,
    //   // responsive: {
    //   //     0: {
    //   //         items: 2,
    //   //         nav: true
    //   //     },
    //   //     600: {
    //   //         items: 3,
    //   //         nav: true
    //   //     },
    //   //     1000: {
    //   //         items: 5,
    //   //         nav: true,
    //   //         margin: 20
    //   //     }
    //   // }
    //   });

   $('#servicetab').owlCarousel({
   loop:false,
   autoplay:true,
   autoplayTimeout:3000,
   autoplayHoverPause:true,
   dots: false,
   margin: 10,
   responsiveClass: true,
   responsive: {
       0: {
           items: 2,
           nav: true
       },
       600: {
           items: 3,
           nav: true
       },
       1000: {
           items: 5,
           nav: true,
           margin: 20
       }
   }
   });
 $('.serviceCarso').show();
      $('#offers').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: true
    },
    1000: {
        items: 3,
        nav: true,
        margin: 20
    }
}
});
$('#brands').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: true
    },
    1000: {
        items: 5,
        nav: true,
        margin: 20
    }
}
});
$('#gallery').owlCarousel({
  loop:true,
  autoplay:true,
  autoplayTimeout:3000,
  autoplayHoverPause:true,
  dots: false,
  margin: 10,
  responsiveClass: true,
  responsive: {
      0: {
          items: 1,
          nav: true
      },
      600: {
          items: 3,
          nav: true
      },
      1000: {
          items: 5,
          nav: true,
          margin: 20
      }
  }
  });

$('#banner_service_carousel').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 2,
        nav: true
    },
    600: {
        items: 3,
        nav: true
    },
    1000: {
        items: 4,
        nav: true,
        margin: 20
    }
}
});
$('#instagram_carousel').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: false
    },
    1000: {
        items: 4,
        nav: true,
        margin: 30
    }
}
});

$('#testimonials').owlCarousel({
loop:true,
autoplay:true,
autoplayTimeout:3000,
autoplayHoverPause:true,
dots: false,
margin: 10,
responsiveClass: true,
responsive: {
    0: {
        items: 1,
        nav: true
    },
    600: {
        items: 3,
        nav: false
    },
    1000: {
        items: 3,
        nav: true,
        margin: 30
    }
}
});

$('.compareDiv').show();




}, 2000);


}

  ngOnInit() {
    this.translate.get(['Home_page_data_meta_data.page_title', 'Home_page_data_meta_data.keywords','Home_page_data_meta_data.description',])
    .subscribe(translations => {
        this.pageSerice.pageInfo(this,{
      "page_title":translations['Home_page_data_meta_data.page_title'],
      "meta_data":{
          "Keywords":translations['Home_page_data_meta_data.keywords'],
          "title":translations['Home_page_data_meta_data.page_title'],
          "description":translations['Home_page_data_meta_data.description']
      }
  });
});

        this.CartService.site_info.subscribe(res=>{
       this.site_info=res;
   });
   
         localStorage.removeItem("withFitting");
        this.productsService.selected_front_tyre_width.next('');
        this.productsService.selected_front_tyre_height.next('');
        this.productsService.selected_front_tyre_rim_size.next('');

        this.productsService.selected_rear_tyre_width.next('');
        this.productsService.selected_rear_tyre_height.next('');
        this.productsService.selected_rear_tyre_rim_size.next('');

        this.productsService.selected_location.next('');
        this.productsService.selected_latt.next('');
        this.productsService.selected_lang.next('');



        this.productsService.selected_make_name.next('');
        this.productsService.selected_modal_name.next('');
          this.productsService.selected_make_id.next('');
        this.productsService.selected_year.next('');

    $('html, body').animate({
            scrollTop: $(".scrollto").offset().top
        }, 1000);

        this.productsService.selected_front_tyre_width.subscribe(selected_front_tyre_width=>{
          this.selected_front_tyre_width= selected_front_tyre_width;
        });

        this.productsService.selected_front_tyre_height.subscribe(selected_front_tyre_height=>{
        this.selected_front_tyre_height= selected_front_tyre_height;
        });

        this.productsService.selected_front_tyre_rim_size.subscribe(selected_front_tyre_rim_size=>{
          this.selected_front_tyre_rim_size= selected_front_tyre_rim_size;
        });
    // subcribe tyre dimensions
    this.translate.get('Home_page_data').subscribe((text:string) => {
      //       const  page_data={
      //     page_title :text.page_title,
      //     meta_data :{
      //       Keywords:text.keywords,
      //       description:text.description,
      //     },
      // };
       //this.pageSerice.pageInfo(this,page_data);
    });

    this.runDependent();
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });
   let  query_params={};
    let apisCallData=[
        {
        "url":"banners",
        "params":query_params
        },
        {
        "url":"homebrand",
        "params":query_params
        },
        {
        "url":"homespecialoffer",
        "params":query_params
      },
        {
        "url":"customerReviews",
        "params":query_params
      },
            {
            "url":"home_blocks",
            "params":query_params
          },
          {
            "url":"instafeeds",
            "params":query_params
          }
    ];
    this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
        this.banner_image=data[0].banner_data;
        this.brands = data[1].data;
        this.special_offers=data[2].data;
        this.customer_reviews=data[3].data;
        this.blocks=data[4].data;
        this.instas=data[5].data;
          this.setAllView();
  });

            this.CartService.car_services.subscribe(car_services=>{
            this.car_services=car_services;
            });

  }
  openCarServiceTab(id){
    $('.servicescont').fadeOut().hide();
    $('#tab_id'+id).fadeIn().show();
    $('.item').removeClass("hilight");
    $('#main_tab_id'+id).addClass("hilight");
  }
  getClass(index,val){
    if(val==1){
      if(index==0){
        return 'show_service_tab';
      }else{
          return 'hide_service_tab';
      }
    }else{
      if(index==0){
        return 'hilight';
      }
    }

  }
get_splcl_notes(notes){
    if(notes){
            var str = notes;
            var res = notes.split(",");
            return res;
    }else{
        return [];
    }

}
  callSnackBar(){
    this.pageSerice.snakeMessage('Snack bar called','success');
  }
  setmycarservice(){
    setTimeout (() => {
      $('#servicetab').owlCarousel({
      loop:true,
      autoplay:true,
      autoplayTimeout:3000,
      autoplayHoverPause:true,
      dots: false,
      margin: 10,
      responsiveClass: true,
      responsive: {
          0: {
              items: 1,
              nav: true
          },
          600: {
              items: 3,
              nav: true
          },
          1000: {
              items: 5,
              nav: true,
              margin: 20
          }
      }
      });
      $('#banner_service_carousel').owlCarousel({
      loop:true,
      autoplay:true,
      autoplayTimeout:3000,
      autoplayHoverPause:true,
      dots: false,
      margin: 10,
      responsiveClass: true,
      responsive: {
          0: {
              items: 1,
              nav: true
          },
          600: {
              items: 3,
              nav: true
          },
          1000: {
              items: 5,
              nav: true,
              margin: 20
          }
      }
      });
    $('.serviceCarso').show();
  },10000);
  }
  runDependent(){
$("#find_tyre").click(function(e){
  e.preventDefault();
  $(".banner_shop").show();
  $(".banner_text, .banner_shop_service").hide();
});
$("#find_service").click(function(e){
  e.preventDefault();
  $(".banner_shop_service").show();
  $(".banner_text, .banner_shop").hide();
});

$("#servicetab .item a").on('click', function(e) {
  e.preventDefault();
  var tabs = $(this).data('servicescont');
  $('.item').removeClass("hilight");
  $(this).parent().addClass("hilight");

  $("#tabscontent .servicescont:not('.hide')").stop().fadeOut('fast', function(e) {
    e.preventDefault();
      $(this).addClass('hide');
   $('#tabscontent .servicescont[data-tabs="'+tabs+'"]').fadeIn('fast').removeClass('hide');
  });
});

  }


}
