import { Component, OnInit,AfterViewInit,OnDestroy } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { SEOService } from '../../../common_service/seo.service';
import {PageService} from 'src/app/common_service/page.service';
import { CartService } from 'src/app/common_service/cart.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
 declare var $: any;
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit,AfterViewInit ,OnDestroy{
    faqs_cats:any=[];
    faqs:any=[];
    cat:any='';
    site_info:any;
  constructor(private CrudService:CrudService,
      private pgServ:PageService,
       private CartService:CartService,
      private router:Router,
      private route: ActivatedRoute,
	  private seoService: SEOService
  ) { }

ngOnDestroy() {
            $('.faqs-carousel').owlCarousel('destroy');
               $('#banner_service_carousel').owlCarousel('destroy'); 
            $('#mainslider').owlCarousel('destroy');
            $('#testimonials').owlCarousel('destroy');
            $('#instagram_carousel').owlCarousel('destroy');
            $('#banner_service_carousel').owlCarousel('destroy');
            $('#brands').owlCarousel('destroy');
            $('#offers').owlCarousel('destroy');
            $('#servicetab').owlCarousel('destroy');
    // cleanup logic goes here
  }
  
  onClickMe(event,index,cat_name){
        var cat=cat_name.replace(/ /g, "-");
      $('.item').removeClass("hilight");
      $('#faq_cat_div'+index).addClass("hilight");
      let  query_params={"cat":cat};
       let apisCallData=[
           {
           "url":"faqs",
           "params":query_params
         }
       ];

      this.router.navigate(
      [],
      {
      relativeTo: this.route,
      queryParams: query_params,
      queryParamsHandling: 'merge'
      });

       this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
             this.faqs=data[0].data;
     });
  }
class(index,cat_name){
    var cat=cat_name.replace(/ /g, "-");
    if(this.cat!=''){
if(cat===this.cat){
    return "hilight";
}
    } else{
      if(index==0){
        return "hilight";
      }
    }
}

openAccordian(index){
        $('.panel_body').removeClass("collapsed");
        $('.panel_body').addClass("collapse");
        $('.panel_head').removeClass("collapsed");
		 $('.panel_head').removeClass("collapse");
        $('.panel_head').addClass("collapsed");
		 $('#collapse_panel_header'+index).removeClass("collapsed");
        $('#collapse_panel_header'+index).addClass("collapse");
        $('#collapse_panel'+index).removeClass("collapse");
       
         $('#collapse_panel'+index).slideDown(1000);
		  $('#collapse_panel'+index).addClass("collapsed");
}
  ngOnInit() {

 this.CartService.site_info.subscribe(res=>{
            this.site_info=res;
            });
    this.route.queryParams.subscribe(params=>{
      if(params.hasOwnProperty('cat')){
          this.cat=params.cat;
       }
    });
const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
    let  query_params={};
     let apisCallData=[
         {
         "url":"faq_categorys",
         "params":query_params
       },
       {
       "url":"faqs",
       "params":{"cat":this.cat}
       }
     ];

     this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
         this.faqs_cats=data[0].data;
         this.setview();
          this.faqs=data[1].data;
   });
  }
setview(){
  setTimeout (() => {
    $('.faqs-carousel').owlCarousel({
           loop: false,
           dots: false,
           margin: 1,
           responsiveClass: true,
           responsive: {
               0: {
                   items: 2,
                   nav: true
               },
               600: {
                   items: 3,
                   nav: true
               },
               1000: {
                   items:5,
                   nav: true,
               }
           }
       });
       $('.compareDiv').show();
  },3000);
}
  ngAfterViewInit(){


  }

}
