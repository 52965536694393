import { Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
  import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
@Component({
  selector: 'app-allbrands',
  templateUrl: './allbrands.component.html',
  styleUrls: ['./allbrands.component.css']
})
export class AllBrandsComponent implements OnInit {
type:any;
cat_id:any;
record:any;
cat_data:any={
  "banner":""

};
  constructor(
        private authService: AuthService,
        private translate: TranslateService,
        public siteService: LanguageService,
        private pageSerice: PageService,
        private productsService: ProductsService,
        private router:Router,
        private route: ActivatedRoute,
        private title:Title,
        private meta:Meta,
        private http: Http,
        private CrudService:CrudService,
        private config: ConfigService,
		private seoService: SEOService
      ) {
         this.router.routeReuseStrategy.shouldReuseRoute = () => false;
       }

  ngOnInit() {
      this.cat_id=0;
    this.type=this.route.snapshot.params.type;
	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
    switch(this.type){
      case 'tires':
      this.cat_id=2;
      break;

      case 'battery':
      this.cat_id=3;
      break;

      case 'lubricants':
      this.cat_id=4;
      break;

      case 'garage_equipments':
      this.cat_id=39;
      break;
    }
    let apisCallData=[
        {
        "url":"brandsList",
        "params":{"cat":this.cat_id}
        },
        {
        "url":"getCategoryData",
        "params":{"cat":this.cat_id}
        }
    ];
    this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
          this.record = data[0].data;
          this.cat_data = data[1].data;
          this.pageSerice.pageInfo(this,{
            "page_title":this.cat_data.meta_title,
            "meta_data":{
                "Keywords":this.cat_data.meta_keyword,
                "title":this.cat_data.meta_title,
                "description":this.cat_data.meta_description
            }
        });
  });

  }

}
