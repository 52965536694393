import { Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  api_calling=false;
token:any;
  capctha_data:any;
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    public siteService: LanguageService,
    private pageSerice: PageService,
    private productsService: ProductsService,
    private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
    private config: ConfigService
    ) { }
    password_form = this.fb.group({
      new_password: ['', [
        Validators.required,
         RxwebValidators.compare({fieldName:'confirm_password' })
      ]],
      confirm_password: ['', [
        Validators.required,
         RxwebValidators.compare({fieldName:'new_password' })
      ]],
    });

  ngOnInit() {
  this.token=this.route.snapshot.params.token;
}

reset_password(e){

  if (e) e.preventDefault();
    this.api_calling=true;
const formData = new FormData();
formData.append('token', this.token);
formData.append('password', this.password_form.controls['new_password'].value);
this.CrudService.addByFormData(formData, 'reset_password').subscribe(res => {
this.api_calling=false;
if (res.status==true) {
      this.api_calling=false;
      this.password_form.reset();
        this.router.navigate(['login']);
  this.pageSerice.snakeMessage(res.msg,'success');
} else {
this.api_calling=false;
this.pageSerice.snakeMessage(res.msg,'Danger');
}
});
}
}
