import { Component, OnInit ,Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CrudService } from 'src/app/common_service/crud.service';
import { SEOService } from '../../../common_service/seo.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
import {PageService} from 'src/app/common_service/page.service';
declare var $: any;
@Component({
  selector: 'app-installments',
  templateUrl: './installments.component.html',
  styleUrls: ['./installments.component.css']
})
export class InstallmentsComponent implements OnInit {
         installaments:any=[];
         currency:any='';
  constructor(  private translate: TranslateService,
    public siteService: LanguageService,
    private CartService:CartService,
    public authService: AutheService,
    private fb: FormBuilder,
    private CrudService:CrudService,
    private pageSerice:PageService,
	private seoService: SEOService) { }

  ngOnInit() {
    let apisCallData=[
        {
        "url":"installments",
        "params":{}
      }
    ];
	
	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
	
    this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
         this.installaments=data[0].data;
         console.log(this.installaments);
  });
      this.siteService.currency.subscribe(res=>{
      this.currency=res;
      });
}

}
