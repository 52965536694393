
import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.css']
})
export class BreadcrumComponent  {
  @Input() Pagedata: any;
  constructor() { }
  
  
}