import { Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
  import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
declare var google: any;
@Component({
  selector: 'app-fittinglocations',
  templateUrl: './fittinglocations.component.html',
  styleUrls: ['./fittinglocations.component.css']
})
export class FittinglocationsComponent implements OnInit ,AfterViewInit{
    facilities:any=[];
    services:any=[];
    providers:any=[];
      weeks:any=[];
     markers:any=[];
      sitelang = '';
        banner_data:any={
        "fld_advertisement_image":""
    };
  constructor(
       public pgServ:PageService,
        private authService: AuthService,
        private translate: TranslateService,
        public siteService: LanguageService,
        public productsService: ProductsService,
        private router:Router,
        private route: ActivatedRoute,
        private title:Title,
        private meta:Meta,
        private http: Http,
        private CrudService:CrudService,
        private config: ConfigService,
		private seoService: SEOService
      ) {
 this.siteService.selectedLang.subscribe(res=>{
         if(res == 'ar'){
           this.sitelang='ar';
         } else{
           this.sitelang='';
         }

     });
   }
selecletd_service(string,id){
  var res = string.split(",");
  var a='disable'
  res.forEach(function (item) {
    if(item!=''){
      if(item == id){
          a= 'enable';
      }
    }
       });
       return a;
}
link(map_data){
  var url=`https://maps.google.com/?q=${map_data.latt},${map_data.longt}`;
  return url
}
makeGoogleMaplink(inputElement){
  this.translate.get(['fittinglocation.locationCopied'])
  .subscribe(translations => {
      this.pgServ.snakeMessage(translations['fittinglocation.locationCopied'],'Green');
      inputElement.select();  
      document.execCommand('copy');  
    inputElement.setSelectionRange(0, 0);
    });

}
 open_close_modal_for_map(open_modal_id,close_modal_id,map_data){
           if(close_modal_id!=0){
             $('#installer_map_page').modal('hide');
         }
         if(open_modal_id!=0){
              var dt= "<h2> "+map_data.comapny+"</h2> <p>"+map_data.address+"</p>";

  var mapOptions = {
          center: new google.maps.LatLng(map_data.latt, map_data.longt),
          zoom: 15,
          mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      var map = new google.maps.Map(document.getElementById("map"), mapOptions);

      //Create and open InfoWindow.
      var infoWindow = new google.maps.InfoWindow();

   
          var data = map_data
          var myLatlng = new google.maps.LatLng(data.lat, data.lng);
          var marker = new google.maps.Marker({
              position: myLatlng,
              map: map,
              title: data.title,
              draggable:false,
              icon:data.icon
          });

          var markercenter = new google.maps.Marker({
              position: new google.maps.LatLng(map_data.latt, map_data.longt),
              map: map,
              title: map_data.title,
              draggable:false,
              icon:map_data.fld_logo_image
          });

          //Attach click event to the marker.
          (function (marker, data) {
              google.maps.event.addListener(marker, "mouseover", function (e) {
                  infoWindow.setContent("<div class='mapCardClass'>" + data.description + "</div>");
                  infoWindow.open(map, marker);
              });
                google.maps.event.addListener(marker, 'click', function() {
               
                });
          })(marker, data);

          infoWindow.setContent("<div class='mapCardClass'>" +dt + "</div>");
          infoWindow.open(map, markercenter);

      
             $('#installer_map_page').modal('show');
         }

       }
zoomToParticularLocation(providers){
       
  var dt= "<h2> "+providers.comapny+"</h2> <p>"+providers.address+"</p>";
  var n=1;
  for (var key in this.providers) {
        var obj = {
        "title": ' ',
        "lat": this.providers[key].latt,
        "lng": this.providers[key].longt,
        "icon":this.providers[key].fld_logo_image,
        "description": "<h2>"+this.providers[key].comapny+",</h2> <p>"+this.providers[key].address+"</p>"
    };
      n++;
    this.markers.push(obj);
  }
  var mapOptions = {
          center: new google.maps.LatLng(providers.latt, providers.longt),
          zoom: 15,
          mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      var map = new google.maps.Map(document.getElementById("dvMap2"), mapOptions);

      //Create and open InfoWindow.
      var infoWindow = new google.maps.InfoWindow();

      for (var i = 0; i < this.markers.length; i++) {
          var data = this.markers[i];
          var myLatlng = new google.maps.LatLng(data.lat, data.lng);
          var marker = new google.maps.Marker({
              position: myLatlng,
              map: map,
              title: data.title,
              draggable:false,
              icon:data.icon
          });

          var markercenter = new google.maps.Marker({
              position: new google.maps.LatLng(providers.latt, providers.longt),
              map: map,
              title: providers.title,
              draggable:false,
              icon:providers.fld_logo_image
          });

          //Attach click event to the marker.
          (function (marker, data) {
              google.maps.event.addListener(marker, "mouseover", function (e) {
                  infoWindow.setContent("<div class='mapCardClass'>" + data.description + "</div>");
                  infoWindow.open(map, marker);
              });
                google.maps.event.addListener(marker, 'click', function() {
            
                });
          })(marker, data);

          infoWindow.setContent("<div class='mapCardClass'>" +dt + "</div>");
          infoWindow.open(map, markercenter);

      }
      $('.compareDiv').show();
      $('html, body').animate({scrollTop:350},'10');
}
ngAfterViewInit(){

}
   map_view(){

     var n=1;
     for (var key in this.providers) {
           var obj = {
           "title": ' ',
           "lat": this.providers[key].latt,
           "lng": this.providers[key].longt,
           "icon":this.providers[key].fld_logo_image,
           "description": "<h2>"+this.providers[key].comapny+",</h2> <p>"+this.providers[key].address+"</p>"
       };
         n++;
       this.markers.push(obj);
     }
     var mapOptions = {
             center: new google.maps.LatLng(this.markers[0].lat, this.markers[0].lng),
             zoom: 12,
             mapTypeId: google.maps.MapTypeId.ROADMAP
         };
         var map = new google.maps.Map(document.getElementById("dvMap"), mapOptions);

         //Create and open InfoWindow.
         var infoWindow = new google.maps.InfoWindow();

         for (var i = 0; i < this.markers.length; i++) {
             var data = this.markers[i];
             var myLatlng = new google.maps.LatLng(data.lat, data.lng);
             var marker = new google.maps.Marker({
                 position: myLatlng,
                 map: map,
                 title: data.title,
                 draggable:false,
                 icon:data.icon
             });

             //Attach click event to the marker.
             (function (marker, data) {
                 google.maps.event.addListener(marker, "mouseover", function (e) {
                     infoWindow.setContent("<div class='mapCardClass'>" + data.description + "</div>");
                     infoWindow.open(map, marker);
                 });
             })(marker, data);



         }
         $('.compareDiv').show();
   }
  ngOnInit() {
      
        this.translate.get(['fitting_page_data_meta_data.page_title', 'fitting_page_data_meta_data.keywords','fitting_page_data_meta_data.description',])
        .subscribe(translations => {
            this.pgServ.pageInfo(this,{
          "page_title":translations['fitting_page_data_meta_data.page_title'],
          "meta_data":{
              "Keywords":translations['fitting_page_data_meta_data.keywords'],
              "description":translations['fitting_page_data_meta_data.description']
          }
      });
    });
	
	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute); 
      
    let  query_params={};
     let apisCallData=[
         {
         "url":"service_facilities",
         "params":query_params
         },
         {
         "url":"fittling_location_installer",
         "params":query_params
         },
          {
         "url":"advertisement",
         "params":{"ads_id":27}
         }
     ];
     this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
        if(data[0].status==true){
        this.services=data[0].data.services;
        this.facilities=data[0].data.facilties;
        this.weeks=data[0].data.week_days;
        }
       
        this.providers=data[1].data.data;
        this.banner_data=data[2].data;
              this.map_view();
   });
  }

}
