import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/common_service/cart.service';
@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {
  site_info:any;
  constructor(private CartService:CartService) { }

  ngOnInit() {
    this.CartService.site_info.subscribe(res=>{
      this.site_info=res;
      });
  }

}
