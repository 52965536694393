import { Component, OnInit ,AfterViewInit } from '@angular/core';

import { CrudService } from 'src/app/common_service/crud.service';
import { SEOService } from '../../../common_service/seo.service';

import { AuthService } from "angular-6-social-login";

import { Router } from '@angular/router';

import {ActivatedRoute} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {PageService} from '../../../common_service/page.service';

import { ProductsService } from 'src/app/common_service/products.service';

import {Title , Meta} from '@angular/platform-browser';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RxwebValidators } from '@rxweb/reactive-form-validators';

import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";

import { SocialUser } from "angular-6-social-login";

import {LanguageService} from 'src/app/common_service/language.service';

 import { ConfigService } from '../../../common_service/ConfigService.service';

 import { Http, Headers } from '@angular/http';

import { Observable, forkJoin } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

 declare var $: any;



@Component({

  selector: 'app-feedback',

  templateUrl: './feedback.component.html',

  styleUrls: ['./feedback.component.css']

})

export class FeedbackComponent implements OnInit {

  api_calling=false;

  providers:any=[];
  capctha_data:any;
  blocks:any=[];

  constructor(

    private authService: AuthService,

    private translate: TranslateService,

    public siteService: LanguageService,

    private pageSerice: PageService,

    private productsService: ProductsService,

    private fb: FormBuilder,

    private router:Router,

    private route: ActivatedRoute,

    private title:Title,

    private meta:Meta,

    private http: Http,

    private CrudService:CrudService,
	private seoService: SEOService,
    private config: ConfigService

    ) { }

    feedbackForm = this.fb.group({
      first_name: ['', [
      Validators.required
      ]],
      email: ['', [
      RxwebValidators.email()
      ]],
      mobile: ['', [
      Validators.required,
      Validators.pattern("^[0-9_-]{8}"),
      RxwebValidators.digit()
      ]],
      rating: ['', [
        Validators.required
      ]],
      from_where: ['', [
        Validators.required
      ]],
      comments: ['', [
        Validators.required
      ]],
      retail_location: ['', [
        Validators.required
      ]]
    });



  ngOnInit() {

    this.CrudService.handleMutipleCallAPI([
      {
      "url":"fittling_location_installer",
      "params":{}
      }
      ]).subscribe(data => {
      this.providers=data[0].data.data;
      });

	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);


}
feedbackcall(e){
  if (e) e.preventDefault();
      if(!this.feedbackForm.valid){
      this.feedbackForm.markAsTouched();
      return ;
      }
        this.api_calling=true;

    const formData = new FormData();
    formData.append('first_name', this.feedbackForm.controls['first_name'].value);
    formData.append('email', this.feedbackForm.controls['email'].value);
    formData.append('retail_location', this.feedbackForm.controls['retail_location'].value);
    formData.append('rating', this.feedbackForm.controls['rating'].value);
    formData.append('from_where', this.feedbackForm.controls['from_where'].value);
    formData.append('mobile', this.feedbackForm.controls['mobile'].value);
    formData.append('comments', this.feedbackForm.controls['comments'].value);

    this.CrudService.addByFormData(formData, 'feedback').subscribe(res => {
      this.api_calling=false;
      if (res.status==true) {
        this.api_calling=false;
        this.feedbackForm.reset();
        this.pageSerice.snakeMessage(res.msg,'success');
      } else {
          this.api_calling=false;
          this.pageSerice.snakeMessage(res.msg,'Danger');
      }
    });
}

}

