import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { Ng5SliderModule } from 'ng5-slider';
import {CookieService} from 'ngx-cookie-service';

//com[ponentss]

import { DashsidebarComponent } from 'src/app/modules/frontend/dashsidebar/dashsidebar.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ProductCompareComponent } from 'src/app/modules/frontend/product-compare/product-compare.component';
import { AddressComponent } from 'src/app/modules/frontend/address/address.component';
import {CheckoutComponent} from 'src/app/modules/frontend/checkout/checkout.component';
import {MyorderComponent} from 'src/app/modules/frontend/myorder/myorder.component';
import {ChangepasswordComponent} from 'src/app/modules/frontend/changepassword/changepassword.component';
import {InstallmentsComponent} from 'src/app/modules/frontend/installments/installments.component';
import {InstallationComponent} from 'src/app/modules/frontend/installation/installation.component';
import {ForgetComponent} from 'src/app/modules/frontend/forget/forget.component';
import { PagesComponent } from 'src/app/modules/frontend/pages/pages.component';
import { AboutComponent } from 'src/app/modules/frontend/about/about.component';
import { BatteryComponent } from 'src/app/modules/frontend/battery/battery.component';
import {FaqsComponent} from 'src/app/modules/frontend/faqs/faqs.component';
import {ThankuComponent} from 'src/app/modules/frontend/thanku/thanku.component';
import { ReviewComponent } from 'src/app/modules/frontend/review/review.component';
import { FittinglocationsComponent } from 'src/app/modules/frontend/fittinglocations/fittinglocations.component';
import { BrandsComponent } from 'src/app/modules/frontend/brands/brands.component';
import { AppoinmentsComponent } from 'src/app/modules/frontend/appoinments/appoinments.component';
import { EnquiryComponent } from 'src/app/modules/frontend/enquiry/enquiry.component';
import { OffersComponent } from 'src/app/modules/frontend/offers/offers.component';
import { CartComponent } from 'src/app/modules/frontend/cart/cart.component';
import { TrackOrderComponent } from 'src/app/modules/frontend/trackorder/trackorder.component';
import { ServicesComponent } from 'src/app/modules/frontend/services/services.component';
import { TetimonialsComponent } from 'src/app/modules/frontend/tetimonials/tetimonials.component';
import { AllBrandsComponent } from 'src/app/modules/frontend/allbrands/allbrands.component';
import { OrderDetailsComponent } from 'src/app/modules/frontend/orderdetails/orderdetails.component';
import { MobileVanServiceComponent } from 'src/app/modules/frontend/mobilevanservice/mobilevanservice.component';
import { CancelOrderComponent } from 'src/app/modules/frontend/cancelorder/cancelorder.component';
import { ReturnOrderComponent } from 'src/app/modules/frontend/returnorder/returnorder.component';
import { ResetComponent } from 'src/app/modules/frontend/reset/reset.component';
 import { VerifyComponent } from 'src/app/modules/frontend/verify/verify.component';
 import { LoyalityComponent } from 'src/app/modules/frontend/loyality/loyality.component';
 import { LoyalityStepComponent } from 'src/app/modules/frontend/loyalityStep/loyalityStep.component';
 import { CpnDetailsComponent } from 'src/app/modules/frontend/cpndetails/cpndetails.component';
  import { OfferDetailsComponent } from 'src/app/modules/frontend/offerdetails/offerdetails.component';
  import { PostComponent } from 'src/app/modules/frontend/post/post.component'
import { ProductComponent } from 'src/app/modules/frontend/product/product.component';
import { FheaderComponent } from 'src/app/modules/frontend/fheader/fheader.component';
import { FooterComponent } from 'src/app/modules/frontend/footer/footer.component'
import { ProductsComponent } from 'src/app/modules/frontend/products/products.component';
import { NavComponent } from 'src/app/modules/frontend/nav/nav.component';
import { HomeComponent } from 'src/app/modules/frontend/home/home.component'
import { ContactsComponent } from 'src/app/modules/frontend/contacts/contacts.component';
import{ LoginComponent } from 'src/app/modules/frontend/login/login.component';
import {DashboardComponent} from 'src/app/modules/frontend/dashboard/dashboard.component';
import {DashboardSideComponent} from 'src/app/modules/frontend/dashboard/dashboard.component';
import { TyreFiltersComponent } from 'src/app/modules/frontend/tyrefilters/tyrefilters.component';
import { HomecarouselComponent } from 'src/app/modules/frontend/homecarousel/homecarousel.component';
import { MultipurposesliderComponent } from 'src/app/modules/frontend/multipurposeslider/multipurposeslider.component';
import { CarservicesComponent } from 'src/app/modules/frontend/carservices/carservices.component';
import { BreadcrumComponent } from 'src/app/modules/frontend/breadcrum/breadcrum.component';
import {BrandDetailsComponent} from 'src/app/modules/frontend/branddetails/branddetails.component';


import {CarYearComponent} from 'src/app/modules/frontend/caryear/caryear.component';
import {CarModalComponent} from 'src/app/modules/frontend/carmodal/carmodal.component';
import {CarMakeComponent} from 'src/app/modules/frontend/carmake/carmake.component';
import {CarShoopComponent} from 'src/app/modules/frontend/carshoop/carshoop.component';
import {OtherProductsComponent} from 'src/app/modules/frontend/otherproducts/otherproducts.component';
import { InstallerReviewComponent } from './modules/frontend/installerreview/installerreview.component';
import {NoMatchComponent} from 'src/app/modules/frontend/nomatch/nomatch.component';
import {QueryFormComponent} from 'src/app/modules/frontend/queryform/queryform.component';
import {ReachUsComponent } from './modules/frontend/reachus/reachus.component';
import {SummerCampaignComponent} from 'src/app/modules/frontend/summer-campaign/summer-campaign.component';
import {ScratchCardComponent} from 'src/app/modules/frontend/scratchcard/scratchcard.component';
import {ScratchCardFormComponent} from 'src/app/modules/frontend/scratchcardform/scratchcardform.component';
import {ScratchCardOtpComponent} from 'src/app/modules/frontend/scratchcardotp/scratchcardotp.component';

import {MobileVanTermComponent} from 'src/app/modules/frontend/mobilevanterm/mobilevanterm.component';
import {FeedbackComponent} from 'src/app/modules/frontend/feedback/feedback.component';
import {ScratchCardMobileVanComponent} from 'src/app/modules/frontend/scratchcardmobilevan/scratchcardmobilevan.component';
import {ScratchCardFormMobileVanComponent} from 'src/app/modules/frontend/scratchcardformmobilevan/scratchcardformmobilevan.component';
import {ScratchCardOtpMobileVanComponent} from 'src/app/modules/frontend/scratchcardotpmobilevan/scratchcardotpmobilevan.component';
import { HealthCheckupComponent } from './modules/frontend/health-checkup/health-checkup.component';
//com[ponentss]

  
import {ReactiveFormsModule} from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//service
import {PageService} from 'src/app/common_service/page.service';
import {ConfigService} from 'src/app/common_service/ConfigService.service';
import {CartService} from 'src/app/common_service/cart.service';
import { ValidateService } from 'src/app/common_service/validate.service';
import { ProductsService } from 'src/app/common_service/products.service';
import { AutheService } from 'src/app/common_service/authe.service';
import { CrudService } from './common_service/crud.service';
//service

import {routes} from './app-routing.module';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { HttpModule } from '@angular/http';
import {NgxPaginationModule} from 'ngx-pagination';


import { FlashMessagesModule } from 'angular2-flash-messages';
import { AuthGuard } from './guards/auth.guard';
import {ModalModule} from 'ngx-bootstrap/modal';


// import { SocialLoginModule, AuthServiceConfig } from "angular-6-social-login";
// import { GoogleLoginProvider, FacebookLoginProvider } from "angular-6-social-login";
import { GoogleLoginProvider, FacebookLoginProvider, AuthService } from 'angular-6-social-login';  
import { SocialLoginModule, AuthServiceConfig } from 'angular-6-social-login';  

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MaterialModule} from './material.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AllshowroomsComponent } from './modules/frontend/allshowrooms/allshowrooms.component';
import { ShowroomGalleryComponent } from './modules/frontend/showroom-gallery/showroom-gallery.component';
import { DaiyaComponent } from './modules/frontend/daiya/daiya.component';
import { RiggaeComponent } from './modules/frontend/riggae/riggae.component';
import { FaihaComponent } from './modules/frontend/faiha/faiha.component';
import { JahraComponent } from './modules/frontend/jahra/jahra.component';
import { AhmadiComponent } from './modules/frontend/ahmadi/ahmadi.component';
import { RaiComponent } from './modules/frontend/rai/rai.component';
import { CanadadryComponent } from './modules/frontend/canadadry/canadadry.component';
import { SpeedmatetabComponent } from './modules/frontend/speedmatetab/speedmatetab.component';

import { CalluspageComponent } from './modules/frontend/calluspage/calluspage.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {prefix: "./assets/translate/about/", suffix: ".json"},
    {prefix: "./assets/translate/header/", suffix: ".json"},
    {prefix: "./assets/translate/home/", suffix: ".json"},
    {prefix: "./assets/translate/contact/", suffix: ".json"},
    {prefix: "./assets/translate/footer/", suffix: ".json"},
    {prefix: "./assets/translate/login_reg_forget_change_pass/", suffix: ".json"},
    {prefix: "./assets/translate/Other_module/", suffix: ".json"},
    {prefix: "./assets/translate/other_providers/", suffix: ".json"},
    {prefix: "./assets/translate/title_meta_tags/", suffix: ".json"}
  ]);
}

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("611085157473-enpol9i7eegorohj4mqga0m92dk5do2e.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("544152483309335")
  },
]);

export function getAuthServiceConfigs() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    MobileVanTermComponent,
    ScratchCardMobileVanComponent,
    ScratchCardFormMobileVanComponent,
    ScratchCardOtpMobileVanComponent,
    FeedbackComponent,
    ScratchCardComponent,
    ScratchCardFormComponent,
    ScratchCardOtpComponent,
    NoMatchComponent,
    QueryFormComponent,
    ReachUsComponent,
    SummerCampaignComponent,
    InstallerReviewComponent,
    OtherProductsComponent,
    CarYearComponent,
    CarModalComponent,
    CarMakeComponent,
    CarShoopComponent,
    CpnDetailsComponent,
    OfferDetailsComponent,
    LoyalityComponent,
    LoyalityStepComponent,
    TyreFiltersComponent,
    PostComponent,
    FittinglocationsComponent,
    BatteryComponent,
    ProductComponent,
    FheaderComponent,
    FooterComponent,
    ProductsComponent,
    BreadcrumComponent,
    NavComponent,
    HomeComponent,
    ContactsComponent,
    HomecarouselComponent,
    CarservicesComponent,
    ServicesComponent,
    LoginComponent,
    ResetComponent,
    CheckoutComponent,
    DashboardComponent,
    DashboardSideComponent,
    DashsidebarComponent,
    AddressComponent,
    MyorderComponent,
    ChangepasswordComponent,
    InstallmentsComponent,
    InstallationComponent,
    AllBrandsComponent,
    MobileVanServiceComponent,
    OrderDetailsComponent,
    CancelOrderComponent,
    ReturnOrderComponent,
    ForgetComponent,
    PagesComponent,
    AboutComponent,
    FaqsComponent,
    ThankuComponent,
    ReviewComponent,
    BatteryComponent,
    FittinglocationsComponent,
    MultipurposesliderComponent,
    TrackOrderComponent,
    DashboardComponent,
    LoginComponent,
    AddressComponent,
    ProductCompareComponent,
    CheckoutComponent,
    BrandsComponent,
    BrandDetailsComponent,
    AppoinmentsComponent,
    TetimonialsComponent,
    EnquiryComponent,
    OffersComponent,
    MyorderComponent,
    ChangepasswordComponent,
    CartComponent,
    InstallmentsComponent,
    InstallationComponent,
    ForgetComponent,
    FittinglocationsComponent,
    FaqsComponent,
    ThankuComponent,
    ReviewComponent,
    BatteryComponent,
     VerifyComponent,
     HealthCheckupComponent,
     AllshowroomsComponent,
     ShowroomGalleryComponent,
     DaiyaComponent,
     RiggaeComponent,
     FaihaComponent,
     JahraComponent,
     AhmadiComponent,
     RaiComponent,
     CanadadryComponent,
     SpeedmatetabComponent,
     CalluspageComponent
  ],
  imports: [
        NgxSkeletonLoaderModule.forRoot(),
        Ng5SliderModule,
        GooglePlaceModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        FormsModule,
        HttpModule,
        MatSnackBarModule,
        MaterialModule,
        NgxPaginationModule,
        //DeviceDetectorModule,
        NgxMaterialTimepickerModule,
        RouterModule.forRoot(routes),
        FlashMessagesModule.forRoot(),
        CarouselModule.forRoot(),
        ModalModule.forRoot(),
        SocialLoginModule,
        BrowserAnimationsModule,
        HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [PageService,ValidateService,AuthService, AutheService, AuthGuard,ConfigService,CrudService,CartService,ProductsService,CookieService,
  {  
    provide: AuthServiceConfig,  
    useFactory: getAuthServiceConfigs  
  }  
],
   exports:[FormsModule],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
