import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-canadadry',
  templateUrl: './canadadry.component.html',
  styleUrls: ['./canadadry.component.css']
})
export class CanadadryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
