import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/common_service/products.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {LanguageService} from 'src/app/common_service/language.service';
declare var $: any;
@Component({
  selector: 'app-tyrefilters',
  templateUrl: './tyrefilters.component.html',
  styleUrls: ['./tyrefilters.component.css']
})
export class TyreFiltersComponent implements OnInit {
  selected_front_tyre_width= '';
  selected_front_tyre_height= '';
  selected_front_tyre_rim_size= '';

  selected_rear_tyre_width= '';
  selected_rear_tyre_height= '';
  selected_rear_tyre_rim_size= '';
selected_location='';
  selected_make_name= '';
  selected_modal_name= '';
  selected_year= '';
  selected_fitting=false;
  selected_make_id= '';
  selected_modal_id= '';
    car_services=[];
    sitelang='';
page='';
  constructor(
      public productsService: ProductsService,
      private router:Router,
      private route: ActivatedRoute,
        public siteService: LanguageService,
  ) {
    this.siteService.page.subscribe(res=>{
    this.page=res;

    });
  }

  ngOnInit() {
    this.productsService.selected_fitting.subscribe(selected_fitting=>{
      this.selected_fitting= selected_fitting;
    });

         this.siteService.selectedLang.subscribe(res=>{
         if(res == 'ar'){
           this.sitelang='ar';
         } else{
           this.sitelang='';
         }

     });
    $(document).ready(function(){
          $("#different_rear_tyre").click(function(){
            $(".different_reartab").delay(100).slideToggle();
          });
        });
        //new
        $(document).ready(function(){
          $("#different_rear_tyre2").click(function(){
            $(".different_reartab2").delay(100).slideToggle();
          });
        });
        this.productsService.car_services.subscribe(car_services=>{
          this.car_services= car_services;
        });

    // subcribe tyre dimensions

    this.productsService.selected_location.subscribe(selected_location=>{
      this.selected_location= selected_location;
    });

        this.productsService.selected_front_tyre_width.subscribe(selected_front_tyre_width=>{
          this.selected_front_tyre_width= selected_front_tyre_width;
        });

        this.productsService.selected_front_tyre_height.subscribe(selected_front_tyre_height=>{
        this.selected_front_tyre_height= selected_front_tyre_height;
        });

        this.productsService.selected_front_tyre_rim_size.subscribe(selected_front_tyre_rim_size=>{
          this.selected_front_tyre_rim_size= selected_front_tyre_rim_size;
        });


        this.productsService.selected_rear_tyre_width.subscribe(selected_rear_tyre_width=>{
          this.selected_rear_tyre_width= selected_rear_tyre_width;
        });

        this.productsService.selected_rear_tyre_height.subscribe(selected_rear_tyre_height=>{
        this.selected_rear_tyre_height= selected_rear_tyre_height;
        });

        this.productsService.selected_rear_tyre_rim_size.subscribe(selected_rear_tyre_rim_size=>{
          this.selected_rear_tyre_rim_size= selected_rear_tyre_rim_size;
        });

        this.productsService.selected_make_name.subscribe(selected_make_name=>{
          this.selected_make_name= selected_make_name;
        });


        this.productsService.selected_modal_name.subscribe(selected_modal_name=>{
          this.selected_modal_name= selected_modal_name;
        });

        this.productsService.selected_make_id.subscribe(selected_make_id=>{
        this.selected_make_id= selected_make_id;
        });

        this.productsService.selected_modal_id.subscribe(selected_modal_id=>{
          this.selected_modal_id= selected_modal_id;
        });
        this.productsService.selected_year.subscribe(selected_year=>{
          this.selected_year= selected_year;
        });
    // subcribe tyre dimensions

  }
  open_close_modal(open_modal_id,close_modal_id){
    if(close_modal_id!=0){
        $('#myModalprocess'+close_modal_id).modal('hide');
    }
    if(open_modal_id!=0){
      switch(open_modal_id){

        case 1:
              this.productsService.selected_front_tyre_width.next('');
              this.productsService.selected_front_tyre_height.next('');
              this.productsService.selected_front_tyre_rim_size.next('');
        break;

        case 2 :
                  this.productsService.selected_front_tyre_height.next('');
                  this.productsService.selected_front_tyre_rim_size.next('');
        break;

        case 3:
                        this.productsService.selected_front_tyre_rim_size.next('');
        break;

        case 5:
                this.productsService.selected_rear_tyre_width.next('');
                this.productsService.selected_rear_tyre_height.next('');
                this.productsService.selected_rear_tyre_rim_size.next('');
        break;

        case 6:
  this.productsService.selected_rear_tyre_height.next('');
  this.productsService.selected_rear_tyre_rim_size.next('');

        break;

        case 7:

            this.productsService.selected_rear_tyre_rim_size.next('');

        break;

        case 70:
            this.productsService.selected_make_name.next('');
            this.productsService.selected_make_id.next('');
            this.productsService.selected_modal_id.next('');
            this.productsService.selected_modal_name.next('');
            this.productsService.selected_year.next('');
        break;

        case 80:
              this.productsService.selected_modal_id.next('');
              this.productsService.selected_modal_name.next('');
              this.productsService.selected_year.next('');

        break;

        case 90:
            this.productsService.selected_year.next('');
        break;
      }


    this.productsService.getTyreConfiguration(open_modal_id,0);
        $('#myModalprocess'+open_modal_id).modal('show');
    }

  }
  search_tyre_make_modal(){
      $(".modal").modal('hide');
  if( this.selected_location=='' || this.selected_location=='null' ||  this.selected_location==null){
    this.open_close_modal(8,0);
      return false;
  }

     let  params={};
     if(this.selected_make_name!=''){
         let querystring = { "make_name" :this.selected_make_name.replace(/\s+/g, '-')};
         $.extend(params,querystring);
    }
    if(this.selected_modal_name!=''){
        let querystring = { "modal_name" :this.selected_modal_name.replace(/\s+/g, '-')};
        $.extend(params,querystring);
   }
   if(this.selected_make_id!=''){
       let querystring = { "make_id" :this.selected_make_id};
       $.extend(params,querystring);
  }
  if(this.selected_modal_id!=''){
      let querystring = { "modal_id" :this.selected_modal_id};
      $.extend(params,querystring);
  }

   if(this.selected_year!=''){
       let querystring = { "year" :this.selected_year};
       $.extend(params,querystring);
  }
  this.router.navigate(['products/tyre'], { queryParams: params });
  this.productsService.filter_applied.next('Yes');


  }
  tyreSearcher(type){
            $(".mytab").removeClass("active");
            $("#tyreFilter_"+type).addClass("active");
            $(".tab-pane").hide();
            $("#banner_form"+type).show();
  }

  search_tyre_on_filter(filter_type){
        // if( this.selected_location=='' || this.selected_location=='null' ||  this.selected_location==null){
        //   this.open_close_modal(4,0);
        //   return false;
        // }

  $(".modal").modal('hide');
  if(filter_type==0){

    let  query_params={};
        $.extend(query_params,{"page":1});
      if(this.selected_front_tyre_width!=''){
        $.extend(query_params,{"f_width":this.selected_front_tyre_width});
      }
      if(this.selected_front_tyre_height!=''){
      $.extend(query_params,{"f_height":this.selected_front_tyre_height});
      }
      if(this.selected_front_tyre_rim_size!=''){
      $.extend(query_params,{"f_rim":this.selected_front_tyre_rim_size});
  }

  if(this.selected_rear_tyre_width!=''){
      let querystring = { "r_width" :this.selected_rear_tyre_width};
      $.extend(query_params,querystring);
 }
 if(this.selected_rear_tyre_height!=''){
     let querystring = { "r_height" :this.selected_rear_tyre_height};
     $.extend(query_params,querystring);
 }
 if(this.selected_rear_tyre_rim_size!=''){
    let querystring = { "r_rim" :this.selected_rear_tyre_rim_size};
    $.extend(query_params,querystring);
 }
 this.router.navigate(['products/tyre'], { queryParams: query_params });
 this.productsService.filter_applied.next('Yes');

  }

  }
}
