import { Component, OnInit ,Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/common_service/products.service';
import {ActivatedRoute} from '@angular/router';
import {Title , Meta} from '@angular/platform-browser';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CrudService } from 'src/app/common_service/crud.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
import {PageService} from 'src/app/common_service/page.service';
import { SEOService } from '../../../common_service/seo.service';
declare var $: any;
@Component({
  selector: 'app-carservices',
  templateUrl: './carservices.component.html',
  styleUrls: ['./carservices.component.css']
})
export class CarservicesComponent implements OnInit {
  services:any=[];
  name:any;
  cat_id:any;
  cat_data:any={
    "banner":""
  };
  constructor(  private translate: TranslateService,
    public siteService: LanguageService,
    private CartService:CartService,
    public authService: AutheService,
    private fb: FormBuilder,
    private router:Router,
    private title:Title,
    private meta:Meta,
      public productsService: ProductsService,
        private route: ActivatedRoute,
    private CrudService:CrudService,
    private pageSerice:PageService,
	private seoService: SEOService) { this.router.routeReuseStrategy.shouldReuseRoute = () => false;}

  ngOnInit() {
    this.translate.get(['car_suv_tires_data_meta_data.page_title', 'car_suv_tires_data_meta_data.keywords','car_suv_tires_data_meta_data.description',])
    .subscribe(translations => {
        this.pageSerice.pageInfo(this,{
      "page_title":translations['car_suv_tires_data_meta_data.page_title'],
      "meta_data":{
          "Keywords":translations['car_suv_tires_data_meta_data.keywords'],
          "title":translations['car_suv_tires_data_meta_data.page_title'],
          "description":translations['car_suv_tires_data_meta_data.description']
      }
  });
});

const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
	
    this.cat_id=0;
  this.name=this.route.snapshot.params.name;
  switch(this.name){
    case 'tyre-service':
    this.cat_id=1;
    break;

    case 'car-service':
    this.cat_id=2;
    break;
    break;
  }
     let apisCallData=[
       {
       "url":"service_facilities",
       "params":{"cat_id":2}
     },
     {
     "url":"getCategoryData",
     "params":{"cat":this.cat_id}
     }
     ];

     this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
            this.services=data[0].data.services;
            this.cat_data = data[1].data;
   });
  }

}
