import { Component, OnInit ,AfterViewInit,OnDestroy } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { CartService } from 'src/app/common_service/cart.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
@Component({
  selector: 'app-carmodal',
  templateUrl: './carmodal.component.html',
  styleUrls: ['./carmodal.component.css']
})
export class CarModalComponent implements OnInit {
        site_info:any;
        make:any='';
        make_data=[];
         modal_data=[];
        api_calling=false;
        currency:any;
    constructor(
    private authService: AuthService,
    private translate: TranslateService,
      public siteService: LanguageService,
    private pageSerice: PageService,
     private CartService:CartService,
    public productsService: ProductsService,
      private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
     private config: ConfigService) { 
         
 
         
     }
    returnUrl(data){
         var name= data.name;
          name= name.toLowerCase();
        return  (name.replace(/\s+/g, '-'))
    }
  ngOnInit() {
    

                this.productsService.selected_front_tyre_width.next('');
                this.productsService.selected_front_tyre_height.next('');
                this.productsService.selected_front_tyre_rim_size.next('');
                
                this.productsService.selected_rear_tyre_width.next('');
                this.productsService.selected_rear_tyre_height.next('');
                this.productsService.selected_rear_tyre_rim_size.next('');
                
                this.productsService.selected_location.next('');
                this.productsService.selected_latt.next('');
                this.productsService.selected_lang.next('');
                
                
                
                this.productsService.selected_make_name.next('');
                this.productsService.selected_modal_name.next('');
                this.productsService.selected_make_id.next('');
                this.productsService.selected_year.next('');
       this.make=this.route.snapshot.params.make;
    let apisCallData=[
            {
            "url":"car_make2",
            "params":{"make_name":this.make}
            },
             {
            "url":"car_modal",
            "params":{"make":this.make}
            }
    ];
        this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
                this.make_data=data[0].data;
                this.modal_data=data[1].data;

                var page_title='name Tyres';
    var meta_title = "name Tyres Prices - Buy name Tires Online & Fitted Locally | Albabtain Auto";
    var meta_description = "Buy Tires for name models Online and install them from authorized installers anywhere in Kuwait.";
    var name=this.make.replaceAll('-', ' ');
    var New_page_title = page_title.replace("name",name);
var New_meta_title = meta_title.replace("name",name);
var New_meta_description = meta_description.replace("name",name);

    this.pageSerice.pageInfo(this,{
      "page_title":New_page_title.toUpperCase(),
      "meta_data":{
          "Keywords":'',
          "title":New_meta_title,
          "description":New_meta_description
      }
  });
               
        });
  }
    
  traking(e){

  }

}
