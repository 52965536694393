import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rai',
  templateUrl: './rai.component.html',
  styleUrls: ['./rai.component.css']
})
export class RaiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
