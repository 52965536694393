import { Component, OnInit, ViewChild, ElementRef,AfterViewInit,OnDestroy} from '@angular/core';
import {PageService} from 'src/app/common_service/page.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { CrudService } from 'src/app/common_service/crud.service';
import { SEOService } from '../../../common_service/seo.service';
import { CartService } from 'src/app/common_service/cart.service';
import { LanguageService } from 'src/app/common_service/language.service';
import {CookieService} from 'ngx-cookie-service';
import { ProductsService } from 'src/app/common_service/products.service';
import { GooglePlaceDirective } from "node_modules/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
declare var $: any;
declare var google: any;
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-installation',
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.css']
})
export class InstallationComponent implements OnInit,AfterViewInit,OnDestroy {

  
      @ViewChild('placesRef', {static: true }) placesRef: GooglePlaceDirective;
      @ViewChild('customer_location', {static: true }) customer_location: GooglePlaceDirective;
      total_product_in_cart:any=[];
      days_slot:any=[];
      providers:any=[];
       door_to_door:any=[];
       facilities:any=[];
    services:any=[];
      weeks:any=[];
      
      fitting_chrages:any=5;
      currency:any='';
      sitelang = '';
      format_address:any;
      customer_address:any;
      lat:any;
      long:any;
      radius:any;
  public shoppingCartItems$: Observable<any[]>;
  obsevableResponseArray: Array<any> = [];
      constructor(
        public pgServ:PageService,
        private router:Router,
        private route: ActivatedRoute,
        private CrudService:CrudService,
        private CartService:CartService,
        private LanguageService:LanguageService,
        private CookieService:CookieService,
        public productsService: ProductsService,
		private seoService: SEOService
      ) {

      }
      
      open_close_modal_for_map(open_modal_id,close_modal_id,map_data){
           if(close_modal_id!=0){
             $('#installer_map_page').modal('hide');
         }
         if(open_modal_id!=0){
              var dt= "<h2> "+map_data.comapny+"</h2> <p>"+map_data.address+"</p>";

  var mapOptions = {
          center: new google.maps.LatLng(map_data.latt, map_data.longt),
          zoom: 15,
          mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      var map = new google.maps.Map(document.getElementById("map"), mapOptions);

      //Create and open InfoWindow.
      var infoWindow = new google.maps.InfoWindow();

   
          var data = map_data
          var myLatlng = new google.maps.LatLng(data.lat, data.lng);
          var marker = new google.maps.Marker({
              position: myLatlng,
              map: map,
              title: data.title,
              draggable:false,
              icon:data.icon
          });

          var markercenter = new google.maps.Marker({
              position: new google.maps.LatLng(map_data.latt, map_data.longt),
              map: map,
              title: map_data.title,
              draggable:false,
              icon:map_data.fld_logo_image
          });

          //Attach click event to the marker.
          (function (marker, data) {
              google.maps.event.addListener(marker, "mouseover", function (e) {
                  infoWindow.setContent("<div class='mapCardClass'>" + data.description + "</div>");
                  infoWindow.open(map, marker);
              });
                google.maps.event.addListener(marker, 'click', function() {
                console.log(marker);
                });
          })(marker, data);

          infoWindow.setContent("<div class='mapCardClass'>" +dt + "</div>");
          infoWindow.open(map, markercenter);

      
             $('#installer_map_page').modal('show');
         }

       }
       
      zoomToParticularLocation(providers){
            var name=providers.comapny.replace(/\s+/g, '-');
        var link="https://www.google.com/maps/place/"+name+"/@"+providers.latt+","+providers.longt+"";
        window.open(link, "_blank");
        return false;
      }
      selected_bookingSlot(e){
          console.log(e);
      }
      selecletd_service(string,id){
  var res = string.split(",");
  var a='disable'
  res.forEach(function (item) {
    if(item!=''){
      if(item == id){
          a= 'enable';
      }
    }
       });
       return a;
}
       ngOnDestroy() {
            $('.installer_slide').owlCarousel('destroy'); 
    // cleanup logic goes here
  }
  
  setViewcaro(){
	   setTimeout(function(){
          
          $('.installer_slide').owlCarousel({
          loop: false,
          dots: false,
          responsiveClass: true,
          responsive: {
          0: {
              items: 2,
              nav: true
          },
          600: {
              items: 4,
              nav: true
          },
          1000: {
              items: 4,
              nav: true
          }
          }
          }); }, 7000);
		  
  }
      ngAfterViewInit(){
       
         
      }
  getproviderInlocation(params){
     let apisCallData=[
         {
         "url":"find_fittling_installer_location_wise",
         "params":params
         }
     ];
     this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
        this.providers=data[0].data;
         //this.setViewcaro();
   });
  }
  getPlacevalue(place){
    if(this.format_address){
      return this.format_address.replace('-','');
    }

  }
  ditance_int(distance){
    return parseInt(distance);
  }
search(radius){
  if(this.format_address){
      let params={};
            $.extend(params,{
               "location" :this.format_address,
               "latt":this.lat,
               "long":this.long,
               "radius":radius
             }
             );
      this.router.navigate(
      [],
      {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge'
      });
      this.getproviderInlocation(params);
  } else{
     this.pgServ.snakeMessage('Select Location','Danger');
  }

}
public handleAddressChange(address: any) {
var lat=address.geometry.location.lat();
var lang=address.geometry.location.lng();
var format_address=address.formatted_address;
var address=address.address_components;

var len=address.length;
var area_full=(len > 0) ? address[0].long_name:"";
var area_short=(len > 0) ? address[0].short_name:"";

var city_full=  (len > 1) ? address[1].long_name:"";
var city_short= (len > 1) ? address[1].short_name:"";

var   state_full=(len > 2) ? address[2].long_name:"";
var   state_short=(len > 2) ? address[2].short_name:"";

var   country_full=(len > 3) ? address[3].long_name:"";
var   country_short=(len > 3) ? address[3].short_name:"";

this.format_address=format_address.replace(/\s+/g, '-');
this.lat=lat;
this.long=lang;


    // this.productsService.selected_location.next(format_address);
    // this.productsService.selected_latt.next(lat);
    // this.productsService.selected_lang.next(lat);
}
      public selectAddress(address: any) {
        var format_address=address.formatted_address;
        this.customer_address=format_address;
     }
	 
	 public makeid(length) {
        var today = new Date();
        var date = today.getFullYear()+':'+(today.getMonth()+1)+':'+today.getDate();
        var time = today.getHours() +":"+ today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+time;
        var randomString =Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)+dateTime;


   var result           = '';
   var characters       = '!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'+dateTime;
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

get_data(close_day,am_booking,pm_booking){
  var days_slot=[];
   var  maskweekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
   if(this.sitelang == 'ar'){
            var weekday = ['شمس', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'خميس', 'الجمعة', 'جلس'];
         } else{
              var  weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
         
         }
for(let j=0;j<=10;j++){
    var ms = new Date().getTime() + (86400000*j);
    var tomorrow = new Date(ms);
    let day2 = maskweekday[tomorrow.getDay()];
    var month = tomorrow.getMonth()+1;
    var day1 = tomorrow.getDate();
    var res2 =close_day.split(",");
    var i2 = res2.indexOf(day2);
    var whole_day_off=0;
      if(i2<0){
        var whole_day_off=1;
      }
    days_slot.push({
          'day':weekday[tomorrow.getDay()],
          'date': day1 +'-'+month,
		  'id':this.makeid(10),
          'whole_day_off': whole_day_off,
          'am_booking_off':  am_booking,
          'pm_booking_off': pm_booking,
          'booking_date':  tomorrow
      });
}

  return  days_slot;
}
open_time_slot(installer_id){
     $('._time_slot').hide();
    $('#time_slot_'+installer_id).slideDown(500).show();
  }
  close_time_slot(installer_id){
     $('._time_slot').hide();
  }
confirmBooking(bookingInstaller){
    var name="booking_slot";
    var date=$("[name="+name+"]:checked").attr("date");
    var value = $("[name="+name+"]:checked").val();

if(value && this.customer_address){
     var time_slot = value.substring(0, 2);
    var install_data={
        "installer_id":bookingInstaller,
        "booking_data":date,
        "installer_type":0,
        "time_slot":time_slot,
        "customer_select_address":this.customer_address,
        "company_name":'Door to Door serices',
    };
  var myJSON = JSON.stringify(install_data);
    this.CookieService.set('installer',myJSON,0.1);
    let dt=this.CookieService.get('installer');
    let backJson = JSON.parse(dt);
  this.router.navigate(['checkout']);
} else{
  this.pgServ.snakeMessage('Please select installation slot','Danger');
}
}
checkAddress(){
  if(this.customer_address){
    return true;
  }else{
      this.pgServ.snakeMessage('Please Enter an Address','Danger');
      return false;
  }
  
}
bookingSlot(bookingInstaller){
    var name="booking_slot";
    var date=$("[name="+name+"]:checked").attr("date");
    var value = $("[name="+name+"]:checked").val();

if(value){
     var time_slot = value.substring(0, 2);
    var install_data={
        "installer_id":bookingInstaller.fld_brand_id,
        "booking_data":date,
        "installer_type":1,
        "time_slot":time_slot,
        "customer_select_address":bookingInstaller.address,
        "company_name":bookingInstaller.comapny,
    };
  var myJSON = JSON.stringify(install_data);
    this.CookieService.set('installer',myJSON,0.1);
    let dt=this.CookieService.get('installer');
    let backJson = JSON.parse(dt);
      this.router.navigate(['checkout']);
} else{
  this.pgServ.snakeMessage('Please select a installation slot','Danger');
}
}
  ngOnInit() {
      
        let  query_params={};
     let apisCallData=[
         {
         "url":"service_facilities",
         "params":query_params
         },
         {
          "url":"fittling_door_todoor_location_installer",
          "params":query_params
          }
     ];
	 const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
     this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
        if(data[0].status==true){
            this.services=data[0].data.services;
            this.facilities=data[0].data.facilties;
            this.weeks=data[0].data.week_days;
        }
        this.door_to_door=data[1].data;
   });
   
      this.LanguageService.selectedLang.subscribe(res=>{
        this.sitelang=res;
     });

        this.CartService.itemsInCartSubject.subscribe(
        cart_products => {
        this.total_product_in_cart=cart_products;
        });
              this.LanguageService.currency.subscribe(res=>{
              this.currency=res;
              });
        this.route.queryParams.subscribe(params=>{
                if(params.hasOwnProperty('location')){
                  this.format_address=params.location;
                }
                if(params.hasOwnProperty('latt')){
                  this.lat=params.latt;
                }
                if(params.hasOwnProperty('long')){
                  this.long=params.long;
                }
                if(params.hasOwnProperty('radius')){
                  this.radius=params.radius;
                }
                if(this.format_address && this.lat && this.long && this.radius){
                  let params={};
                  $.extend(params,{
                     "location" :this.format_address,
                     "latt":this.lat,
                     "long":this.long,
                     "radius":this.radius
                   }
                   );
                     this.getproviderInlocation(params);
                }
        });

  }
  get sum()
{
  let sum=0;
  this.total_product_in_cart.forEach((p,index)=>{
    sum+=p.cart_qty*p.mrp_price
  });
  return sum;
}
  remove_product_from_cart(product:any){
    
        this.CartService.remove_from_cart(product);
  }

}
