import { Component, OnInit ,Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CrudService } from 'src/app/common_service/crud.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
import {PageService} from 'src/app/common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-myorder',
  templateUrl: './myorder.component.html',
  styleUrls: ['./myorder.component.css']
})
export class MyorderComponent implements OnInit {
sitelang = '';
currency:any='';
list_type:any;
order_list:any=[];
constructor(  private translate: TranslateService,
  public siteService: LanguageService,
  private CartService:CartService,
  public authService: AutheService,
  private fb: FormBuilder,
    private router:Router,
      private route: ActivatedRoute,
        public productsService: ProductsService,
  private CrudService:CrudService,
  private pageSerice:PageService) {
     this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.siteService.currency.subscribe(res=>{
    this.currency=res;
    });
    this.siteService.selectedLang.subscribe(res=>{
      if(res == 'ar'){
              this.sitelang='ar';
      } else{
        this.sitelang='';
      }
  });}

onClickMe(index){
}
getPage(page: number) {

}

  ngOnInit() {
    this.route.params.subscribe(params=>{

      if(params.hasOwnProperty('list_type')){
          this.list_type=params.list_type;
          if(this.list_type==''){
            this.router.navigate(['home']);
          }
       } else{
          this.router.navigate(['home']);
       }
    });
    var list=0;
    switch(this.list_type){
      case 'upcomming':
      break;

      case 'past':
      var list=3;
      break;

      case 'cancelled':
      var list=4;
      break;

      case 'returned':
      var list=5;
      break;
    }
    let  query_params={
      "fld_user_id":this.authService.userdata().fld_user_id,
      "list_type":list
       };
     this.CrudService.add(query_params,'order_list').subscribe(data => {
           if(data.status==true){
            this.order_list=data.data.orders;
           
           }

   });

  }

}
