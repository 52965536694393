import { Component, OnInit, ViewChild, ElementRef,AfterViewInit} from '@angular/core';
import { Options } from 'ng5-slider';
import {PageService} from 'src/app/common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { CartService } from 'src/app/common_service/cart.service';
import { CrudService } from 'src/app/common_service/crud.service';
import { LanguageService } from 'src/app/common_service/language.service';
declare var $: any;
@Component({
  selector: 'app-cpndetails',
  templateUrl: './cpndetails.component.html',
  styleUrls: ['./cpndetails.component.css']
})
export class CpnDetailsComponent implements OnInit {
    product_id:any;
    data:any;
    currency:any='';
    
  constructor(private pgServ:PageService,
  private router:Router,
  private route: ActivatedRoute,
  private CartService:CartService,
  private LanguageService:LanguageService,
  private CrudService:CrudService,
  public productsService: ProductsService,) { }

  ngOnInit() {
    this.product_id=this.route.snapshot.params.id;
    this.CrudService.list({"id":this.product_id},'coupon').subscribe(data => {
        console.log(data);
      if(data.status==true){
           this.data = data.data;
      }
      });
  }

}
