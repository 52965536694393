
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';
import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CrudService } from 'src/app/common_service/crud.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
import {PageService} from 'src/app/common_service/page.service';
declare var $: any;

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  profile_data:any;
  api_calling=false;
    constructor(  private translate: TranslateService,
      public siteService: LanguageService,
      private CartService:CartService,
      public authService: AutheService,
      private fb: FormBuilder,
      private CrudService:CrudService,
      private pageSerice:PageService) { }
      password_form = this.fb.group({
        current_password: ['', [
          Validators.required
          
        ]],
        new_password: ['', [
          Validators.required,
           RxwebValidators.compare({fieldName:'confirm_password' })
        ]],
        confirm_password: ['', [
          Validators.required,
          RxwebValidators.compare({fieldName:'new_password' })
        ]],
      });
    ngOnInit() {

    }
    update_password(e){
      if (e) e.preventDefault();
     
      if(!this.password_form.valid){
              this.password_form.markAsTouched();
            return ;
        }
        this.api_calling=true;
      let  query_params={
          "fld_user_id":this.authService.userdata().fld_user_id,
          "fld_current_password":this.password_form.controls['current_password'].value,
          "fld_new_password":this.password_form.controls['new_password'].value,
    };
    this.CrudService.add(query_params,'changePassword').subscribe(data => {
          this.api_calling=false;
          if (data.status==true) {
            this.pageSerice.snakeMessage(data.msg,'success');
          } else {
            this.pageSerice.snakeMessage(data.msg,'Danger');
          }

  });

    }
}
