import { Component, OnInit ,AfterViewInit } from '@angular/core';

import { CrudService } from 'src/app/common_service/crud.service';
import { SEOService } from '../../../common_service/seo.service';

import { AuthService } from "angular-6-social-login";

import { Router } from '@angular/router';

import {ActivatedRoute} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {PageService} from '../../../common_service/page.service';

import { ProductsService } from 'src/app/common_service/products.service';

import {Title , Meta} from '@angular/platform-browser';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { RxwebValidators } from '@rxweb/reactive-form-validators';

import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";

import { SocialUser } from "angular-6-social-login";

import {LanguageService} from 'src/app/common_service/language.service';

 import { ConfigService } from '../../../common_service/ConfigService.service';

 import { Http, Headers } from '@angular/http';

import { Observable, forkJoin } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

 declare var $: any;

@Component({
  selector: 'app-health-checkup',
  templateUrl: './health-checkup.component.html',
  styleUrls: ['./health-checkup.component.css']
})
export class HealthCheckupComponent implements OnInit {

  api_calling=false;

  providers:any=[];
  capctha_data:any;
  blocks:any=[];
  currentlang: any;
  constructor(

    private authService: AuthService,

    private translate: TranslateService,

    public siteService: LanguageService,

    private pageSerice: PageService,

    private productsService: ProductsService,

    private fb: FormBuilder,

    private router:Router,

    private route: ActivatedRoute,

    private title:Title,

    private meta:Meta,

    private http: Http,

    private CrudService:CrudService,
	private seoService: SEOService,
    private config: ConfigService

    ) { 
      this.siteService.selectedLang.subscribe(res=>{
        this.currentlang=res;
      });
    }

    feedbackForm = this.fb.group({
      first_name: ['', [
      Validators.required
      ]],
      email: ['', [
      RxwebValidators.email()
      ]],
      mobile: ['', [
      Validators.required,
      Validators.pattern("^[0-9_-]{8}"),
      RxwebValidators.digit()
      ]],
      enquiry: ['', [
       // Validators.required
      ]],
      modal: [null, [//Validators.required
      ]],
      size: [null, [//Validators.required
      ]],
      image: [null, [
            
      ]],
      retail_location: [null, [Validators.required
            
      ]],
      date: [null, [Validators.required
            
      ]],
      time: [null, [Validators.required
            
      ]],
    });


    date_selected(val){
      this.feedbackForm.get("date").setValue(val);
   }
  ngOnInit() {
     $('.datetimepicker').datetimepicker({
      format: 'YYYY-MM-DD'
    }).on('changeDate', function(ev) {
      // $('.datetimepicker').datetimepicker('hide');
      });

    const script = document.createElement('script');
  script.innerHTML = ` window.dataLayer = window.dataLayer || [];
  function gtag() {
      dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'UA-180908633-1');`;
  document.head.appendChild(script);

    this.feedbackForm.get('enquiry').valueChanges
    .subscribe(value => {
          if(value=='battery' || value=='tire' ) {
            $('.grpClass').show();
          // this.feedbackForm.get('myEmailField').setValidators(this.emailValidators.concat(Validators.required))
          // this.feedbackForm.get('image').setValidators(Validators.required)
          this.feedbackForm.get('modal').setValidators(Validators.required)
          this.feedbackForm.get('size').setValidators(Validators.required)
          } else {
            $('.grpClass').hide();
            // this.feedbackForm.get('image').clearValidators();
            this.feedbackForm.get('modal').clearValidators();
            this.feedbackForm.get('size').clearValidators();
          }
      this.feedbackForm.get('image').updateValueAndValidity();
      this.feedbackForm.get('modal').updateValueAndValidity();
      this.feedbackForm.get('size').updateValueAndValidity();
    }
    );

	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);


}

setImageAsNull(){
  this.feedbackForm.patchValue({
    image: null
    });
    this.feedbackForm.get('image').updateValueAndValidity()
}
      getImage(event) {
        this.setImageAsNull();
      if (event.target.files.length === 0)
       return;

       const file = (event.target as HTMLInputElement).files[0];
       if(file.type=="image/jpeg" || file.type=="image/jpg" || file.type=="image/png"){
        this.feedbackForm.patchValue({
          image: file
          });
          this.feedbackForm.get('image').updateValueAndValidity()
       }else{
          this.setImageAsNull();
       }
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
      }
      reader.readAsDataURL(file)
      }
feedbackcall(e){
  if (e) e.preventDefault();
        if(!this.feedbackForm.valid){
      this.feedbackForm.markAsTouched();
      return ;
      }
     
        this.api_calling=true;

    const formData = new FormData();
        formData.append('first_name', this.feedbackForm.controls['first_name'].value);
        formData.append('email', this.feedbackForm.controls['email'].value);
        formData.append('mobile', this.feedbackForm.controls['mobile'].value);
        formData.append('enquiry', this.feedbackForm.controls['enquiry'].value);
        formData.append('modal', this.feedbackForm.controls['modal'].value);
        formData.append('size', this.feedbackForm.controls['size'].value);
        formData.append('image', this.feedbackForm.controls['image'].value);
        formData.append('date_time', this.feedbackForm.controls['date'].value);
        formData.append('lead_time', this.feedbackForm.controls['time'].value);
        formData.append('retail_location', this.feedbackForm.controls['retail_location'].value);

    this.CrudService.addByFormData(formData, 'free-health-checkup-form').subscribe(res => {
      this.api_calling=false;
      if (res.status==true) {
            this.feedbackForm.patchValue({
            image: null
            });
            this.feedbackForm.get('image').updateValueAndValidity()

          this.api_calling=false;
          this.feedbackForm.reset();
        this.pageSerice.snakeMessage(res.msg,'success');
      } else {
          this.api_calling=false;
          this.pageSerice.snakeMessage(res.msg,'Danger');
      }
    });
}

}
