
import { Component, OnInit,ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {PageService} from 'src/app/common_service/page.service';
import { CrudService } from 'src/app/common_service/crud.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { ProductsService } from 'src/app/common_service/products.service';
import { CartService } from 'src/app/common_service/cart.service';
import { ConfigService } from 'src/app/common_service/ConfigService.service';
//import { DeviceDetectorService } from 'ngx-device-detector';
import { GooglePlaceDirective } from "node_modules/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    @ViewChild('placesRef', {static: true }) placesRef: GooglePlaceDirective;
    faqs_cats:any=[];
    crm_pages:any=[];
    tyre_services:any=[];
    car_services:any=[];
    coupon_message:any;
    location_msg="";
    site_info:any;
    coupon:any;
    api_calling=false;
    cpn_api_calling=false;
    sitelang='';
    currentlang = 'en';

    selected_location= '';
    selected_latt= '';
    selected_lang= '';

    selected_front_tyre_width= '';
    selected_front_tyre_height= '';
    selected_front_tyre_rim_size= '';

    selected_rear_tyre_width= '';
    selected_rear_tyre_height= '';
    selected_rear_tyre_rim_size= '';

    selected_make_name= '';
    selected_modal_name= '';
    selected_year= '';

    selected_make_id= '';
    selected_modal_id= '';
    selected_fitting=false;
    filter_datas=[
    ];
  constructor(
    private translate: TranslateService,
    private config: ConfigService,
    private CookieService:CookieService,
    private router:Router,
   // private deviceService: DeviceDetectorService,
    private pageSerice: PageService,
    private CartService:CartService,
    public siteService: LanguageService,
    private route: ActivatedRoute,
    public productsService: ProductsService,
    private fb: FormBuilder,
    private crud: CrudService) {

this.CartService.cpn_data.subscribe(cpn_data_res=>{
this.coupon=cpn_data_res;
});
            this.CartService.site_info.subscribe(res=>{
            this.site_info=res;
            });

        this.CartService.tyre_services.subscribe(tyre_services=>{
        this.tyre_services=tyre_services;
        });

        this.CartService.car_services.subscribe(car_services=>{
        this.car_services=car_services;
        });


        this.CartService.faq_categorys.subscribe(faq_categorys=>{
        this.faqs_cats=faq_categorys;
        });

this.CartService.crm_page_urls.subscribe(crm_page_urls=>{
this.crm_pages=crm_page_urls;
});


      this.siteService.selectedLang.subscribe(res=>{
        this.currentlang=res;
        this.sitelang=res;
    });
     }
    subcriptionForm = this.fb.group({
      email: ['', [
        Validators.required,
        RxwebValidators.email()
      ]]
    });

    couponFrom = this.fb.group({
      cpn: ['', [
        Validators.required,
      ]]
    });

hidemodal(){
      $('#tyrepopup').modal('hide');
}
    appyCpn(e){
      if (e) e.preventDefault();
        this.cpn_api_calling=true;
              let randomString='NAN';
      if (localStorage.getItem('customer_cart')) {
          randomString=localStorage.getItem('customer_cart');
      }
      let  query_params={
          "coupon_code":this.couponFrom.controls['cpn'].value,
          "user_cookie":randomString
            };
    this.crud.add(query_params,'applyCoupon').subscribe(data => {
               this.cpn_api_calling=false;
          if (data.Error==0) {
            setTimeout(function(){
                   $('#apply-discount').modal('hide');
            }, 1000);
                this.CartService.couponApplied.next(data);
                this.CookieService.set('couponCode',this.couponFrom.controls['cpn'].value,365);
                this.coupon_message=data.Msg;
          } else {
              this.coupon_message=data.Msg;
          }

  });

    }
    public handleAddressChange(address: any) {

    var lat=address.geometry.location.lat();
    var lang=address.geometry.location.lng();
    var format_address=address.formatted_address;
    var address=address.address_components;

    var len=address.length;
    var area_full=(len > 0) ? address[0].long_name:"";
    var area_short=(len > 0) ? address[0].short_name:"";

    var city_full=  (len > 1) ? address[1].long_name:"";
    var city_short= (len > 1) ? address[1].short_name:"";

    var   state_full=(len > 2) ? address[2].long_name:"";
    var   state_short=(len > 2) ? address[2].short_name:"";

    var   country_full=(len > 3) ? address[3].long_name:"";
    var   country_short=(len > 3) ? address[3].short_name:"";

        localStorage.setItem('selected_location', format_address);
        localStorage.setItem('selected_latt', lat);
        localStorage.setItem('selected_lang', lang);

      this.productsService.selected_location.next(format_address);
      this.productsService.selected_latt.next(lat);
      this.productsService.selected_lang.next(lang);
   }
  ngOnInit() {
   

      this.productsService.selected_fitting.subscribe(selected_fitting=>{
        this.selected_fitting= selected_fitting;
      });

    this.productsService.selected_location.subscribe(selected_location=>{
      this.selected_location= selected_location;
    });

    this.productsService.selected_latt.subscribe(selected_latt=>{
    this.selected_latt= selected_latt;
    });

    this.productsService.selected_lang.subscribe(selected_lang=>{
      this.selected_lang= selected_lang;
    });

    this.productsService.selected_front_tyre_width.subscribe(selected_front_tyre_width=>{
      this.selected_front_tyre_width= selected_front_tyre_width;
    });

    this.productsService.selected_front_tyre_height.subscribe(selected_front_tyre_height=>{
    this.selected_front_tyre_height= selected_front_tyre_height;
    });

    this.productsService.selected_front_tyre_rim_size.subscribe(selected_front_tyre_rim_size=>{
      this.selected_front_tyre_rim_size= selected_front_tyre_rim_size;
    });

    this.productsService.selected_rear_tyre_width.subscribe(selected_rear_tyre_width=>{
      this.selected_rear_tyre_width= selected_rear_tyre_width;
    });

    this.productsService.selected_rear_tyre_height.subscribe(selected_rear_tyre_height=>{
    this.selected_rear_tyre_height= selected_rear_tyre_height;
    });

    this.productsService.selected_rear_tyre_rim_size.subscribe(selected_rear_tyre_rim_size=>{
      this.selected_rear_tyre_rim_size= selected_rear_tyre_rim_size;
    });

    this.productsService.selected_make_name.subscribe(selected_make_name=>{
      this.selected_make_name= selected_make_name;
    });


    this.productsService.selected_modal_name.subscribe(selected_modal_name=>{
      this.selected_modal_name= selected_modal_name;
    });

    this.productsService.selected_make_id.subscribe(selected_make_id=>{
    this.selected_make_id= selected_make_id;
    });

    this.productsService.selected_modal_id.subscribe(selected_modal_id=>{
      this.selected_modal_id= selected_modal_id;
    });
    this.productsService.selected_year.subscribe(selected_year=>{
      this.selected_year= selected_year;
    });

    this.productsService.filter_data.subscribe(filter_data=>{
        this.filter_datas=filter_data;
    });
  }

  subscribe(){
    this.api_calling=true;
    const formData = new FormData();
    formData.append('email', this.subcriptionForm.controls['email'].value);
    this.crud.addByFormData(formData, 'subcription').subscribe(res => {
      this.api_calling=false;
      if (res.status==true) {
        this.subcriptionForm.reset();
        this.pageSerice.snakeMessage(res.msg,'success');
      } else {
        this.pageSerice.snakeMessage(res.msg,'Danger');
      }
    });
  }
  open_close_modal(open_modal_id,close_modal_id){
    if(close_modal_id!=0){
        $('#myModalprocess'+close_modal_id).modal('hide');
    }
    if(open_modal_id!=0){
      switch(open_modal_id){

        case 1:
              this.productsService.selected_front_tyre_width.next('');
              this.productsService.selected_front_tyre_height.next('');
              this.productsService.selected_front_tyre_rim_size.next('');
        break;

        case 2 :
                  this.productsService.selected_front_tyre_height.next('');
                  this.productsService.selected_front_tyre_rim_size.next('');
        break;

        case 3:
                        this.productsService.selected_front_tyre_rim_size.next('');
        break;

        case 5:
                this.productsService.selected_rear_tyre_width.next('');
                this.productsService.selected_rear_tyre_height.next('');
                this.productsService.selected_rear_tyre_rim_size.next('');
        break;

        case 6:
  this.productsService.selected_rear_tyre_height.next('');
  this.productsService.selected_rear_tyre_rim_size.next('');

        break;

        case 7:

            this.productsService.selected_rear_tyre_rim_size.next('');

        break;

        case 70:
        
            this.productsService.selected_make_name.next('');
            this.productsService.selected_make_id.next('');
            this.productsService.selected_modal_id.next('');
            this.productsService.selected_modal_name.next('');
            this.productsService.selected_year.next('');
        break;

        case 80:

              this.productsService.selected_modal_id.next('');
              this.productsService.selected_modal_name.next('');
              this.productsService.selected_year.next('');

        break;

        case 90:
            this.productsService.selected_year.next('');
        break;
      }


    this.productsService.getTyreConfiguration(open_modal_id,0);
        $('#myModalprocess'+open_modal_id).modal('show');
    }

  }

  select_make(data){
    this.productsService.selected_make_name.next(data.name);
     this.productsService.selected_make_id.next(data.id);
    this.open_close_modal(80,70);

  }
  select_modal(data){
    this.productsService.selected_modal_name.next(data.name);
   this.productsService.selected_modal_id.next(data.id);
    this.open_close_modal(90,80);

  }
  select_year(data){
    this.productsService.selected_year.next(data);
    this.open_close_modal(100,90);
  }

  select_width(type,width){
    if(type==0){
      this.productsService.selected_front_tyre_width.next(width);
      this.open_close_modal(2,1);
    }

  }
  select_height(type,height){
    if(type==0){
      this.productsService.selected_front_tyre_height.next(height);
      this.open_close_modal(3,2);
    }

  }
  select_rim_size(type,rim_size){
    if(type==0){
      this.productsService.selected_front_tyre_rim_size.next(rim_size);
      this.open_close_modal(4,3);
    }

  }

  select_r_width(type,width){
    if(type==0){
      this.productsService.selected_rear_tyre_width.next(width);
      this.open_close_modal(6,5);
    }

  }
  select_r_height(type,height){
    if(type==0){
      this.productsService.selected_rear_tyre_height.next(height);
      this.open_close_modal(7,6);
    }

  }
  select_r_rim_size(type,rim_size){
    if(type==0){
      this.productsService.selected_rear_tyre_rim_size.next(rim_size);
      this.open_close_modal(8,7);
    }

  }
  getPlacevalue(place){
    if(this.selected_location){
      return this.selected_location.replace('-','');
    }
  }
  search_tyre_make_modal(){
    // if( this.selected_location=='' || this.selected_location=='null' ||  this.selected_location==null){
    //   this.location_msg=this.translate.instant('page_error.enterlocation');
    //   return false;
    //  }
      $(".modal").modal('hide');
     this.productsService.filter_make_modal_applied.next('Yes');

     let  params={};
     if(this.selected_make_name!=''){
         let querystring = { "make_name" :this.selected_make_name.replace(/\s+/g, '-')};
         $.extend(params,querystring);
    }
    if(this.selected_modal_name!=''){
        let querystring = { "modal_name" :this.selected_modal_name.replace(/\s+/g, '-')};
        $.extend(params,querystring);
   }
   if(this.selected_make_id!=''){
       let querystring = { "make_id" :this.selected_make_id};
       $.extend(params,querystring);
  }
  if(this.selected_modal_id!=''){
      let querystring = { "modal_id" :this.selected_modal_id};
      $.extend(params,querystring);
 }

   if(this.selected_year!=''){
       let querystring = { "year" :this.selected_year};
       $.extend(params,querystring);
  }
  params=this.location_params(params);
  this.router.navigate(['/bar/'+this.selected_make_name,{mode: 'test'}]);
 this.router.navigate(['tyres/car-suv-tires/'+this.selected_make_id+'/'+this.selected_modal_id+'/'+this.selected_year]);
  //this.productsService.filter_applied.next('Yes');

  }
  location_params(params){
    if(this.selected_location!=''){
        let querystring = { "location" :this.selected_location.replace(/\s+/g, '-')};
        $.extend(params,querystring);
   }
   if(this.selected_latt!=''){
       let querystring = { "latt" :this.selected_latt};
       $.extend(params,querystring);
  }
  if(this.selected_lang!=''){
      let querystring = { "long" :this.selected_lang};
      $.extend(params,querystring);
 }
 return params;
  }
  search_tyre_on_filter(filter_type){

           $(".modal").modal('hide');
  if(filter_type==0){


    let  query_params={};
        $.extend(query_params,{"page":1});
      if(this.selected_front_tyre_width!=''){
        $.extend(query_params,{"f_width":this.selected_front_tyre_width});
      }
      if(this.selected_front_tyre_height!=''){
      $.extend(query_params,{"f_height":this.selected_front_tyre_height});
      }
      if(this.selected_front_tyre_rim_size!=''){
      $.extend(query_params,{"f_rim":this.selected_front_tyre_rim_size});
      }
      if(this.selected_rear_tyre_width!=''){
          let querystring = { "r_width" :this.selected_rear_tyre_width};
          $.extend(query_params,querystring);
     }

     if(this.selected_rear_tyre_height!=''){
         let querystring = { "r_height" :this.selected_rear_tyre_height};
         $.extend(query_params,querystring);
     }

     if(this.selected_rear_tyre_rim_size!=''){
        let querystring = { "r_rim" :this.selected_rear_tyre_rim_size};
        $.extend(query_params,querystring);
     }
     query_params=this.location_params(query_params);
     this.router.navigate(['products/tyre'], { queryParams: query_params });
     this.productsService.filter_applied.next('Yes');

  }
      }


  
}
