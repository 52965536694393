import { Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CartService } from 'src/app/common_service/cart.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
  import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
 declare var google: any;
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit,AfterViewInit {
	    faqs_cats:any=[];
  api_calling=false;
    site_info:any;
    lattitude:any='29.308481';
    longtitude:any='47.931104';
  providers:any=[];
    markers:any=[];
    banner_data:any={
        "fld_advertisement_image":""
    };
  capctha_data:any={
    "img":""
  };
  constructor(
      private authService: AuthService,
      private translate: TranslateService,
      public siteService: LanguageService,
        private CartService:CartService,
      private pageSerice: PageService,
      private productsService: ProductsService,
      private fb: FormBuilder,
      private router:Router,
      private route: ActivatedRoute,
      private title:Title,
      private meta:Meta,
      private http: Http,
      private CrudService:CrudService,
      private config: ConfigService,
	  private seoService: SEOService
   ) {
     this.CartService.site_info.subscribe(res=>{
         this.site_info=res;
     });
   }
   contact_form = this.fb.group({
     email: ['', [
       Validators.required,
       RxwebValidators.email()
     ]],
     name: ['', [
       Validators.required
     ]],
     mobile: ['', [
       Validators.required,
       Validators.pattern("^[0-9_-]{8}"),
       RxwebValidators.digit()
     ]],
     message: ['', [
       Validators.required
     ]],
     captcha: ['', [
       Validators.required
     ]]
   });

zoomToParticularLocation(providers){
    var dt= "<h2> "+providers.comapny+"</h2> <p>"+providers.address+"</p>";
  var n=1;
  for (var key in this.providers) {
        var obj = {
        "title": ' ',
        "lat": this.providers[key].latt,
        "lng": this.providers[key].longt,
        "icon":this.providers[key].fld_logo_image,
        "description": "<h2>"+this.providers[key].comapny+",</h2> <p>"+this.providers[key].address+"</p>"
    };
      n++;
    this.markers.push(obj);
  }
  var mapOptions = {
          center: new google.maps.LatLng(providers.latt, providers.longt),
          zoom: 15,
          mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      var map = new google.maps.Map(document.getElementById("dvMap"), mapOptions);

      //Create and open InfoWindow.
      var infoWindow = new google.maps.InfoWindow();

      for (var i = 0; i < this.markers.length; i++) {
          var data = this.markers[i];
          var myLatlng = new google.maps.LatLng(data.lat, data.lng);
          var marker = new google.maps.Marker({
              position: myLatlng,
              map: map,
              title: data.title,
              draggable:false,
              icon:data.icon
          });
          var markercenter = new google.maps.Marker({
              position: new google.maps.LatLng(providers.latt, providers.longt),
              map: map,
              title: providers.title,
              draggable:false,
              icon:providers.fld_logo_image
          });

          //Attach click event to the marker.
          (function (marker, data) {
              google.maps.event.addListener(marker, "mouseover", function (e) {
                  infoWindow.setContent("<div class='mapCardClass'>" + data.description + "</div>");
                  infoWindow.open(map, marker);
              });
          })(marker, data);

          infoWindow.setContent("<div class='mapCardClass'>" +dt + "</div>");
          infoWindow.open(map, markercenter);

      }
      $('.compareDiv').show();
}
   ngAfterViewInit(){
      
    
    
 
   }

  ngOnInit() {
     
     this.translate.get(['contact_page_data_meta_data.page_title', 'contact_page_data_meta_data.keywords','contact_page_data_meta_data.description',])
        .subscribe(translations => {
            this.pageSerice.pageInfo(this,{
          "page_title":translations['contact_page_data_meta_data.page_title'],
          "meta_data":{
              "Keywords":translations['contact_page_data_meta_data.keywords'],
              "title":translations['contact_page_data_meta_data.page_title'],
              "description":translations['contact_page_data_meta_data.description']
          }
      });
    });
	
	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute); 
    
    let  query_params={};
     let apisCallData=[
         {
         "url":"fittling_location_installer",
         "params":query_params
         },
         {
         "url":"advertisement",
         "params":{"ads_id":26}
         },
		{
		"url":"faq_categorys",
		"params":query_params
		}
     ];
     this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
        this.providers=data[0].data.data;
        this.banner_data=data[1].data;
		  this.faqs_cats=data[2].data;
		  console.log(this.faqs_cats[0].name);
        var n=1;
        for (var key in this.providers) {
              var obj = {
              "title": ' ',
              "lat": this.providers[key].latt,
              "lng": this.providers[key].longt,
              "icon":this.providers[key].fld_logo_image,
               "description": "<h2>"+this.providers[key].comapny+",</h2> <p>"+this.providers[key].address+"</p>"
          };
            n++;
          this.markers.push(obj);
        }
        var mapOptions = {
                center: new google.maps.LatLng(this.markers[0].lat, this.markers[0].lng),
                zoom: 12,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            var map = new google.maps.Map(document.getElementById("dvMap"), mapOptions);

            //Create and open InfoWindow.
            var infoWindow = new google.maps.InfoWindow();

            for (var i = 0; i < this.markers.length; i++) {
                var data = this.markers[i];
                var myLatlng = new google.maps.LatLng(data.lat, data.lng);
                var marker = new google.maps.Marker({
                    position: myLatlng,
                    map: map,
                    title: data.title,
                    draggable:false,
                    icon:data.icon
                });

                //Attach click event to the marker.
                (function (marker, data) {
                    google.maps.event.addListener(marker, "mouseover", function (e) {
                        infoWindow.setContent("<div class='mapCardClass'>" + data.description + "</div>");
                        infoWindow.open(map, marker);
                    });
                })(marker, data);
            }
            $('.compareDiv').show();
   });
   this.getNewCapctha();
  }

  getNewCapctha(){
    let apisCallData=[
        {
        "url":"getCaptcha",
        "params":{}
        }
    ];
    this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
      this.capctha_data=data[0].data;

    });
  }
  contact(e){

    if (e) e.preventDefault();
      if(!this.contact_form.valid){
              this.contact_form.markAsTouched();
            return ;
        }
        
      this.api_calling=true;
const formData = new FormData();
  formData.append('captcha', this.contact_form.controls['captcha'].value);
  formData.append('c_key', this.capctha_data.key);
  formData.append('phone', this.contact_form.controls['mobile'].value);
  formData.append('comments', this.contact_form.controls['message'].value);
  formData.append('name', this.contact_form.controls['name'].value);
  formData.append('email', this.contact_form.controls['email'].value);
this.CrudService.addByFormData(formData, 'contact').subscribe(res => {
  this.api_calling=false;
  if (res.status==true) {
      this.api_calling=false;
      this.getNewCapctha();
      this.contact_form.reset();
    this.pageSerice.snakeMessage(res.msg,'success');
  } else {
  this.api_calling=false;
  this.pageSerice.snakeMessage(res.msg,'Danger');
  }
});
  }
}
