import { Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
import { AuthService } from "angular-6-social-login";
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CartService } from 'src/app/common_service/cart.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
  import { SEOService } from '../../../common_service/seo.service';
 import { Http, Headers } from '@angular/http';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;
 declare var google: any;

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {
      api_calling=false;
      type:any;
         banner_data:any={
        "fld_advertisement_image":""
    };
  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    public siteService: LanguageService,
    private CartService:CartService,
    private pageSerice: PageService,
    private productsService: ProductsService,
    private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private title:Title,
    private meta:Meta,
    private http: Http,
    private CrudService:CrudService,
    private config: ConfigService,
	private seoService: SEOService
  ) {  
      
    //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      
      
  }
  contact_form = this.fb.group({
    email: ['', [
      Validators.required,
      RxwebValidators.email()
    ]],
    first_name: ['', [
      Validators.required
    ]],
    last_name: ['', [
      Validators.required
    ]],
    mobile: ['', [
      Validators.required,
      Validators.pattern("^[0-9_-]{8}"),
      RxwebValidators.digit()
    ]],
    comments: ['', [
      Validators.required
    ]],
     reason: ['', [
      Validators.required
    ]]
  });
  ngOnInit() {
      
      this.translate.get(['enquiry_page_data_meta_data.page_title', 'enquiry_page_data_meta_data.keywords','enquiry_page_data_meta_data.description',])
        .subscribe(translations => {
            this.pageSerice.pageInfo(this,{
          "page_title":translations['enquiry_page_data_meta_data.page_title'],
          "meta_data":{
              "Keywords":translations['enquiry_page_data_meta_data.keywords'],
              "description":translations['enquiry_page_data_meta_data.description']
          }
      });
    });
	
	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute); 
    
    //   this.type=this.route.snapshot.params.type;
     this.type='general';
      let  query_params={};
     let apisCallData=[
          {
         "url":"advertisement",
         "params":{"ads_id":29}
         }
     ];
     this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
        this.banner_data=data[0].data;
              
   });
    
  }
  class(cat_name){
      var cat=cat_name.replace(/ /g, "-");
      if(this.type!=''){
  if(cat===this.type){
      return "active";
  }
      }
  }
  contact(e){
            if(!this.contact_form.valid){
                      this.contact_form.markAsTouched();
                    return ;
                }
    if (e) e.preventDefault();
      this.api_calling=true;
const formData = new FormData();
      formData.append('enquiry_type', this.type);
      formData.append('mobile', this.contact_form.controls['mobile'].value);
      formData.append('comments', this.contact_form.controls['comments'].value);
      formData.append('first_name', this.contact_form.controls['first_name'].value);
      formData.append('last_name', this.contact_form.controls['last_name'].value);
      formData.append('email', this.contact_form.controls['email'].value);
        formData.append('reason', this.contact_form.controls['reason'].value);
this.CrudService.addByFormData(formData, 'enquiry').subscribe(res => {
  this.api_calling=false;
  if (res.status==true) {
      this.api_calling=false;
      this.contact_form.reset();
    this.pageSerice.snakeMessage(res.msg,'success');
  } else {
  this.api_calling=false;
  this.pageSerice.snakeMessage(res.msg,'Danger');
  }
});
  }
  open_tab(name){
    console.log(name);
    $("#general_tab").fadeOut();
    this.type=name;
    $("#general_tab").fadeIn();
     
  }
  
}
