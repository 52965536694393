import { Component, OnInit, ViewChild, ElementRef,AfterViewInit,OnDestroy} from '@angular/core';
import { Options } from 'ng5-slider';
import {PageService} from 'src/app/common_service/page.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { CrudService } from 'src/app/common_service/crud.service';
import { SEOService } from '../../../common_service/seo.service';
import { CartService } from 'src/app/common_service/cart.service';
import { LanguageService } from 'src/app/common_service/language.service';
import { ProductsService } from 'src/app/common_service/products.service';
import { TranslateService } from '@ngx-translate/core';
import {Title , Meta} from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie-service';
import { Observable, forkJoin } from 'rxjs';
 import { Http, Headers } from '@angular/http';
declare var $: any;
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit,AfterViewInit,OnDestroy {
    selected_front_tyre_width= '';
    selected_front_tyre_height= '';
    selected_front_tyre_rim_size= '';



  selected_rear_tyre_width= '';
  selected_rear_tyre_height= '';
  selected_rear_tyre_rim_size= '';

  selected_front_tyre_width2= '';
  selected_front_tyre_height2= '';
  selected_front_tyre_rim_size2= '';

  selected_rear_tyre_width2= '';
  selected_rear_tyre_height2= '';
  selected_rear_tyre_rim_size2= '';


    sitelang = '';
  selected_fitting=false;
avg:any=0;
    selected_location= '';
    selected_latt= '';
    selected_lang= '';

    selected_make_name= '';
    selected_modal_name= '';
    selected_year= '';

    selected_make_id= '';
    selected_modal_id= '';
    product_type:any='tyre';
    gift_card:any=[];
    tyre_filter_shows=false;
    product_box_tile:any;
    skeletonloader = true;
  minValue: any=0;
  maxValue: any=0;
  pminValue: any = 0;
   pmaxValue: any = 0;
      compareSleceted=0;
    price_filter_applied=false;
      search_filter=false;
    search_filter_values='';
    brand_filter_applied=false;
    terminal_filter_applied=false;
     vol_filter_applied=false;
      amp_filter_applied=false;
    load_index_filter_applied=false;
    speed_rating_filter_applied=false;

    run_flat_filter_applied=false;
    year_filter_applied=false;
    pattern_filter_applied=false;


    currency:any='';
  options: any = {
    floor: 0,
    ceil: 500,
    showSelectionBar: true,
  };

    @ViewChild('target', {static: true }) scrollto: ElementRef;
    @ViewChild('load_index_filter', {static: true }) load_index_filter: ElementRef;
    @ViewChild('speed_rating_filter', {static: true }) speed_rating_filter: ElementRef;
    @ViewChild('min', {static: true }) min: ElementRef;
    @ViewChild('max', {static: true }) max: ElementRef;
  cat_url:any;
  records:any=[] ;
  brandForFilter:any=[] ;
    amprsForFilter:any=[] ;
    voltagesForFilter:any=[] ;
    terminalsForFilter:any=[] ;

    run_flat:any=[] ;
    years:any=[] ;
    pattern:any=[] ;

    
    selectedBrandFilter:any=[] ;

  selectedampsFilter:any=[] ;
  selectedtermianlFilter:any=[] ;
    selectedvoltageFilter:any=[] ;

    selectedRunflatFilter:any=[] ;
    selectedyearFilter:any=[] ;
    selectedpatternFilter:any=[] ;

  page:any;
  totalRec:any;
  constructor(
            private pgServ:PageService,
            private router:Router,
            private http: Http,
            private title:Title,
            private meta:Meta,
            private translate: TranslateService,
            private route: ActivatedRoute,
            private CrudService:CrudService,
            private CartService:CartService,
            private LanguageService:LanguageService,
            public productsService: ProductsService,
            private CookieService:CookieService,
			private seoService: SEOService
  ) {
     this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	 
    this.LanguageService.selectedLang.subscribe(res=>{
      if(res == 'ar'){
        this.sitelang='ar';
      } else{
        this.sitelang='';
      }

  });
    this.product_type=this.route.snapshot.params.type;
      this.route.params.subscribe(params=>{
        if(params.hasOwnProperty('type')){
          this.page= (parseInt(params.page)>0)?parseInt(params.page):1;
          this.product_type=params.type;
          if(this.product_type=='tyre'){
            this.tyre_filter_shows=true;
            this.product_box_tile='Tires';
          }else if(this.product_type=='battery'){
            this.product_box_tile='Battery';
          } else{
            this.product_box_tile='Unknown';
          }
        }
      });


   }
   restProducts(){
       var product =[];
         this.records.forEach((p,index)=>{
            if(p.offer_code==0){
                   product.push(p);
            }
    });
     return product;
   }
     recommneddProducts(){
       var product =[];
         this.records.forEach((p,index)=>{
            if(p.offer_code!=0){
                   product.push(p);
            }
    });
     return product;
   }
   
   recommnedation_tag(){
       var reccom=false;
        this.records.forEach((p,index)=>{
            if(p.offer_code!=0){
                    reccom=true;
            }
    });

       return reccom;
   }
    search_fun(val){
        if(val!=''){
            var n=val.replace(/\s+/g, '~');
            this.router.navigate([], {queryParams: {search: n,page:1}, queryParamsHandling: 'merge'});
        }

   }


   search_tyre_make_modal(){
      $(".modal").modal('hide');


     let  params={};
     if(this.selected_make_name!=''){
         let querystring = { "make_name" :this.selected_make_name.replace(/\s+/g, '-')};
         $.extend(params,querystring);
    }
    if(this.selected_modal_name!=''){
        let querystring = { "modal_name" :this.selected_modal_name.replace(/\s+/g, '-')};
        $.extend(params,querystring);
   }
   if(this.selected_make_id!=''){
       let querystring = { "make_id" :this.selected_make_id};
       $.extend(params,querystring);
  }
  if(this.selected_modal_id!=''){
      let querystring = { "modal_id" :this.selected_modal_id};
      $.extend(params,querystring);
  }

   if(this.selected_year!=''){
       let querystring = { "year" :this.selected_year};
       $.extend(params,querystring);
  }
  this.router.navigate(['products/tyre'], { queryParams: params });
  this.productsService.filter_applied.next('Yes');


  }
  tyreSearcher(type){
            $(".mytab").removeClass("active");
            $("#tyreFilter_"+type).addClass("active");
            $(".tab-pane").hide();
            $("#banner_form"+type).show();
  }

  search_tyre_on_filter(filter_type){
     

  $(".modal").modal('hide');
  if(filter_type==0){

     let  query_params={};
      $.extend(query_params,this.location_params(query_params));

        $.extend(query_params,{"page":1});
      if(this.selected_front_tyre_width!=''){
        $.extend(query_params,{"f_width":this.selected_front_tyre_width});
      }
      if(this.selected_front_tyre_height!=''){
      $.extend(query_params,{"f_height":this.selected_front_tyre_height});
      }
      if(this.selected_front_tyre_rim_size!=''){
      $.extend(query_params,{"f_rim":this.selected_front_tyre_rim_size});
  }

  if(this.selected_rear_tyre_width!=''){
      let querystring = { "r_width" :this.selected_rear_tyre_width};
      $.extend(query_params,querystring);
 }
 if(this.selected_rear_tyre_height!=''){
     let querystring = { "r_height" :this.selected_rear_tyre_height};
     $.extend(query_params,querystring);
 }
 if(this.selected_rear_tyre_rim_size!=''){
    let querystring = { "r_rim" :this.selected_rear_tyre_rim_size};
    $.extend(query_params,querystring);
 }
 this.router.navigate(['products/tyre'], { queryParams: query_params });
 this.productsService.filter_applied.next('Yes');

  }

  

  }
   getsearchData(){
     var n=this.search_filter_values.replace('~', ' ');
     return n;
   }
////revmove filter function
clear_search_filter(){
        this.router.navigate([], {queryParams: {search: null,page:1}, queryParamsHandling: 'merge'});
        this.search_filter=false;
      }
     clear_price_filter(){
        this.router.navigate([], {queryParams: {min: null,max:null,page:1}, queryParamsHandling: 'merge'});
        this.price_filter_applied=false;
     }
     clear_load_index_filter(){
        this.router.navigate([], {queryParams: {load_index_filter:null,page:1}, queryParamsHandling: 'merge'});
        this.load_index_filter_applied=false;
     }
     clear_speed_rating_filter(){
        this.router.navigate([], {queryParams: {speed_rating_filter:null,page:1}, queryParamsHandling: 'merge'});
        this.speed_rating_filter_applied=false;
     }
     clear_filter(filter,filter_flag){

       this.route.queryParams.subscribe(params=>{

         if(params.hasOwnProperty('filters')){
                var flters = [];
                var filters=params.filters;
                var filters_array = filters.split(",");
                filters_array.forEach((val,index)=>{
                var n = val.search(filter);
                if(n==0){
                    flters.push(val);
                 }
              });
              var brd=flters.join();
              var res = filters.replace(brd,"");
              var out=((res.length)>0 && res!=',')?res:null;
                this.router.navigate([], {queryParams: {page:1,filters:out}, queryParamsHandling: 'merge'});
         }
       });

     }
  ////revmove filter function
checkthis(){
            var facilitiesid = new Array();
            $( "input[name='product_compare[]']:checked" ).each( function()
            {
            facilitiesid.push( $(this).attr('id') );
            });
            var compareProducts=facilitiesid.length;
            this.compareSleceted=compareProducts;
            if(this.compareSleceted>1){
                 this.CartService.addToCompare(JSON.stringify(facilitiesid));
            }else{
                this.CookieService.delete('compareproducts')
            }

}
clearFormcompare(){
     this.compareSleceted=0;
      $(".myCheck").prop("checked", false);
}
compare_all(){
  var facilitiesid = new Array();
        $( "input[name='product_compare[]']:checked" ).each( function()
        {
          facilitiesid.push( $(this).attr('id') );
        });
var compareProducts=facilitiesid.length;
if(compareProducts>1){
    this.CartService.addToCompare(JSON.stringify(facilitiesid));
    this.router.navigate(['product-compare']);
} else{
    this.pgServ.snakeMessage('Please select products to compare','Danger');
}
}
add_to_cart(qty:any,product:any){
    var item={
      "product_id":product.id,
      "qty":qty,
      "data":product
    };

 this.CartService.addToCart(item);

}
filter_modal_open(){
  $('.Offcanvas_menu_wrapper,.off_canvars_overlay').addClass('active');
}
filter_modal_close(){
    $('.Offcanvas_menu_wrapper,.off_canvars_overlay').removeClass('active');
}
open_close_modal(open_modal_id,close_modal_id){
  if(close_modal_id!=0){
      $('#myModalprocess'+close_modal_id).modal('hide');
  }
  if(open_modal_id!=0){
    switch(open_modal_id){

      case 1:
            this.productsService.selected_front_tyre_width.next('');
            this.productsService.selected_front_tyre_height.next('');
            this.productsService.selected_front_tyre_rim_size.next('');
      break;

      case 2 :
                this.productsService.selected_front_tyre_height.next('');
                this.productsService.selected_front_tyre_rim_size.next('');
      break;

      case 3:
                      this.productsService.selected_front_tyre_rim_size.next('');
      break;

      case 5:
              this.productsService.selected_rear_tyre_width.next('');
              this.productsService.selected_rear_tyre_height.next('');
              this.productsService.selected_rear_tyre_rim_size.next('');
      break;

      case 6:
this.productsService.selected_rear_tyre_height.next('');
this.productsService.selected_rear_tyre_rim_size.next('');

      break;

      case 7:

          this.productsService.selected_rear_tyre_rim_size.next('');

      break;

      case 70:
          this.productsService.selected_make_name.next('');
          this.productsService.selected_make_id.next('');
          this.productsService.selected_modal_id.next('');
          this.productsService.selected_modal_name.next('');
          this.productsService.selected_year.next('');
      break;

      case 80:
            this.productsService.selected_modal_id.next('');
            this.productsService.selected_modal_name.next('');
            this.productsService.selected_year.next('');

      break;

      case 90:
          this.productsService.selected_year.next('');
      break;
    }


  this.productsService.getTyreConfiguration(open_modal_id,0);
      $('#myModalprocess'+open_modal_id).modal('show');
  }

}
   sliderOptions(): Options {
       return {
        floor: this.pminValue,
        ceil: this.pmaxValue,
        hideLimitLabels: true,
        hidePointerLabels: true,
        showSelectionBarFromValue: -10
       };
     }
     ngAfterViewInit(){
        
      //   if($(window).width() >= 767){
      //       setTimeout(function(){
      //       if(this.product_type=='tyre'){
      //           window.scroll(0,230); 
      //       }else{
      //           console.log("hello this battery 810");
      //           window.scroll(0,810);
      //       }
            
      //     //document.querySelector('#new_target').scrollIntoView({ behavior: 'smooth' });
      // }, 2000);
            
      //   }
            this.getFilters();

             $("#prdfiltercolllaps, .closeicon").click(function(){
              $(".hideshowprddiv, .closeicon").slideToggle(500);
            });
             $(document).ready(function(){
          $("#different_rear_tyre").click(function(){
            $(".different_reartab").delay(100).slideToggle();
          });
        });
        //new
        $(document).ready(function(){
          $("#different_rear_tyre2").click(function(){
            $(".different_reartab2").delay(100).slideToggle();
          });
        });
   this.scrollto.nativeElement.scrollIntoView({ behavior: 'smooth' });

     }

     get_height_width_rim_size_filter(){
      let  params={};
      if(this.selected_front_tyre_width!=''){
          let querystring = { "f_width" :this.selected_front_tyre_width};
          $.extend(params,querystring);
     }
     if(this.selected_front_tyre_height!=''){
         let querystring = { "f_height" :this.selected_front_tyre_height};
         $.extend(params,querystring);
    }
    if(this.selected_front_tyre_rim_size!=''){
        let querystring = { "f_rim" :this.selected_front_tyre_rim_size};
        $.extend(params,querystring);
   }
  
   if(this.selected_rear_tyre_width!=''){
       let querystring = { "r_width" :this.selected_rear_tyre_width};
       $.extend(params,querystring);
  }
  if(this.selected_rear_tyre_height!=''){
      let querystring = { "r_height" :this.selected_rear_tyre_height};
      $.extend(params,querystring);
  }
  if(this.selected_rear_tyre_rim_size!=''){
     let querystring = { "r_rim" :this.selected_rear_tyre_rim_size};
     $.extend(params,querystring);
  }
 return params
  }
getFilters(){
    let pm=this.get_height_width_rim_size_filter();
    let  query_params=pm;
     $.extend(query_params,{"product_type":this.product_type});
  let apisCallData=[
          {
          "url":"brandsList",
          "params":query_params
          },
        {
        "url":"tyresminMaxPrice",
        "params":query_params
      },
      {
      "url":"getAmprs",
      "params":query_params
      },
      {
      "url":"getVoltages",
      "params":query_params
      },
      {
      "url":"getTermials",
      "params":query_params
    },
    {
    "url":"gift_card",
    "params":query_params
  },
      {
      "url":"tyreYear",
      "params":query_params
      },
      {
      "url":"pattern",
      "params":query_params
      },
      {
      "url":"runflat",
      "params":query_params
      }
  ];

  this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
                this.brandForFilter = data[0].data;
                this.pminValue=data[1].data.min;
                this.pmaxValue=data[1].data.max;
                this.amprsForFilter = data[2].data;
                this.voltagesForFilter = data[3].data;
                this.terminalsForFilter = data[4].data;
                this.gift_card = data[5].data;
                this.years = data[6].data;
                this.pattern = data[7].data;
                this.run_flat = data[8].data;
                
    
                if(this.maxValue==0){
                    this.maxValue=this.pmaxValue
                }
                if(this.minValue==0){
                    this.minValue=this.pminValue
                }
               

                 $("#slider-range").slider({
            range: true,
            min: parseFloat(this.pminValue),
            max: parseFloat(this.pmaxValue)+1,
            step: 0.1,
            values: [parseFloat(this.minValue), parseFloat(this.maxValue)],
            slide: function (e, ui) {
                var hours1 = Math.floor(ui.values[0]);

                $('.slider-time').html(hours1);

                var hours2 = Math.floor(ui.values[1]);

                $('.slider-time2').html(hours2);
            }
        });
        // $('#slider-range').slider().on('slide', function(ev){
         
        // });
        
            
               
        // $( "#slider-range" ).slider( "option", "max", parseInt(600));
});

}


height_width_rim_size_filter(){
    let  params={};
    if(this.selected_front_tyre_width!=''){
        let querystring = { "f_width" :this.selected_front_tyre_width};
        $.extend(params,querystring);
   }
   if(this.selected_front_tyre_height!=''){
       let querystring = { "f_height" :this.selected_front_tyre_height};
       $.extend(params,querystring);
  }
  if(this.selected_front_tyre_rim_size!=''){
      let querystring = { "f_rim" :this.selected_front_tyre_rim_size};
      $.extend(params,querystring);
 }

 if(this.selected_rear_tyre_width!=''){
     let querystring = { "r_width" :this.selected_rear_tyre_width};
     $.extend(params,querystring);
}
if(this.selected_rear_tyre_height!=''){
    let querystring = { "r_height" :this.selected_rear_tyre_height};
    $.extend(params,querystring);
}
if(this.selected_rear_tyre_rim_size!=''){
   let querystring = { "r_rim" :this.selected_rear_tyre_rim_size};
   $.extend(params,querystring);
}
this.apicall(params);
}

make_modal_filter(){
    let  params={};
    if(this.selected_make_name!=''){
        let querystring = { "make_name" :this.selected_make_name};
        $.extend(params,querystring);
   }
   if(this.selected_modal_name!=''){
       let querystring = { "modal_name" :this.selected_modal_name};
       $.extend(params,querystring);
  }
  if(this.selected_make_id!=''){
      let querystring = { "make_id" :this.selected_make_id};
      $.extend(params,querystring);
 }
 if(this.selected_modal_id!=''){
     let querystring = { "modal_id" :this.selected_modal_id};
     $.extend(params,querystring);
}

  if(this.selected_year!=''){
      let querystring = { "year" :this.selected_year};
      $.extend(params,querystring);
 }
 this.apicall(params);
}
ngOnDestroy(){
}
checkbox_filter_close(index,name){
      $("#"+name+index). prop("checked", false);
      $("#"+name+'_lebel'+index).hide();
}
checkbox_filter_open(index,name){
  var ischecked= $("#"+name+index).is(':checked');
                  if(!ischecked){
                        $("#"+name+'_lebel'+index).hide();
                     } else{
                          $("#"+name+'_lebel'+index).show();
                     }
}
  ngOnInit() {
	  const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute);
    if(this.product_type=='tyre'){
      this.translate.get(['tyre.page_title', 'tyre.keywords','tyre.description',])
      .subscribe(translations => {
          this.pgServ.pageInfo(this,{
        "page_title":translations['tyre.page_title'],
        "meta_data":{
            "Keywords":translations['tyre.keywords'],
            "title":translations['tyre.page_title'],
            "description":translations['tyre.description']
        }
    });
  });
    }else{
      this.translate.get(['battery.page_title', 'battery.keywords','battery.description',])
      .subscribe(translations => {
          this.pgServ.pageInfo(this,{
        "page_title":translations['battery.page_title'],
        "meta_data":{
            "Keywords":translations['battery.keywords'],
            "title":translations['battery.page_title'],
            "description":translations['battery.description']
        }
    });
  });
    }
        
        if (localStorage.getItem('withFitting') && this.product_box_tile=='Tires') {
            this.productsService.selected_fitting.next(true);
        } else{
           localStorage.removeItem("withFitting");
            this.productsService.selected_fitting.next(false);
        }
    this.productsService.selected_fitting.subscribe(selected_fitting=>{
      this.selected_fitting= selected_fitting;
    });


    this.productsService.selected_location.subscribe(selected_location=>{
      this.selected_location= selected_location;
    });

    this.productsService.selected_latt.subscribe(selected_latt=>{
    this.selected_latt= selected_latt;
    });

    this.productsService.selected_lang.subscribe(selected_lang=>{
      this.selected_lang= selected_lang;
    });
// subcribe tyre dimensions
    this.productsService.selected_front_tyre_width.subscribe(selected_front_tyre_width=>{
      this.selected_front_tyre_width= selected_front_tyre_width;
    });

    this.productsService.selected_front_tyre_height.subscribe(selected_front_tyre_height=>{
    this.selected_front_tyre_height= selected_front_tyre_height;

    });

    this.productsService.selected_front_tyre_rim_size.subscribe(selected_front_tyre_rim_size=>{
      this.selected_front_tyre_rim_size= selected_front_tyre_rim_size;
    });

    this.productsService.selected_rear_tyre_width.subscribe(selected_rear_tyre_width=>{
        this.selected_rear_tyre_width= selected_rear_tyre_width;
    });

    this.productsService.selected_rear_tyre_height.subscribe(selected_rear_tyre_height=>{
        this.selected_rear_tyre_height= selected_rear_tyre_height;
    });

    this.productsService.selected_rear_tyre_rim_size.subscribe(selected_rear_tyre_rim_size=>{
        this.selected_rear_tyre_rim_size= selected_rear_tyre_rim_size;
    });

    this.productsService.selected_make_name.subscribe(selected_make_name=>{
      this.selected_make_name= selected_make_name;
    });


    this.productsService.selected_modal_name.subscribe(selected_modal_name=>{
      this.selected_modal_name= selected_modal_name;
    });

    this.productsService.selected_make_id.subscribe(selected_make_id=>{
    this.selected_make_id= selected_make_id;
    });

    this.productsService.selected_modal_id.subscribe(selected_modal_id=>{
      this.selected_modal_id= selected_modal_id;
    });
    this.productsService.selected_year.subscribe(selected_year=>{
      this.selected_year= selected_year;
    });

    this.productsService.filter_applied.subscribe(called=>{
      if(called!=''){
          this.height_width_rim_size_filter();
      }

    });

    this.productsService.filter_make_modal_applied.subscribe(called=>{
      if(called!=''){
          this.make_modal_filter();
      }

    });
// subcribe tyre dimensions



    this.LanguageService.currency.subscribe(res=>{
    this.currency=res;
    });
    let min,max,load_index_filter,speed_rating_filter: any ;
    let  Queryparams={};
    // this.cat_url=this.route.snapshot.params.cat_url;
    this.cat_url=3;
    this.route.queryParams.subscribe(params=>{
      if(params.hasOwnProperty('page')){
        this.page= (parseInt(params.page)>0)?parseInt(params.page):1;
      }


      if(params.hasOwnProperty('filters')){
          $.extend(Queryparams,{"filters":params.filters});
        var filters=params.filters;
        var filters_array = filters.split(",");
        filters_array.forEach((val,index)=>{
          var n = val.search("brand");
          if(n==0){
            this.brand_filter_applied=true;
            var str=val.replace('brand','')
              this.selectedBrandFilter.push( Number(str) );
           }
           var a = val.search("apms");
           if(a==0){
             this.amp_filter_applied=true;
             var str=val.replace('apms','')
               this.selectedampsFilter.push((str) );
            }
            var v = val.search("vols");
            if(v==0){
               this.vol_filter_applied=true;
              var str=val.replace('vols','')
                this.selectedvoltageFilter.push((str) );
             }
             var t = val.search("terminal");
             if(t==0){
                this.terminal_filter_applied=true;
               var str=val.replace('terminal','')
                 this.selectedtermianlFilter.push((str) );
              }
              var run = val.search("run");
              if(run==0){
                 this.run_flat_filter_applied=true;
                var str=val.replace('run','')
                  this.selectedRunflatFilter.push((str) );
               }
               var year = val.search("year");
               if(year==0){
                  this.year_filter_applied=true;
                 var str=val.replace('year','')
                   this.selectedyearFilter.push((str) );
                }
                var pattern = val.search("pattern");
                if(pattern==0){
                   this.pattern_filter_applied=true;
                  var str=val.replace('pattern','')
                    this.selectedpatternFilter.push((str) );
                 }
        })
      }

      if(params.hasOwnProperty('min')){
          this.minValue= parseInt(params.min);
          this.price_filter_applied=true;
     }

     if(params.hasOwnProperty('f_width')){
       this.productsService.selected_front_tyre_width.next( (params.f_width));
       this.selected_front_tyre_width2=(params.f_width);
    }

    if(params.hasOwnProperty('f_height')){
         this.productsService.selected_front_tyre_height.next( (params.f_height));
            this.selected_front_tyre_height2=(params.f_height);
   }

   if(params.hasOwnProperty('f_rim')){
         this.productsService.selected_front_tyre_rim_size.next((params.f_rim));
           this.selected_front_tyre_rim_size2=(params.f_rim);
  }

  if(params.hasOwnProperty('r_width')){
    this.productsService.selected_rear_tyre_width.next( (params.r_width));
     this.selected_rear_tyre_width2=(params.r_width);
 }

 if(params.hasOwnProperty('r_height')){
      this.productsService.selected_rear_tyre_height.next( (params.r_height));
       this.selected_rear_tyre_height2=(params.r_height);
}

if(params.hasOwnProperty('r_rim')){
      this.productsService.selected_rear_tyre_rim_size.next((params.r_rim));
       this.selected_rear_tyre_rim_size2=(params.r_rim);
}

if(params.hasOwnProperty('location')){
      this.productsService.selected_location.next((params.location.replace("-", ' ')));
}
if(params.hasOwnProperty('latt')){
      this.productsService.selected_latt.next((params.latt));
}
if(params.hasOwnProperty('long')){
      this.productsService.selected_lang.next((params.long));
}

  if(params.hasOwnProperty('make_name')){
        this.productsService.selected_make_name.next((params.make_name.replace("-", ' ')));
 }
 if(params.hasOwnProperty('modal_name')){
       this.productsService.selected_modal_name.next((params.modal_name.replace("-", ' ')));
}
if(params.hasOwnProperty('make_id')){
      this.productsService.selected_make_id.next((params.make_id));
}
if(params.hasOwnProperty('modal_id')){
      this.productsService.selected_modal_id.next((params.modal_id));
}
if(params.hasOwnProperty('year')){
      this.productsService.selected_year.next((params.year));
}

     if(params.hasOwnProperty('load_index_filter')){
      load_index_filter=params.load_index_filter;
      this.load_index_filter_applied=true;
      this.load_index_filter.nativeElement.value=load_index_filter;
      }
      if(params.hasOwnProperty('speed_rating_filter')){
        speed_rating_filter=params.speed_rating_filter;
          this.speed_rating_filter_applied=true;
        this.speed_rating_filter.nativeElement.value=speed_rating_filter;
        }
     if(params.hasOwnProperty('max')){
       this.maxValue=parseInt(params.max);
       this.price_filter_applied=true;
     }

      if(params.hasOwnProperty('search')){
           this.search_filter_values=(params.search);
           this.search_filter=true;
             $.extend(Queryparams,{"search":this.search_filter_values});
         }
    });

    let querystring = { "page" :this.page};
    let cat = { "cat" :this.cat_url};
    $.extend(Queryparams,querystring);
    $.extend(Queryparams,cat);
    $.extend(Queryparams,{"max":this.maxValue});
    $.extend(Queryparams,{"min":this.minValue});

    $.extend(Queryparams,{"load_index_filter":load_index_filter});
    $.extend(Queryparams,{"speed_rating_filter":speed_rating_filter});

   this.apicall(Queryparams);
  }
maskValue(pattern){
    var n=pattern.replace(/\s+/g, '~');
    return n;
}
  getPage(page: number) {
    this.page=page;
    let load_index_filter=this.load_index_filter.nativeElement.value;
    let speed_rating_filter=this.speed_rating_filter.nativeElement.value;
    let  query_params={};
            
            var max_price=$('.slider-time').text();
            var min_price=$('.slider-time2').text();
            
            // if(this.pminValue!=this.minValue || this.pmaxValue!=this.maxValue){
            //     $.extend(query_params,{"max":min_price});
            //     $.extend(query_params,{"min":max_price});
            // }

    $.extend(query_params,{"max":min_price});
    $.extend(query_params,{"min":max_price});
                
    $.extend(query_params,{"page":this.page});
    if(load_index_filter){
      $.extend(query_params,{"load_index_filter":load_index_filter});
    } else{
      $.extend(query_params,{"load_index_filter":null});
    }

    if(speed_rating_filter){
      $.extend(query_params,{"speed_rating_filter":speed_rating_filter});
    } else{
      $.extend(query_params,{"speed_rating_filter":null});
    }
      this.router.navigate(
    [],
    {
    relativeTo: this.route,
    queryParams: query_params,
    queryParamsHandling: 'merge'
    });
     let cat = { "cat" :this.cat_url};
    $.extend(query_params,cat);
     this.scrollto.nativeElement.scrollIntoView({ behavior: 'smooth' });
    this.apicall(query_params);
  }

sliderChange(e){
    console.log(e);
}
  filter_result() {
      
            var max_price=$('.slider-time').text();
            var min_price=$('.slider-time2').text();

	
    $('.Offcanvas_menu_wrapper,.off_canvars_overlay').removeClass('active');
    var facilitiesid = new Array();


    	$( "input[name='checkbox[]']:checked" ).each( function()
    	{
    		facilitiesid.push( $(this).attr('id') );
    	});


    let  query_params={};
    $.extend(query_params,{"max":min_price});
    $.extend(query_params,{"min":max_price});


    $.extend(query_params,{"page":1});

    if(facilitiesid.length>0){
      $.extend(query_params,{"filters":facilitiesid.toString()});
    }

    if(this.product_type=='tyre'){
      let load_index_filter=this.load_index_filter.nativeElement.value;
      let speed_rating_filter=this.speed_rating_filter.nativeElement.value;
      if(load_index_filter){
        $.extend(query_params,{"load_index_filter":load_index_filter});
      } else{
        $.extend(query_params,{"load_index_filter":null});
      }

      if(speed_rating_filter){
        $.extend(query_params,{"speed_rating_filter":speed_rating_filter});
      } else{
        $.extend(query_params,{"speed_rating_filter":null});
      }


    }



    this.page=1;
      this.router.navigate(
    [],
    {
    relativeTo: this.route,
    queryParams: query_params,
    queryParamsHandling: 'merge'
    });


    let cat = { "cat" :this.cat_url};
    $.extend(query_params,cat);

    //this.scrollto.nativeElement.scrollIntoView({ behavior: 'smooth' });
     this.apicall(query_params);
  }
  location_params(params){
    if(this.selected_location!=''){
        let querystring = { "location" :this.selected_location.replace(/\s+/g, '-')};
        $.extend(params,querystring);
   }
   if(this.selected_latt!=''){
       let querystring = { "latt" :this.selected_latt};
       $.extend(params,querystring);
  }
  if(this.selected_lang!=''){
      let querystring = { "long" :this.selected_lang};
      $.extend(params,querystring);
 }
 return params;
  }
  avgprice(min,max){
    if(min!=0 || max!=0){
      return ((min+max)/2)
    } else{
      return 0;
    }

  }
  apicall(params){


       params=this.location_params(params);
       this.route.queryParams.subscribe(params1=>{

         if(params1.hasOwnProperty('offer_type')){
             $.extend(params,{"offer_type":params1.offer_type});
         }
         if(params1.hasOwnProperty('offer_discount')){
             $.extend(params,{"offer_discount":params1.offer_discount});
         }
         if(params1.hasOwnProperty('offer_id')){
          $.extend(params,{"offer_id":params1.offer_id});
      }
      if(params1.hasOwnProperty('show_products')){
        $.extend(params,{"show_products":params1.show_products});
    }
       });

  $.extend(params,{"product_type":this.product_type});

  if(this.selected_front_tyre_width!=''){
      let querystring = { "f_width" :this.selected_front_tyre_width};
      $.extend(params,querystring);
 }
 if(this.selected_front_tyre_height!=''){
     let querystring = { "f_height" :this.selected_front_tyre_height};
     $.extend(params,querystring);
}
if(this.selected_front_tyre_rim_size!=''){
    let querystring = { "f_rim" :this.selected_front_tyre_rim_size};
    $.extend(params,querystring);
}

if(this.selected_rear_tyre_width!=''){
   let querystring = { "r_width" :this.selected_rear_tyre_width};
   $.extend(params,querystring);
}
if(this.selected_rear_tyre_height!=''){
  let querystring = { "r_height" :this.selected_rear_tyre_height};
  $.extend(params,querystring);
}
if(this.selected_rear_tyre_rim_size!=''){
 let querystring = { "r_rim" :this.selected_rear_tyre_rim_size};
 $.extend(params,querystring);
}

if(this.selected_make_name!=''){
    let querystring = { "make_name" :this.selected_make_name};
    $.extend(params,querystring);
}
if(this.selected_modal_name!=''){
   let querystring = { "modal_name" :this.selected_modal_name};
   $.extend(params,querystring);
}
if(this.selected_make_id!=''){
  let querystring = { "make_id" :this.selected_make_id};
  $.extend(params,querystring);
}
if(this.selected_modal_id!=''){
 let querystring = { "modal_id" :this.selected_modal_id};
 $.extend(params,querystring);
}

if(this.selected_year!=''){
  let querystring = { "year" :this.selected_year};
  $.extend(params,querystring);
}
  this.CrudService.list(params,'tyres').subscribe(data => {
     if(data.data){
            this.records = data.data.products;
            this.avg=data.data.avg;
        this.totalRec=data.data.total_records;
     } else{
      this.records = [];
     }

    });
  }



   selectedbrand(type,id){
     if(type==0){
       var n = this.selectedBrandFilter.includes(id);
       return n;
     }
     if(type==1){
       var n = this.selectedBrandFilter.includes(id);
       if(n){
         return 'block';
       } else{
         return 'none';
       }
     }

   }
   selectedamr(type,id){
     if(type==0){
       var n = this.selectedampsFilter.includes(id);
       return n;
     }
     if(type==1){
       var n = this.selectedampsFilter.includes(id);
       if(n){
         return 'block';
       } else{
         return 'none';
       }
     }

   }
   selectedvoltage(type,id){
     if(type==0){
       var n = this.selectedvoltageFilter.includes(id);
       return n;
     }
     if(type==1){
       var n = this.selectedvoltageFilter.includes(id);
       if(n){
         return 'block';
       } else{
         return 'none';
       }
     }

   }
   selectedrunFlat(type,id){
     if(type==0){
       var n = this.selectedRunflatFilter.includes(id);
       return n;
     }
     if(type==1){
       var n = this.selectedRunflatFilter.includes(id);
       if(n){
         return 'block';
       } else{
         return 'none';
       }
     }

   }
   selectedpattern(type,id){
     if(type==0){
       var n = this.selectedpatternFilter.includes(id);
       return n;
     }
     if(type==1){
       var n = this.selectedpatternFilter.includes(id);
       if(n){
         return 'block';
       } else{
         return 'none';
       }
     }

   }
   selectedyear(type,id){
     if(type==0){
       var n = this.selectedyearFilter.includes(id);
       return n;
     }
     if(type==1){
       var n = this.selectedyearFilter.includes(id);
       if(n){
         return 'block';
       } else{
         return 'none';
       }
     }

   }
   selectedtermial(type,id){
     if(type==0){
       var n = this.selectedtermianlFilter.includes(id);
       return n;
     }
     if(type==1){
       var n = this.selectedtermianlFilter.includes(id);
       if(n){
         return 'block';
       } else{
         return 'none';
       }
     }

   }

}
