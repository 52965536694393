import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject, Subscriber} from 'rxjs';

import { AutheService } from 'src/app/common_service/authe.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CrudService } from 'src/app/common_service/crud.service';
import {LanguageService} from 'src/app/common_service/language.service';
import { CartService } from 'src/app/common_service/cart.service';
import {PageService} from 'src/app/common_service/page.service';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  profile_data:any;
  api_calling=false;
    constructor(  private translate: TranslateService,
      public siteService: LanguageService,
      private CartService:CartService,
      public authService: AutheService,
      private fb: FormBuilder,
      private CrudService:CrudService,
      private pageSerice:PageService) { }
      profile_form = this.fb.group({
        email: ['', [
          Validators.required,
          RxwebValidators.email()
        ]],
        name: ['', [
          Validators.required
        ]],
        phone: ['', [
      Validators.required,
      Validators.pattern("^[0-9_-]{8}"),
      RxwebValidators.digit()
        ]],
        gender: ['', [
          Validators.required
        ]]
      });
    ngOnInit() {
      let  query_params={"fld_user_id":this.authService.userdata().fld_user_id};
       this.CrudService.add(query_params,'getuserProfile').subscribe(data => {
             if(data.status==true){
               this.profile_data=data.data;
                this.profile_form.setValue(
                    {
                    name: this.profile_data.fld_user_name,
                    email: this.profile_data.fld_user_email,
                    phone: this.profile_data.fld_user_phone,
                    gender: this.profile_data.fld_user_gender
                  }
                   );

             }
     });
    }
    update_profile(e){
      if (e) e.preventDefault();
      
            if(!this.profile_form.valid){
            this.profile_form.markAsTouched();
            return ;
            }
                
        this.api_calling=true;
      let  query_params={
          "fld_user_id":this.authService.userdata().fld_user_id,
          "fld_user_name":this.profile_form.controls['name'].value,
          "fld_user_email":this.profile_form.controls['email'].value,
          "fld_user_phone":this.profile_form.controls['phone'].value,
          "fld_user_gender":this.profile_form.controls['gender'].value
    };
    this.CrudService.add(query_params,'updateProfile').subscribe(data => {
          this.api_calling=false;
          if (data.status==true) {
            var user={
              "fld_user_id":this.authService.userdata().fld_user_id,
              "fld_user_name":this.profile_form.controls['name'].value,
            };
            this.authService.update_data(user);
            this.pageSerice.snakeMessage(data.msg,'success');
          } else {
            this.pageSerice.snakeMessage(data.msg,'Danger');
          }

  });

    }
}
@Component({
  selector: 'dshside',
  templateUrl: 'dashboard_sidebar.html'
})
export class DashboardSideComponent  implements OnInit{
  constructor(
      private translate: TranslateService,
      public siteService: LanguageService,
      private CartService:CartService,
      public authService: AutheService,
      private fb: FormBuilder,
      private CrudService:CrudService,
      private pageSerice:PageService
  ) { }

  ngOnInit() {

  }

}
