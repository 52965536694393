import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
@Injectable()
export class PageService {
  constructor(
    public snackBar: MatSnackBar
  ) {

  }

  pageInfo(com_obj,pagedata) {
       com_obj.title.setTitle(pagedata.page_title);
      com_obj.meta.updateTag({name:'keywords',content:pagedata.meta_data.Keywords});
      var dt=pagedata.meta_data.hasOwnProperty('title');
      if(dt){
        com_obj.meta.updateTag({name:'title',content:pagedata.meta_data.title});
        com_obj.meta.updateTag({property:'og:title',content:pagedata.meta_data.title});
      }
      com_obj.meta.updateTag({name:'description',content:pagedata.meta_data.description});
      com_obj.meta.updateTag({property:'og:description',content:pagedata.meta_data.description});
  }

  avl_fitting_date(close_day){
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let day = weekday[new Date().getDay()];
    var res = close_day.split(",");
    let avl_date:any;
    var i = res.indexOf(day);
    if(i<0){
       avl_date=new Date();
    } else{
      for(let j=1;j<=7;j++){
        var ms = new Date().getTime() + (86400000*j);
        var tomorrow = new Date(ms);
            let day2 = weekday[tomorrow.getDay()];
            var res2 = close_day.split(",");
            var i2 = res.indexOf(day2);
        if(i2<0){
               avl_date=tomorrow;
             break;;
        }
      }
    }
      var month = avl_date.getMonth()+1;
      var day1 = avl_date.getDate();
      var year = avl_date.getFullYear();
      return ( day1 + "-"+month  + "-" + year);
  }
   special_day(special_day,lang){
      var  maskweekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            var a = maskweekday.indexOf(special_day);
      if(lang == 'ar'){
            var weekday = ['شمس', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'خميس', 'الجمعة', 'جلس'];
         } else{
              var  weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
         
         }
         return weekday[a];
  }
  
  
  
  open_time(close_day,lang){
      if(lang == 'ar'){
            var weekday = ['شمس', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'خميس', 'الجمعة', 'جلس'];
         } else{
              var  weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
         
         }
      
      var res = close_day.split(",");
      weekday = weekday.filter(item => !res.includes(item));
if((weekday.length)==1){
  return weekday[0];
}
if((weekday.length)==2 || (weekday.length)>2){
  return (weekday[0]+'-'+weekday[1]);
}
  }

  snakeMessage(message,action) {
    let extraClasses;
   if (action ==='Danger') {
     extraClasses = ['snakebar-background-red'];
   } else {
     extraClasses = ['snakebar-background-green'];
 }
    this.snackBar.open(message, '', {
      duration: 3000,
      panelClass:extraClasses
    });
}
}
