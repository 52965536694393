import { Component, OnInit ,AfterViewInit } from '@angular/core';
import { CrudService } from 'src/app/common_service/crud.service';
 import { AuthService } from "angular-6-social-login";
import { AutheService } from 'src/app/common_service/authe.service';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {PageService} from '../../../common_service/page.service';
import { ProductsService } from 'src/app/common_service/products.service';
import {Title , Meta} from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {LanguageService} from 'src/app/common_service/language.service';
 import { ConfigService } from '../../../common_service/ConfigService.service';
  import { SEOService } from '../../../common_service/seo.service';
 import {CookieService} from 'ngx-cookie-service';
import { Observable, forkJoin } from 'rxjs';
 declare var $: any;

@Component({
  selector: 'app-loyality',
  templateUrl: './loyality.component.html',
  styleUrls: ['./loyality.component.css']
})
export class LoyalityComponent implements OnInit {
      api_calling:any=false;
      state_data:any;
      city_data:any;
  constructor(
      private translate: TranslateService,
      private authService: AutheService,
      private AuthServiceSocial: AuthService,
      public siteService: LanguageService,
      private pageSerice: PageService,
      private productsService: ProductsService,
      private router:Router,
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private title:Title,
      private meta:Meta,
      private CrudService:CrudService,
      private config: ConfigService,
      private CookieService:CookieService,
	  private seoService: SEOService
  ) { }
  reg_form = this.fb.group({
     email: ['', [
        Validators.required,
        RxwebValidators.email(),
         RxwebValidators.maxLength({value:255 })
    ]],
    name: ['', [
       Validators.required,
        RxwebValidators.maxLength({value:255 })
   ]],
   last_name: ['', [
      Validators.required,
      RxwebValidators.maxLength({value:255 })
  ]],
      address1: ['', [
        Validators.required,
        RxwebValidators.maxLength({value:255 })
      ]],
      address2: ['', [
       Validators.required,
       RxwebValidators.maxLength({value:255 })
      ]],
       phone: ['', [
      Validators.required,
      Validators.pattern("^[0-9_-]{8}"),
      RxwebValidators.digit()
       ]],
       state: ['', [
         Validators.required
       ]],
       fld_user_city: ['', [
         Validators.required
       ]],
    password: ['', [
    Validators.required,
    RxwebValidators.compare({fieldName:'confirm_password' }),
    RxwebValidators.maxLength({value:25 })
    ]],
   confirm_password: ['', [
     Validators.required,
      RxwebValidators.compare({fieldName:'password' }),
      RxwebValidators.maxLength({value:25 })
   ]]
  });
  selectedState(state){
    let  query_params={
        "state_name":state
  };
    this.CrudService.add(query_params,'cities').subscribe(data => {
        this.city_data=data.data;
  });
  }
  ngOnInit() {

    this.translate.get(['loyality.page_title', 'loyality.keywords','loyality.description',])
    .subscribe(translations => {
        this.pageSerice.pageInfo(this,{
      "page_title":translations['loyality.page_title'],
      "meta_data":{
          "Keywords":translations['loyality.keywords'],
          "title":translations['loyality.page_title'],
          "description":translations['loyality.description']
      }
  });
});

	const currentRoute=window.location.href;
	this.seoService.updateCanonicalUrl(currentRoute); 

    let apisCallData=[
        {
        "url":"states",
        "params":{}
        }
    ];
    this.CrudService.handleMutipleCallAPI(apisCallData).subscribe(data => {
      this.state_data=data[0].data;
  });
  }
  registration(e){
      if (e) e.preventDefault();
         this.api_calling=true;
     var userentry={
  "email":this.reg_form.controls['email'].value,
  "name":this.reg_form.controls['name'].value,
  "last_name":this.reg_form.controls['last_name'].value,
  "address1":this.reg_form.controls['address1'].value,
  "address2":this.reg_form.controls['address2'].value,
  "phone":this.reg_form.controls['phone'].value,
  "state":this.reg_form.controls['state'].value,
  "city":this.reg_form.controls['fld_user_city'].value,
  "password":this.reg_form.controls['password'].value,
  "confirm_password":this.reg_form.controls['confirm_password'].value
}

    this.CrudService.add(userentry, 'loyality_program').subscribe(res => {
      if (res.status==true) {
              this.pageSerice.snakeMessage(res.msg,'success');
              this.reg_form.reset();
      } else {
         this.api_calling=false;
        this.pageSerice.snakeMessage(res.msg,'Danger');
      }
    });
  }
}
